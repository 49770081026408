import {Stack} from "@mui/material";
import {List, ListItemIcon, ListItemText, makeStyles} from "@material-ui/core";
import {InfoCard, Link} from "@backstage/core-components";
import React from "react";
import {Tool} from "@backstage/plugin-home";
import {PrometheusIcon} from "../../../assets/icons/PrometheusIcon";
import {GrafanaIcon} from "../../../assets/icons/GrafanaIcon";
import {JaegerIcon} from "../../../assets/icons/JaegerIcon";
import {LessonlyIcon} from "../../../assets/icons/Lessonly";
import GitHubIcon from "@material-ui/icons/GitHub";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import {DoomIcon} from "../../../assets/icons/DoomIcon";
import LanguageIcon from '@material-ui/icons/Language';

const currentMonthDay = new Date().toLocaleDateString('en-US', {month: "2-digit", day: "2-digit"});

const useStyles = makeStyles(theme => ({
  toolkit: {
    display: 'flex',
    textAlign: 'center',
    overflowX: 'auto'
  },
  tool: {
    margin: theme.spacing(0.5, 1),
  },
  label: {
    marginTop: theme.spacing(1),
    width: '72px',
    fontSize: '0.9em',
    lineHeight: '1.25',
    overflowWrap: 'break-word',
    color: theme.palette.text.secondary,
  },
  icon: {
    width: '64px',
    height: '64px',
    borderRadius: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.default,
  },
}));

export const HelpfulLinksCard = () => {
  const classes = useStyles();

  const toolkitLinks: Tool[] = [
    {
      url: 'https://grafana.weavelab.xyz/goto/aHefpG1Sg?orgId=1',
      label: 'Victoria Metrics',
      icon: <PrometheusIcon/>,
    },
    {
      url: 'https://grafana.weavelab.xyz/d/PZga5s87z/service-metrics-dashboard?orgId=1',
      label: 'Grafana',
      icon: <GrafanaIcon/>,
    },
    {
      url: 'https://tracing.sso.gke1-west3.wsf-prod-1.wstack.net/',
      label: 'Production Tracing',
      icon: <JaegerIcon/>,
    },
    {
      url: 'https://weave.seismic.com/app#/learning/path/391679-backend-engineer',
      label: 'Backend Engineer Certification Course',
      icon: <LessonlyIcon/>,
    },
    {
      url: 'https://engineering.getweave.com/',
      label: 'Engineering Blog',
      icon: <GitHubIcon/>,
    },
    {
      url: 'https://integrations-dashboard.weavelab.ninja/',
      label: 'Integrations Dashboard',
      icon: <AccountTreeIcon/>,
    },
    {
      url: 'https://wam.weavelab.ninja/wam/',
      label: 'WAM',
      icon: <LanguageIcon/>,
    },
    {
      url: 'https://phone-data-service.weavelab.ninja/tenants',
      label: 'Phone Data Service',
      icon: <LanguageIcon/>,
    },
    {
      url: 'https://weavelab.app.opsgenie.com/',
      label: 'Opsgenie',
      icon: <LanguageIcon/>,
    }
  ]

  if (currentMonthDay === "04/01") {
    toolkitLinks.push({
      url: "https://js-dos.com/DOOM/",
      label: "They are rage, brutal, without mercy",
      icon: <DoomIcon/>
    })
  }

  return (
    <InfoCard title="Helpful Links" noPadding>
      <Stack direction="row" spacing={2}>
        <List className={classes.toolkit}>
          {toolkitLinks.map(tool => (
            <Link key={tool.url} to={tool.url} className={classes.tool}>
              <ListItemIcon className={classes.icon}>{tool.icon}</ListItemIcon>
              <ListItemText
                secondaryTypographyProps={{className: classes.label}}
                secondary={tool.label}
              />
            </Link>
          ))}
        </List>
      </Stack>
    </InfoCard>
  )
}
