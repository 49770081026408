import React from "react";
import {useApp} from "@backstage/core-plugin-api";
import {HelpIcon} from "@backstage/core-components";

export const renderIcon = (kind: string | undefined) => {
  if (!kind) return null;

  const Ic =
    useApp().getSystemIcon(kind.toLocaleLowerCase()) // works for custom kind
    || useApp().getSystemIcon(`kind:${kind.toLocaleLowerCase()}`) // // works for default kind
    || HelpIcon

  return (<Ic/>)
}
