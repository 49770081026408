import React, {ReactNode} from "react";
import {catalogApiRef, useEntity} from "@backstage/plugin-catalog-react";
import {useApi} from '@backstage/core-plugin-api';
import {Link, MarkdownContent, Table} from '@backstage/core-components';
import {
  DeploymentSummary_Env,
  DeploymentSummary_MountDirectory,
  DeploymentSummary_MountFile
} from "@weave/schema-gen-ts/dist/schemas/bart/deploy.pb";
import {Card, CardContent, CardHeader, Divider, Grid, makeStyles} from "@material-ui/core";
import {Entity} from "@backstage/catalog-model";
import {DeploymentEntityV1alpha1} from "../../../../../../../backend/src/custom-processors/types";

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word',
    'font-weight': 'bold',
  },
});

const displayLink = (options: { link: string | undefined, label?: string }) => {
  if (!options.link) {
    return "N/A"
  }
  return <Link to={options.link}>{options.label ?? "Link"}</Link>
}

const SummaryContent = () => {
  const classes = useStyles();
  const {entity} = useEntity<DeploymentEntityV1alpha1>();
  const [repoEntity, setRepoEntity] = React.useState<Entity>();
  const catalogApiClient = useApi(catalogApiRef);
  const [wappBytes, setWappBytes] = React.useState('');

  catalogApiClient.getEntityByRef(entity.spec?.owner as string).then(r => {
    if (JSON.stringify(r) !== JSON.stringify(repoEntity)) {
      setRepoEntity(r);
    }
  }).catch(e => {
    console.error(e);
  })

  React.useEffect(() => {
    if (repoEntity?.spec?.waml) {
      let wamlStr = repoEntity?.spec?.waml.toString();
      setWappBytes(wamlStr);
    }
  }, [repoEntity?.spec?.waml])

  if (!wappBytes) {
    return null;
  }

  // create mounted files list from all the mounted directories
  const listMountedFiles = (dirs: DeploymentSummary_MountDirectory[]) => {
    let mountedFiles: DeploymentSummary_MountFile[] = [];
    dirs.map((dir, _i) => {
      if (dir && dir.mountFiles) {
        dir.mountFiles.map((file, _j) => {
          mountedFiles.push({name: dir.path + "/" + file.name, size: file.size || 0});
        })
      }
    })
    return mountedFiles;
  };

  const mountedFiles = listMountedFiles(entity.spec.deploySummary?.mountDirectories || []);

  let envVarTablePageSize = 20;
  let numEnvVars = entity.spec.deploySummary?.environmentVariables?.length || 0;

  return (
    <>
      <Grid>
        <Grid style={{padding: '5px 0'}}>
          <Table
            options={{paging: numEnvVars > envVarTablePageSize, pageSize: envVarTablePageSize, padding: 'dense'}}
            data={entity.spec.deploySummary?.environmentVariables || []}
            columns={[
              {title: 'NAME', field: 'name', highlight: true},
              {title: 'SOURCE', field: 'source'},
              {
                title: 'VALUE', field: 'value', render: (data: DeploymentSummary_Env): ReactNode => {
                  if (data.source === "deployer" && !data.value) {
                    const link = entity.metadata.links?.find(link => link.title === "Argo");
                    if (link) {
                      return displayLink({link: link.url, label: "Argo Page"});
                    }
                  }

                  return data.value
                }
              },
            ]}
            title="Environment Variables"></Table>
          <Divider/>
        </Grid>

        {entity.spec.deploySummary?.mountDirectories && <Grid style={{padding: '5px 0'}}>
            <Table
                options={{paging: false, padding: 'dense', search: false}}
                data={entity.spec.deploySummary?.mountDirectories || []}
                columns={[
                  {title: 'NAME', field: 'name', highlight: true},
                  {title: 'SOURCE', field: 'source'},
                  {title: 'VERSION', field: 'version'},
                  {title: 'REAL_TIME UPDATES', field: 'realtime', emptyValue: 'false'}, // TODO
                  {title: 'PATH', field: 'path'},
                ]}
                title="Mounted Directories"></Table>
            <Divider/>
        </Grid>}

        {mountedFiles?.length != 0 && <Grid style={{padding: '5px 0'}}>
            <Table
                options={{paging: true, pageSize: 5, padding: 'dense'}}
                data={mountedFiles || []}
                columns={[
                  {title: 'NAME', field: 'name', highlight: true},
                  {title: 'SIZE', field: 'size'},
                ]}
                title="Mounted Files"></Table>
            <Divider/>
        </Grid>}

        {entity.spec.deploySummary?.serviceAddress && <Grid style={{padding: '5px 0'}}>
            <Card className="gridItem" style={{width: '100%'}}>
                <CardHeader title="Services"/>
                <Divider/>
                <CardContent className="gridItem">
                  {entity.spec.deploySummary?.serviceAddress?.map((variable, i) => (
                    <MarkdownContent
                      key={i}
                      className={classes?.description}
                      content={variable}
                    />
                  ))}
                </CardContent>
            </Card>
            <Divider/>
        </Grid>}

        {entity.spec.deploySummary?.ingressPaths && <Grid style={{padding: '5px 0'}}>
            <Table
                options={{paging: false, padding: 'dense', search: false}}
                data={entity.spec.deploySummary?.ingressPaths || []}
                columns={[
                  {title: 'URL', field: 'url', highlight: true},
                  {title: 'CERT', field: 'cert'},
                  {title: 'CONFIG', field: 'config'},
                ]}
                title="Ingress"></Table>
            <Divider/>
        </Grid>}

        {entity.spec.deploySummary?.databases && <Grid style={{padding: '5px 0'}}>
            <Table
                options={{paging: false, padding: 'dense', search: false,}}
                data={entity.spec.deploySummary?.databases || []}
                columns={[
                  {title: 'NAME', field: 'name', highlight: true},
                  {title: 'HOSTNAME', field: 'hostname',},
                  {title: 'GRAFANA', render: (database) => displayLink({link: database.grafanaDashboard})},
                  {title: 'GCP Cloud Query', render: (database) => displayLink({link: database.queryInsightsTemplate})},
                  {title: 'REPLICA', field: 'replica'},
                  {title: 'SCHEMA', field: 'schema'},
                ]}
                title="Databases"></Table>
            <Divider/>
        </Grid>}
        {entity.spec.deploySummary?.alerts && <Grid style={{padding: '5px 0'}}>
            <Table
                options={{paging: false, padding: 'dense'}}
                data={entity.spec.deploySummary?.alerts || []}
                columns={[
                  {title: 'NAME', field: 'alert', highlight: true},
                  {title: 'SLACK', field: 'slackChannel'},
                  {title: 'OPSGENIE', field: 'opsgenieTeam'},
                  {title: 'QUERY', field: 'expression'},
                ]}
                title="Alerts"></Table>
            <Divider/>
        </Grid>}
      </Grid>
    </>
  )
}

export default SummaryContent;
