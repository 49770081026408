import React from "react";
import {RELATION_OWNER_OF} from "@backstage/catalog-model";
import {EntityRelationsCard} from "../../../../../entity/EntityRelationsCard/EntityRelationsCard";
import {DeploymentTableSubtitle, getDeploymentTableColumns} from "../../../../deployment/DeploymentPage";
import {getSchemaTableColumns, SchemaTableSubtitle} from "../../../../schema/SchemaPage";
import {Content, TabbedLayout} from "@backstage/core-components";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    paddingTop: '0'
  }
})

const OwnershipContent = () => {
  const classes = useStyles();

  return (
    <Content className={classes.root}>
      <TabbedLayout>
        <TabbedLayout.Route path="/deployments" title="Deployments">
          <EntityRelationsCard
            title="Deployments"
            subtitle={<DeploymentTableSubtitle/>}
            relationsType={RELATION_OWNER_OF}
            relationsKind="Deployment"
            columns={getDeploymentTableColumns()}
            errorMsg="Failed to load deployments."
            emptyMsg="No deployments found."
          />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/schemas" title="Schemas">
          <EntityRelationsCard
            title="Schemas"
            subtitle={<SchemaTableSubtitle/>}
            relationsType={RELATION_OWNER_OF}
            relationsKind="Schema"
            columns={getSchemaTableColumns()}
            errorMsg="Failed to load schemas."
            emptyMsg="No schemas found."
          />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Content>
  )
}

export default OwnershipContent;
