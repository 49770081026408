import React, {ReactNode} from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import usaLogo from "../../../assets/logos/usa-flag-final.png";
import indiaLogo from "../../../assets/logos/ind-flag-final.png";

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

type TimeObj = {
  label: string;
  icon?: ReactNode;
  value: string;
  dateTime: string;
};

/** @public */
export type ClockConfig = {
  label: string;
  timeZone: string;
  icon?: ReactNode;
};

function getTimes(
  clockConfigs: ClockConfig[],
  customTimeFormat?: Intl.DateTimeFormatOptions,
) {
  const d = new Date();
  const lang = window.navigator.language;

  const clocks: TimeObj[] = [];

  if (!clockConfigs) {
    return clocks;
  }

  for (const clockConfig of clockConfigs) {
    let label = clockConfig.label;

    const options: Intl.DateTimeFormatOptions = {
      timeZone: clockConfig.timeZone,
      ...(customTimeFormat ?? timeFormat),
    };

    try {
      new Date().toLocaleString(lang, options);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(
        `The timezone ${options.timeZone} is invalid. Defaulting to GMT`,
      );
      options.timeZone = 'GMT';
      label = 'GMT';
    }

    const value = d.toLocaleTimeString(lang, options);
    const dateTime = d.toLocaleTimeString(lang, {
      timeZone: options.timeZone,
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    clocks.push({label, value, icon: clockConfig.icon, dateTime});
  }

  return clocks;
}

/**
 * A component to display a configurable list of clocks for various time zones.
 *
 * @example
 * Here's a simple example:
 * ```
 * // This will give you a clock for the time zone that Stockholm is in
 * // you can add more than one but keep in mind space may be limited
 * const clockConfigs: ClockConfig[] = [
 *  {
 *    label: 'STO',
 *    timeZone: 'Europe/Stockholm',
 *  },
 * ];
 *
 * // Setting hour12 to false will make all the clocks show in the 24hr format
 * const timeFormat: Intl.DateTimeFormatOptions = {
 *  hour: '2-digit',
 *  minute: '2-digit',
 *  hour12: false,
 * };
 *
 * // Here is the component in use:
 * <HeaderWorldClock
 *  clockConfigs={clockConfigs}
 *  customTimeFormat={timeFormat}
 * />
 * ```
 *
 * @public
 */
export const HeaderWorldClock = () => {
  const clockConfigs: ClockConfig[] = [
    {
      label: 'Utah',
      timeZone: 'America/Denver',
      icon: <img style={{width: "24px", height: "20px"}} src={usaLogo} alt="USA"/>
    },
    {
      label: 'IST',
      timeZone: 'IST',
      icon: <img style={{width: "24px", height: "20px"}} src={indiaLogo} alt="IST"/>
    },
  ];

  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const defaultTimes: TimeObj[] = [];
  const [clocks, setTimes] = React.useState(defaultTimes);

  React.useEffect(() => {
    let times = getTimes(clockConfigs, timeFormat);
    if (times.toString() !== clocks.toString()) {
      setTimes(times);
    }

    const intervalId = setInterval(() => {
      setTimes(getTimes(clockConfigs, timeFormat));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [clockConfigs, timeFormat]);

  if (clocks.length !== 0) {
    return (
      <Grid container>
        {clocks.map(clock => (
          <ClockItem
            key={clock.label}
            icon={clock.icon}
            label={clock.label}
            value={<time dateTime={clock.dateTime}>{clock.value}</time>}
          />
        ))}
      </Grid>
    );
  }
  return null;
};

type ClockItemProps = {
  label: string;
  value: ReactNode;
  icon: ReactNode;
};

const ClockItem = (props: ClockItemProps) => {
  return (
    <Box style={{ display: "flex", flexWrap: "nowrap", gap: 2, padding: 10 }}>
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          minWidth: 0,
          gap: 1,
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexShrink: 0, marginRight: 0, marginLeft: 5}}>
            {props.icon}
          </Box>
          { !props.icon && <Typography
            noWrap
            style={{
              fontWeight: 600,
              fontSize: "0.85rem",
              color: '#FFF'
            }}
          >
            <b>{props.label}</b>
          </Typography>}
          <Typography
            noWrap
            variant="body2"
            style={{
              fontSize: "0.75rem",
              color: "#FFF",
              marginTop: -0.25,
              marginRight: 5
            }}
          >
            {props.value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
