import * as React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scorecard-tabpanel-${index}`}
      aria-labelledby={`scorecard-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const tabA11yProps = (index: number) => ({
  id: `scorecard-tab-${index}`,
  'aria-controls': `scorecard-tabpanel-${index}`,
});
