import React from 'react';
import {Route} from 'react-router-dom';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin} from '@backstage/plugin-catalog';
import {HomepageCompositionRoot} from '@backstage/plugin-home';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {discoveryApiRef, IdentityApi, oktaAuthApiRef, useApi} from '@backstage/core-plugin-api';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/root';
import {createTheme, genPageTheme, lightTheme, shapes,} from '@backstage/theme';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LightIcon from '@material-ui/icons/WbSunny';
import {OpsgeniePage} from '@k-phoen/backstage-plugin-opsgenie';
import MuiGitHubIcon from '@material-ui/icons/GitHub';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ClustersIcon from '@material-ui/icons/DeviceHub';
import ExtensionIcon from '@material-ui/icons/Extension';

/**
 * The `@backstage/core-components` package exposes this type that
 * contains all Backstage and `material-ui` components that can be
 * overridden along with the classes key those components use.
 */
import {AlertDisplay, OAuthRequestDialog, SignInPage} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {HomePage} from "./components/home/HomePage";
import {ApiExplorerPage} from "@backstage/plugin-api-docs";
import {CatalogGraphPage} from "@backstage/plugin-catalog-graph";
import {setTokenCookie} from './cookieAuth';
import {CustomCatalogPage} from './components/catalog/CustomCatalogPage';
import {CustomRenderNode} from './components/catalog-graph/CustomRenderNode';
import {ArgoCDIcon} from './assets/icons/ArgoCDIcon';
import {GrafanaIcon} from './assets/icons/GrafanaIcon';
import {JaegerIcon} from './assets/icons/JaegerIcon';
import {WDocsIcon} from './assets/icons/WDocsIcon';
import {UserSettingsPage} from "@backstage/plugin-user-settings";
import {userProfilePage} from "./components/user-profile/UserProfilePage";
import {EntityListProvider} from "@backstage/plugin-catalog-react";
import {DoomIcon} from "./assets/icons/DoomIcon";
import {TechDocsIndexPage, TechDocsReaderPage} from "@backstage/plugin-techdocs";
import {TechDocsHomePage} from "./components/core/TechDocs/TechDocsHomePage";
import {bartApiPlugin} from "./api/plugin";

const defaultTheme = createTheme({
  palette: {
    ...lightTheme.palette,
  },
  defaultPageTheme: 'home',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({colors: ['#51ade5', '#000000'], shape: shapes.wave}),
  },
});

const app = createApp({
  apis,
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'okta-auth-provider',
            title: 'Okta',
            message: 'Sign in using Okta',
            apiRef: oktaAuthApiRef,
          }}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );
            props.onSignInSuccess(identityApi);
          }}
        />
      )
    },
  },
  plugins: [bartApiPlugin],
  icons: {
    component: MuiGitHubIcon,
    deployment: CloudUploadIcon,
    infradeployment: CloudUploadIcon,
    schema: SwapHorizIcon,
    cluster: ClustersIcon,
    gcp: CloudOutlinedIcon,
    system: ExtensionIcon,
    argo: ArgoCDIcon,
    jaeger: JaegerIcon,
    grafana: GrafanaIcon,
    wdocs: WDocsIcon,
    doom: DoomIcon,
  },
  themes: [{
    id: 'default-theme',
    title: 'Default Theme',
    variant: 'light',
    icon: <LightIcon/>,
    Provider: ({children}) => (
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  }],
  bindRoutes({bind}) {
    bind(catalogPlugin.externalRoutes, {});
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot/>}>
      <HomePage/>
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage/>}>
      <EntityListProvider>
        <CustomCatalogPage/>
      </EntityListProvider>
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage/>}
    >
      {entityPage}
    </Route>
    <Route path="/search" element={<SearchPage/>}>
      {searchPage}
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage/>}/>
    <Route path="/catalog-graph" element={<CatalogGraphPage renderNode={CustomRenderNode}/>}/>
    <Route path="/profile" element={<UserSettingsPage/>}>
      {userProfilePage}
    </Route>
    <Route path="/opsgenie" element={<OpsgeniePage/>}/>

    {/*These have to be here for the TechDocs to work on the repository page located at: /catalog/:namespace/:kind/:name/docs*/}
    <Route path="/docs" element={<TechDocsIndexPage/>}>
      <EntityListProvider>
        <TechDocsHomePage/>
      </EntityListProvider>
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage/>}
    />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay/>
    <OAuthRequestDialog/>
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
