import React from "react";
import OverviewContent from "./tabs/overview/OverviewContent";
import {EntityLayout} from "../../entity/EntityLayout/EntityLayout";
import {EntityHeaderBreadcrumbs} from "../../entity/EntityHeaderBreadcrumbs/EntityHeaderBreadcrumbs";
import {Link, TableColumn} from "@backstage/core-components";
import {CatalogTableRow} from "@backstage/plugin-catalog";
import {createNameColumn, createOwnerColumn} from "../../entity/utils/tableColumns";
import {isMobile} from "mobile-device-detect";
import SummaryContent from "./tabs/summary/SummaryContent";

export const createDeploymentTableLinksColumn = (width?: string): TableColumn<CatalogTableRow> => {
  return {
    title: 'Links',
    render: ({entity}) => {
      const serviceMetricsDashboardLink = entity.metadata.links?.find(link => link.title === "Service Metrics Dashboard")
      const argoLink = entity.metadata.links?.find(link => link.title === "Argo")
      const logsLink = entity.metadata.links?.find(link => link.title === "Application Logs")
      return (
        <ul>
          {serviceMetricsDashboardLink && (
            <li>
              <Link to={serviceMetricsDashboardLink.url}>Service Metrics Dashboard</Link>
            </li>
          )}
          {argoLink && (
            <li>
              <Link to={argoLink.url}>Argo</Link>
            </li>
          )}
          {logsLink && (
            <li>
              <Link to={logsLink.url}>Logs</Link>
            </li>
          )}
        </ul>
      )
    },
    width: width,
  };
}

export const getDeploymentTableColumns = (): TableColumn<CatalogTableRow>[] => {
  let columns: TableColumn<CatalogTableRow>[] = [
    createNameColumn({width: "20%"}),
    createOwnerColumn("40%"),
  ];

  if (!isMobile) {
    columns.push(createDeploymentTableLinksColumn("20%"))
  }

  return columns;
}

export const DeploymentTableSubtitle = () => {
  return (
    <>The list of deployments comes from the <Link
      to="https://github.com/weave-lab/ops-argo-apps-generated">ops-argo-apps-generated</Link> repository
      which contains all deployments that are a result of running <Link
        to="https://switchboard.weavelab.ninja/catalog/default/component/bart/docs/commands/deploy/">bart
        deploy</Link>.</>
  )
}

const DeploymentPage = () => {
  return (
    <EntityLayout headerRoutingBreadcrumbs={<EntityHeaderBreadcrumbs/>}>
      <EntityLayout.Route path="/" title="Overview">
        {<OverviewContent/>}
      </EntityLayout.Route>
      <EntityLayout.Route path="/summary" title="Summary">
        {<SummaryContent/>}
      </EntityLayout.Route>
    </EntityLayout>
  );
}

export default DeploymentPage;
