import React from "react";
import OverviewContent from "./tabs/overview/OverviewContent";
import {EntityLayout} from "../../../entity/EntityLayout/EntityLayout";
import {EntityHeaderBreadcrumbs} from "../../../entity/EntityHeaderBreadcrumbs/EntityHeaderBreadcrumbs";
import {Link} from "@backstage/core-components";

export const ProductFeatureTableSubtitle = () => {
  return (
    <>The list of product features comes from all of the <Link
      to="https://github.com/weave-lab/the-deployer/blob/6b9836e054377d2dd50ccc3768ff5177771f9adf/.weave.yaml#L5">productFeature</Link> fields
      in all WAMLs (.weave.yaml files). This field was added so that we could determine the compute cost for
      each product feature. This field is added as a label to each deployment in Kubernetes. If you wish to
      add a new product feature, make a pull request into <Link
        to={"https://github.com/weave-lab/bart"}>bart</Link> by adding to <Link
        to={"https://github.com/weave-lab/bart/blob/e7c4ce4eba70fdb8cfa25763a9f156cae7868ea9/internal/v2/bwaml/resource_name.go#L12"}>this
        list</Link>.</>
  )
}

const ProductFeaturePage = () => {
  return (
    <EntityLayout headerRoutingBreadcrumbs={<EntityHeaderBreadcrumbs/>}>
      <EntityLayout.Route path="/" title="Overview">
        {<OverviewContent/>}
      </EntityLayout.Route>
    </EntityLayout>
  );
}

export default ProductFeaturePage;
