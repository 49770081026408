import React from 'react';
import {useEntity,} from '@backstage/plugin-catalog-react';
import {Card, CardContent, CardHeader, Divider} from "@material-ui/core";

import {AboutContent} from './AboutContent';
import {EntityLink} from "@backstage/catalog-model";

/**
 * Props for {@link AboutContent}.
 *
 * @public
 */
export interface AboutCardProps {
  externalLinks?: EntityLink[];
}

/**
 * Exported publicly via the EntityAboutCard
 */
export function AboutCard(props: AboutCardProps) {
  const {entity} = useEntity();

  return (
    <Card className="gridItem">
      <CardHeader
        title="About"
      />
      <Divider/>
      <CardContent className="gridItem">
        <AboutContent entity={entity} externalLinks={props.externalLinks}/>
      </CardContent>
    </Card>
  );
}
