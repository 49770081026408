import React from "react";
import {RepositoryEntityV1alpha1} from "backend/src/custom-processors/types";
import {TabbedLayout} from "@backstage/core-components";
import SLOPage, {SLOPageTitle} from "./slos/SLOPage";
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import WAMLDiffModal from "./WAMLDiffModal";
import WAMLDefinition, {WAMLDefinitionPageTitle} from "./definition/WAMLDefinition";
import {ChangesNotificationBadge} from "../../../../../core/ChangesNotificationBadge/ChangesNotificationBadge";
import InlineCode from "../../../../../core/InlineCode/InlineCode";

export type WamlContentProps = {
  repositoryEntity: RepositoryEntityV1alpha1;
  hasStagedChanges: boolean;
  originalWaml: string;
  localWaml?: string;
  modifyWaml: (newWaml: string, options?: ModifyWamlOptions) => void;
  resetWaml: () => void;
  tabsWithChanges?: string[];
}

export type ModifyWamlOptions = {
  tab?: string;
}

const WamlContent = (props: WamlContentProps) => {
  const [diffModalOpen, setDiffModalOpen] = React.useState(false);

  const toggleDiffModal = () => {
    setDiffModalOpen(!diffModalOpen);
  }

  if (!props.localWaml) {
    return <>No <InlineCode>.weave.yaml</InlineCode> found</>;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{textAlign: "right"}}>
          <Button
            onClick={props.resetWaml}
            disabled={!props.hasStagedChanges}
            color={"primary"}
            variant={"outlined"}
            size={"small"}
            style={{marginTop: "-10px", marginRight: "10px"}}
          >Reset</Button>
          <Button
            onClick={toggleDiffModal}
            disabled={!props.hasStagedChanges}
            variant={"outlined"}
            style={{marginTop: "-10px"}}
            color={"primary"}
            size={"small"}
          >{props.hasStagedChanges ? "View Diff" : "No Diff"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TabbedLayout>
            <TabbedLayout.Route
              path="/"
              title={WAMLDefinitionPageTitle}
              tabProps={props.tabsWithChanges?.includes(WAMLDefinitionPageTitle) ? {
                label: (<ChangesNotificationBadge title={WAMLDefinitionPageTitle}/>)
              } : {}}
            >
              <WAMLDefinition
                originalWaml={props.originalWaml}
                waml={props.localWaml}
                modifyWaml={(newWapp: string, options?: ModifyWamlOptions) => props.modifyWaml(newWapp, {tab: options?.tab || WAMLDefinitionPageTitle})}
              />
            </TabbedLayout.Route>
            <TabbedLayout.Route
              path="/slo"
              title={SLOPageTitle}
              tabProps={props.tabsWithChanges?.includes(SLOPageTitle) ? {
                label: (<ChangesNotificationBadge title={SLOPageTitle}/>)
              } : {}}
            >
              <SLOPage
                waml={props.localWaml}
                modifyWaml={(newWapp: string, options?: ModifyWamlOptions) => props.modifyWaml(newWapp, {tab: options?.tab || SLOPageTitle})}
              />
            </TabbedLayout.Route>
          </TabbedLayout>
        </Grid>
      </Grid>

      <WAMLDiffModal
        repositoryEntity={props.repositoryEntity}
        toggleModal={toggleDiffModal}
        open={diffModalOpen}
        originalWaml={props.originalWaml}
        localWaml={props.localWaml}
        hasStagedChanges={props.hasStagedChanges}
      />
    </>
  )
}

export default WamlContent;
