import React, {useEffect, useRef} from 'react';
import {Grid, makeStyles, Paper, Theme} from '@material-ui/core';
import {SearchBar, SearchPagination} from '@backstage/plugin-search-react';
import {Content, Header, InfoCard, Page,} from '@backstage/core-components';
import {isMobile} from 'mobile-device-detect';
import {SelectFilter} from "./SearchFilter";
import {HeaderWorldClock} from "../core/HeaderWorldClock/HeaderWorldClock";
import {SearchResults} from "./SearchResults";

const useStyles = makeStyles((theme: Theme) => ({
  searchBar: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    marginTop: '0px'
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  filters: {
    boxShadow: theme.shadows[1],
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
  searchResults: {
    borderRadius: '10px',
  }
}));

export const SearchPageContent = () => {
  const classes = useStyles();
  const searchBarRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    searchBarRef?.current?.focus();
  });

  return (
    <Content>
      <Grid container direction="row">
        <Grid item sm={12} md={9}>
          <SearchBar
            className={classes.searchBar}
            inputProps={{ref: searchBarRef}}
            InputProps={{
              classes: {
                root: classes.searchBar,
                notchedOutline: classes.searchBarOutline,
              }
            }}
          />
        </Grid>
        <Grid item sm={6} md={3} style={isMobile ? {width: '50%'} : undefined}>
          <Paper className={classes.filters}>
            <SelectFilter
              className={classes.filter}
              label="Kind"
              name="kind"
              values={[
                {name: 'All', value: 'All'},
                {name: 'Repository', value: 'Component'},
                {name: 'Documentation', value: 'Documentation'},
                {name: 'Team', value: 'Group', type: 'team'},
                {name: 'Deployment', value: 'Deployment'},
                {name: 'Infra Deployment', value: 'InfraDeployment'},
                {name: 'Cluster', value: 'Cluster'},
                {name: 'Schema', value: 'Schema'},
                {name: 'Product Feature', value: 'System'}
              ]}
            />
          </Paper>
        </Grid>
        <Grid item sm={12}>
          <InfoCard noPadding className={classes.searchResults}>
            <SearchResults/>
            <SearchPagination limitText={() => ""}/>
          </InfoCard>
        </Grid>
      </Grid>
    </Content>
  )
}

const SearchPage = () => {
  return (
    <Page themeId="home">
      <Header title="Search">
        <HeaderWorldClock/>
      </Header>
      <SearchPageContent/>
    </Page>
  );
};

export const searchPage = <SearchPage/>;
