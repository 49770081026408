import React from "react";
import {Grid} from "@material-ui/core";
import {EntityCatalogGraphCard} from "@backstage/plugin-catalog-graph";
import {EntityLink, RELATION_PROVIDES_API} from "@backstage/catalog-model";
import {AboutCard} from "../../../../core/AboutCard/AboutCard";
import {EntityWarningContent} from "../../../../entity/EntityWarningContent/EntityWarningContent";
import {CustomRenderNode} from "../../../../catalog-graph/CustomRenderNode";
import {getSchemaTableColumns} from "../../../schema/SchemaPage";
import {EntityRelationsCard} from "../../../../entity/EntityRelationsCard/EntityRelationsCard";
import {useEntity} from "@backstage/plugin-catalog-react";
import {DeploymentEntityV1alpha1} from "../../../../../../../backend/src/custom-processors/types";
import {DeploymentSummary_AnnotationLink} from "@weave/schema-gen-ts/dist/schemas/bart/deploy.pb";

const OverviewContent = () => {
  const {entity} = useEntity<DeploymentEntityV1alpha1>();

  return (
    <Grid container spacing={3}>
      {<EntityWarningContent/>}
      <Grid item xs={12}>
        <AboutCard
          externalLinks={annotationLinksToEntityLinks(entity.spec?.deploySummary?.annotationLinks)}
          externalLinksRef={"https://switchboard.weavelab.ninja/catalog/default/component/waml/docs/deployment_meta/#external-links"}
        />
      </Grid>
      <Grid item xs={12}>
        <EntityCatalogGraphCard variant="gridItem" height={300} maxDepth={1} renderNode={CustomRenderNode}/>
      </Grid>
      <Grid item xs={12}>
        <EntityRelationsCard
          title="Provided Schemas"
          subtitle={<>These are the schemas provided by this deployment.</>}
          relationsType={RELATION_PROVIDES_API}
          relationsKind="Schema"
          columns={getSchemaTableColumns()}
          errorMsg="Failed to load providers."
          emptyMsg="No schemas are provided by this deployment."
        />
      </Grid>
    </Grid>
  )
}

const annotationLinksToEntityLinks = (annotationLinks: DeploymentSummary_AnnotationLink[] | undefined): EntityLink[] => {
  let entityLinks: EntityLink[] = [];

  if (!annotationLinks) {
    return entityLinks;
  }

  annotationLinks.forEach(annotationLink => {
    entityLinks.push({
      title: annotationLink.title,
      url: annotationLink.url || "",
    });
  });

  return entityLinks
}

export default OverviewContent;
