import React from 'react';
import {Entity, EntityLink, parseEntityRef, RELATION_OWNED_BY,} from '@backstage/catalog-model';
import {Chip, Grid, makeStyles} from '@material-ui/core';
import {MarkdownContent} from '@backstage/core-components';
import {ContentField} from '../../../../../../core/ContentField/ContentField';
import {EntityRefLink, EntityRefLinks, getEntityRelations} from "@backstage/plugin-catalog-react";
import {LinksGridList} from "../../../../../../core/LinksGridList/LinksGridList";

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word',
  },
});

/**
 * Props for {@link AboutContent}.
 *
 * @public
 */
export interface AboutContentProps {
  entity: Entity;
  externalLinks?: EntityLink[];
}

/** @public */
export function AboutContent(props: AboutContentProps) {
  const {entity} = props;
  const classes = useStyles();

  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  const productFeatureRef = parseEntityRef({
    name: entity?.spec?.system as string,
    kind: "system",
  })

  let externalLinks = props.externalLinks || [];

  return (
    <Grid container>
      <ContentField label="Description" gridSizes={{xs: 12, sm: 8, md: 6, lg: 4}}>
        <MarkdownContent
          className={classes.description}
          content={entity?.metadata?.description || 'N/A (No "description" in WAML)'}
        />
      </ContentField>
      {entity?.metadata?.tags && (
        <ContentField
          label="Tags"
          value="No Tags"
          gridSizes={{xs: 12, sm: 4, md: 2}}
        >
          {(entity?.metadata?.tags || []).map(t => (
            <Chip key={t} size="small" label={t}/>
          ))}
        </ContentField>
      )}
      <ContentField
        label="Owner(s)"
        value="No Owner"
        gridSizes={{xs: 12, md: 4}}
      >
        {ownedByRelations.length > 0 && (
          <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group"/>
        )}
      </ContentField>
      {productFeatureRef && (
        <ContentField
          label="Product Feature"
          value='N/A (No "productFeature" populated in WAML)'
          gridSizes={{xs: 12, md: 4}}
        >
          <EntityRefLink entityRef={productFeatureRef} defaultKind="system"/>
        </ContentField>
      )}
      {entity?.metadata?.links && (
        <ContentField label='Links' gridSizes={{xs: 12}}>
          <LinksGridList
            cols={{xs: 2, sm: 2, md: 3, lg: 4, xl: 5}}
            items={entity.metadata?.links.map(({url, title}) => ({
              text: title ?? url,
              href: url,
            }))}
          />
        </ContentField>
      )}
      {externalLinks.length > 0 && (
        <ContentField
          label="External Links"
          value="No links"
          gridSizes={{xs: 12}}
          badgeLink={"https://github.com/weave-lab/slack-api/blob/8c7c5266e20df65aa77814367869eac541bb32ce/.weave.yaml#L14-L16"}
        >
          <LinksGridList
            cols={{xs: 2, sm: 2, md: 3, lg: 4, xl: 5}}
            items={externalLinks.map(({url, title}) => ({
              text: title ?? url,
              href: url,
            }))}
          />
        </ContentField>
      )}
    </Grid>
  );
}
