import {RepositoryEntityV1alpha1} from "../../../../../../../../backend/src/custom-processors/types";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {useApi} from "@backstage/core-plugin-api";
import {BartApiError, bartApiRef} from "../../../../../../api/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link, WarningPanel} from "@backstage/core-components";
import DiffViewer from "react-diff-viewer";
import Button from "@material-ui/core/Button";
import InlineCode from "../../../../../core/InlineCode/InlineCode";
import Modal from "../../../../../core/Modal/Modal";

export type DiffModalProps = {
  repositoryEntity: RepositoryEntityV1alpha1;
  open: boolean;
  hidden?: boolean;
  originalWaml: string;
  localWaml: string;
  hasStagedChanges: boolean;
  toggleModal: () => void;
}

const useDiffModalStyles = makeStyles(_theme => ({
  fmtDiffWarning: {
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "50%",
    marginTop: "1%"
  }
}));

const WAMLDiffModal = (props: DiffModalProps) => {
  const apiClient = useApi(bartApiRef);

  const classes = useDiffModalStyles();
  const {open = false, toggleModal} = props;

  // this has to be a string to retain the formatting that is returned from bart-api
  const [localWaml, setLocalWaml] = React.useState<string>("")
  const [localWamlFormatLoading, setLocalWamlFormatLoading] = React.useState<boolean>(false);
  const [localWamlFormatError, setLocalWamlFormatError] = React.useState<string>("");

  const [pullRequestModalOpen, setPullRequestModalOpen] = React.useState(false);

  const togglePullRequestModal = () => {
    setPullRequestModalOpen(!pullRequestModalOpen);
  }

  React.useEffect(() => {
    setLocalWamlFormatError("");

    setLocalWamlFormatLoading(true);
    apiClient.FormatWaml(props.localWaml)
      .then(r => {
        if (!r.waml) {
          return;
        }
        setLocalWaml(r.waml);
      })
      .catch((e: BartApiError) => {
        setLocalWamlFormatError(e.cause);
      })
      .finally(() => {
        setLocalWamlFormatLoading(false);
      });

  }, [props.originalWaml, props.localWaml]);

  React.useEffect(() => {
    if (open) {
      setLocalWamlFormatError("");
    }
  }, [open])

  return (
    <Modal
      open={open}
      toggleModal={toggleModal}
      fullWidth
      title={<>
        WAML Diff

        <div className={classes.fmtDiffWarning}>Note: this might show
          more changes that are a result of running <InlineCode>bart fmt</InlineCode></div>
      </>}
      actions={<Button onClick={togglePullRequestModal} color={"primary"} disabled={!props.hasStagedChanges}>Create Pull
        Request</Button>}
    >
      {(localWamlFormatLoading) ? (
        <CircularProgress/>
      ) : (
        <>
          {localWamlFormatError ? (
            <WarningPanel
              severity="error"
              title={"WAML Formatting Error"}
              defaultExpanded={true}
            >
              {localWamlFormatError}
            </WarningPanel>
          ) : (
            <>
              <DiffViewer
                oldValue={props.originalWaml}
                newValue={localWaml}
                splitView={true}
              />
              <WAMLDiffPullRequestModal
                repositoryEntity={props.repositoryEntity}
                localWaml={localWaml}
                open={pullRequestModalOpen}
                toggleModal={togglePullRequestModal}
                hidden={!pullRequestModalOpen}
              />
            </>
          )}
        </>
      )}
    </Modal>
  )
};

type WAMLDiffPullRequestModalProps = {
  repositoryEntity: RepositoryEntityV1alpha1;
  localWaml: string;
  open: boolean;
  hidden?: boolean;
  toggleModal: () => void;
}

const WAMLDiffPullRequestModal = (props: WAMLDiffPullRequestModalProps) => {
  const apiClient = useApi(bartApiRef);

  const {open = false, toggleModal} = props;

  const [pullRequestURL, setPullRequestURL] = React.useState<string>("");
  const [createPullRequestError, setCreatePullRequestError] = React.useState<string>("")
  const [createPullRequestLoading, setCreatePullRequestLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!open || pullRequestURL) {
      return;
    }

    setCreatePullRequestLoading(true);
    apiClient.CreateWamlPR(props.repositoryEntity.metadata.name, props.localWaml, "feat(waml): updates from Switchboard", "Updates to the .weave.yaml file from the Switchboard").then(r => {
      setPullRequestURL(r.prUrl || "");
    }).catch((e: BartApiError) => {
      setCreatePullRequestError(e.cause);
    }).finally(() => {
      setCreatePullRequestLoading(false);
    })
  }, [open])

  return (
    <Modal
      open={open}
      toggleModal={toggleModal}
      title={createPullRequestLoading ? "Creating Pull Request" : "Pull Request Created!"}
    >
      {createPullRequestLoading && (
        <CircularProgress/>
      )}

      {createPullRequestError && (
        <WarningPanel
          severity="error"
          title={"Pull Request Creation Error"}
          defaultExpanded={true}
        >
          {createPullRequestError}
        </WarningPanel>
      )}

      {pullRequestURL && (
        <><Link to={pullRequestURL}>{pullRequestURL}</Link></>
      )}
    </Modal>
  )
}

export default WAMLDiffModal;
