import React from "react";
import {Grid} from "@material-ui/core";
import {OpenApiDefinitionWidget} from "@backstage/plugin-api-docs";
import {useEntity} from "@backstage/plugin-catalog-react";
import {SchemaEntityV1alpha1} from "../../../../../../../backend/src/custom-processors/types";

const OpenAPIContent = () => {
  const {entity: schemaAPI} = useEntity<SchemaEntityV1alpha1>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <OpenApiDefinitionWidget definition={schemaAPI.spec.openApiDocs}/>
      </Grid>
    </Grid>
  )
}

export default OpenAPIContent;
