import React from "react";
import {useEntity} from "@backstage/plugin-catalog-react";
import {EntitySwitch} from "@backstage/plugin-catalog";
import ProductFeaturePage from "./product-feature/ProductFeaturePage";
import {isEntityType} from "../../entity/utils/entityUtils";

const SystemPage = () => {
  const {entity} = useEntity();

  return (
    <EntitySwitch>
      <EntitySwitch.Case if={isEntityType(entity, "productFeature")} children={<ProductFeaturePage/>}/>
    </EntitySwitch>
  )
};

export default SystemPage;
