export const getNumber = (value?: string | number) => {
  return Number(value) || 0;
};

export const getPercentage = (
  points?: string | number,
  maxPoints?: string | number,
) => {
  const partialValue = getNumber(points);
  const totalValue = getNumber(maxPoints) || 1;
  return (100 * partialValue) / totalValue;
};

export const getColorByPercentage = (percentage = 0) => {
  if (percentage >= 75) {
    return '#ff5c5c';
  }
  if (percentage >= 50) {
    return '#f8bb32';
  }
  if (percentage >= 25) {
    return '#40ad87';
  }
  return '#008254';
};
