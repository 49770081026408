import {AnyApiFactory, createApiFactory} from '@backstage/core-plugin-api';
import {catalogApiRef, entityPresentationApiRef,} from '@backstage/plugin-catalog-react';
import {DefaultEntityPresentationApi} from '@backstage/plugin-catalog';
import MuiGitHubIcon from '@material-ui/icons/GitHub';
import CloudIcon from '@material-ui/icons/CloudUpload';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ClustersIcon from '@material-ui/icons/DeviceHub';
import {WDocsIcon} from './assets/icons/WDocsIcon';
import ExtensionIcon from '@material-ui/icons/Extension';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: entityPresentationApiRef,
    deps: {
      catalogApi: catalogApiRef,
    },
    factory({catalogApi}) {
      return DefaultEntityPresentationApi.create({
        catalogApi,
        kindIcons: {
          component: MuiGitHubIcon,
          schema: SwapHorizIcon,
          deployment: CloudIcon,
          infradeployment: CloudIcon,
          cluster: ClustersIcon,
          wdocs: WDocsIcon,
          system: ExtensionIcon
        },
      });
    },
  })
];
