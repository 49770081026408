import React from "react";
import {useEntity} from "@backstage/plugin-catalog-react";
import {Card, Grid} from "@material-ui/core";
import YAML from "yaml";
import {InfraDeploymentEntityV1alpha1} from "backend/src/custom-processors/types";
import {CodeEditor} from "../../../../core/Editors/CodeEditor";

const ValuesContent = () => {
  const {entity} = useEntity<InfraDeploymentEntityV1alpha1>();

  return (
    <Grid container spacing={3} alignItems="stretch" style={{height: '100%'}}>
      <Card style={{padding: '16px', width: '100%'}}>
        <p>The code block below shows the final values that were used to render this infra deployment, including any values that came from <code>valuesFiles</code></p>
      </Card>
        <CodeEditor
            value={YAML.stringify(entity.spec.coreModelData?.mergedValues)}
        />
    </Grid>
  )
}

export default ValuesContent;
