import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { ReportCategory } from '@weave/schema-gen-ts/dist/schemas/quality-metrics/quality_metrics.pb';
import { ScorePointInfo } from './ScorePointInfo';
import { ScorecardMetrics } from './ScorecardMetrics';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: '1rem', marginRight: 1, marginLeft: 1 }}
      />
    }
    {...props}
  />
))(() => ({
  padding: 0,
  flexDirection: 'row-reverse',
}));

interface Props {
  categories?: ReportCategory[];
  isChildAccordion?: boolean;
}

export const ScorecardAccordion = ({ categories, isChildAccordion }: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  if (!categories?.length) {
    return <Typography color="gray">No categories found</Typography>;
  }

  return (
    <div>
      {categories.map(category => (
        <Accordion
          sx={{ borderWidth: isChildAccordion ? 0 : '1px' }}
          expanded={expanded === category.name}
          onChange={handleChange(category.name!)}
        >
          <AccordionSummary
            sx={{
              background: isChildAccordion
                ? 'rgba(0, 0, 0, .03)'
                : 'rgba(0, 0, 0, .06)',
              minHeight: isChildAccordion ? 42 : 50,
              '.v5-MuiAccordionSummary-content': {
                margin: 0,
              },
            }}
            aria-controls={`${category.name}-content`}
            id={`${category.name}-header`}
          >
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Typography
                variant={isChildAccordion ? 'subtitle2' : 'subtitle1'}
                sx={{ fontWeight: isChildAccordion ? 'normal' : 'bold' }}
                color={isChildAccordion ? 'darkslategrey' : 'black'}
              >
                {category.name}
              </Typography>
              <Stack direction="row">
                <ScorePointInfo
                  grade={"A"}
                  optPoints={category.points}
                  maxPoints={category.pointsMax}
                  percent={100}
                />
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {category?.subcategories?.map(subcategory => (
              <ScorecardAccordion isChildAccordion categories={[subcategory]} />
            ))}
            <ScorecardMetrics metrics={category.metrics} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
