import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { GaugeChart } from './GaugeChart';
import { CustomTabPanel, tabA11yProps } from './CustomTabPanel';
import { MetricsCategory } from './MetricsCategory';
import Typography from '@mui/material/Typography';
import { getNumber } from './helpers';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import {
  entityRouteParams,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';
import { useRouteRef } from '@backstage/core-plugin-api';
import { RepositoryEntityV1alpha1 } from '../../../../../../../../../backend/src/custom-processors/types';
import { Link } from '@backstage/core-components';

const INFO_LINKS = [
  {
    icon: <ScoreboardIcon />,
    title: 'See how your score stacks up',
    href: 'https://grafana.weavelab.xyz/d/a24ebc21-675e-4a77-82ab-8f4628da4580/quality-score-metric-distributions?orgId=1',
  },
  {
    icon: <AutoStoriesOutlinedIcon />,
    title: 'Read more about our approach to risk',
    href: 'https://switchboard.weavelab.ninja/catalog/default/component/testinfra-knowledge-base/docs/risk-score/risk%20approach/',
  },
  {
    icon: <AddCommentOutlinedIcon />,
    title: 'Feel this is unjustified?',
    href: 'https://forms.gle/DoXmYBZAtBtb38sv5',
  },
  {
    icon: <TipsAndUpdatesOutlinedIcon />,
    title:
      'Are you doing something you feel like you should get extra credit for ?',
    href: 'https://forms.gle/2WSqnFX4msiGm9b97',
  },
];

interface Props {
  entity?: RepositoryEntityV1alpha1 | null;
  showOnlyChart?: boolean;
}

export const QualityScore = ({ entity, showOnlyChart }: Props) => {
  const catalogEntityRoute = useRouteRef(entityRouteRef);
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState(0);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { categories, points, maxPoints } = React.useMemo(
    () => ({
      categories: entity?.spec?.qualityMetricsReport?.categories ?? [],
      points: getNumber(entity?.spec?.qualityMetricsReport?.points),
      maxPoints: getNumber(entity?.spec?.qualityMetricsReport?.pointsMax),
    }),
    [entity?.spec?.qualityMetricsReport],
  );

  if (showOnlyChart) {
    return (
      <Link
        to={
          entity
            ? `${catalogEntityRoute(entityRouteParams(entity))}/quality-score`
            : ''
        }
      >
        <GaugeChart
          {...{ maxPoints, points }}
          style={{ width: '100%', maxWidth: 100 }}
        />
      </Link>
    );
  }

  return (
    <Stack direction="row" position="relative">
      <Box
        sx={{ width: '100%' }}
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <Box>
          <Typography
            variant="body1"
            fontWeight="bold"
            color="white"
            sx={{
              position: 'absolute',
              top: 130,
              right: 'calc(50% + 95px)',
            }}
          >
            Low Risk
          </Typography>
          <Typography
            variant="body1"
            fontWeight="bold"
            color="white"
            sx={{
              position: 'absolute',
              top: 50,
              right: 'calc(50% + 10px)',
            }}
          >
            Medium Risk
          </Typography>
          <Typography
            variant="body1"
            fontWeight="bold"
            color="white"
            sx={{
              position: 'absolute',
              top: 50,
              right: 'calc(50% - 95px)',
            }}
          >
            High Risk
          </Typography>
          <Typography
            variant="body1"
            fontWeight="bold"
            color="white"
            sx={{
              position: 'absolute',
              top: 130,
              right: 'calc(50% - 180px)',
              width: 100,
              textAlign: 'center',
            }}
          >
            Critical Risk
          </Typography>
        </Box>
        <GaugeChart {...{ maxPoints, points }} style={{ width: 400 }} />
        <Stack flexDirection="column" alignItems="center">
          <Typography variant="h6" fontWeight="bold" color="gray">
            {points} / {maxPoints}
          </Typography>
          <Typography variant="h6" fontWeight="bold" color="rgba(0, 0, 0, 0.6)">
            Score Breakdown
          </Typography>
        </Stack>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '100%',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="matrix score card tabs"
          >
            {categories.map((category, index) => (
              <Tab
                key={category.name}
                label={category.name}
                {...tabA11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        {categories.map((category, index) => (
          <CustomTabPanel key={category.name} value={value} index={index}>
            <MetricsCategory categoryInfo={category} />
          </CustomTabPanel>
        ))}
      </Box>
      <IconButton
        sx={{ position: 'absolute', right: 10, top: 10, zIndex: 1 }}
        onClick={toggleDrawer(!open)}
        color={open ? 'inherit' : 'default'}
      >
        {open ? <CancelIcon /> : <InfoIcon />}
      </IconButton>
      {open && (
        <Paper
          sx={{
            right: 15,
            top: 15,
            position: 'absolute',
            maxWidth: 300,
          }}
        >
          <List>
            {INFO_LINKS.map(link => (
              <ListItem key={link.href} disablePadding>
                <ListItemButton
                  onClick={() => {
                    window.open(link.href, '_blank', 'noopener noreferrer');
                  }}
                >
                  <ListItemIcon color="primary">{link.icon}</ListItemIcon>
                  <ListItemText primary={link.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Stack>
  );
};
