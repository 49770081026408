import React from "react";
import {useEntity} from "@backstage/plugin-catalog-react";
import {Grid} from "@material-ui/core";
import YAML from "yaml";
import {InfraDeploymentEntityV1alpha1} from "backend/src/custom-processors/types";
import {CodeEditor} from "../../../../core/Editors/CodeEditor";


const SummaryContent = () => {
  const {entity} = useEntity<InfraDeploymentEntityV1alpha1>();

  return (
    <Grid container spacing={3} alignItems="stretch" style={{height: '100%'}}>
        <CodeEditor
            value={YAML.stringify(entity.spec.coreModelData)}
        />
    </Grid>
  )
}

export default SummaryContent;
