import React from "react";
import {Badge} from "@material-ui/core";
import HasChangesIcon from "@material-ui/icons/SyncProblem";

export type ChangesNotificationBadgeProps = {
  title: string;
}

export const ChangesNotificationBadge = (props: ChangesNotificationBadgeProps) => {
  return (
    <Badge
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      badgeContent={
        <HasChangesIcon style={{
          fontSize: "20px",
          color: "#707070",
          marginRight: "25px",
          marginTop: "18px"
        }}/>
      }
      overlap={"rectangular"}>
      {props.title}
    </Badge>
  )
}
