import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#e8e8e8",
    color: "#000000de",
    borderRadius: "0.3em",
    padding: "4px 5px 4px",
    whiteSpace: "nowrap"
  }
})

const InlineCode = (props: { children: string }) => {
  const classes = useStyles();

  return (
    <code className={classes.root}>{props.children}</code>
  )
}

export default InlineCode;
