import React from 'react';
import {EntitySwitch, isKind} from '@backstage/plugin-catalog';
import ComponentPage from "./component/ComponentPage";
import GroupPage from "./group/GroupPage";
import SystemPage from "./system/SystemPage";
import SchemaPage from './schema/SchemaPage';
import DeploymentPage from "./deployment/DeploymentPage";
import ClusterPage from "./cluster/ClusterPage";
import InfraDeploymentPage from "./infra-deployment/InfraDeploymentPage";

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={<ComponentPage/>}/>
    <EntitySwitch.Case if={isKind('deployment')} children={<DeploymentPage/>}/>
    <EntitySwitch.Case if={isKind('infradeployment')} children={<InfraDeploymentPage/>}/>
    <EntitySwitch.Case if={isKind('cluster')} children={<ClusterPage/>}/>
    <EntitySwitch.Case if={isKind('group')} children={<GroupPage/>}/>
    <EntitySwitch.Case if={isKind('schema')} children={<SchemaPage/>}/>
    <EntitySwitch.Case if={isKind('system')} children={<SystemPage/>}/>
  </EntitySwitch>
);
