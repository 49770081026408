import React from "react";
import OverviewContent from "./tabs/overview/OverviewContent";
import {EntityLayout} from "../../entity/EntityLayout/EntityLayout";
import {EntityHeaderBreadcrumbs} from "../../entity/EntityHeaderBreadcrumbs/EntityHeaderBreadcrumbs";
import {Link} from "@backstage/core-components";

export const ClusterTableSubtitle = () => {
  return (
    <>The list of clusters comes from the <Link
      to="https://github.com/weave-lab/deployer-resources/tree/main/clusters">deployer-resources</Link> repository.</>
  )
}

const ClusterPage = () => {
  return (
    <EntityLayout headerRoutingBreadcrumbs={<EntityHeaderBreadcrumbs/>}>
      <EntityLayout.Route path="/" title="Overview">
        {<OverviewContent/>}
      </EntityLayout.Route>
    </EntityLayout>
  );
}

export default ClusterPage;
