const GRADE_PERCENT_MAPPING: Record<string, number> = {
  'A+': 95,
  A: 90,
  'A-': 85,
  'B+': 80,
  B: 75,
  'B-': 70,
  'C+': 65,
  C: 60,
  'C-': 55,
  'D+': 50,
  D: 45,
  'D-': 40,
  'E+': 35,
  E: 30,
  'E-': 25,
  'F+': 20,
  F: 15,
  'F-': 10,
};

export const getGradeColor = (gradeLetter?: string) => {
  const gradePercent = GRADE_PERCENT_MAPPING[gradeLetter ?? 'F-'] ?? 0;
  return `hsl(${gradePercent}, 100%, 75%)`;
};
