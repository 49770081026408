import React, {
  ComponentType,
  createContext,
  useContext,
  ReactNode,
} from 'react';
import { useParams } from 'react-router-dom';
import { useTechDocsReaderPage } from '@backstage/plugin-techdocs-react';

import { useReaderState, ReaderState } from './useReaderState';

const TechDocsReaderContext = createContext<ReaderState>({} as ReaderState);

export const useTechDocsReader = () => useContext(TechDocsReaderContext);

/**
 * @public Render function for {@link TechDocsReaderProvider}
 */
export type TechDocsReaderProviderRenderFunction = (
  value: ReaderState,
) => JSX.Element;

/**
 * @public Props for {@link TechDocsReaderProvider}
 */
export type TechDocsReaderProviderProps = {
  children: TechDocsReaderProviderRenderFunction | ReactNode;
};

/**
 * Provides shared building process state to the reader page components.
 *
 * @public
 */
export const TechDocsReaderProvider = (props: TechDocsReaderProviderProps) => {
  const { children } = props;

  const { '*': path = '' } = useParams();
  const { entityRef } = useTechDocsReaderPage();
  const { kind, namespace, name } = entityRef;
  const value = useReaderState(kind, namespace, name, path);

  return (
    <TechDocsReaderContext.Provider value={value}>
      {children instanceof Function ? children(value) : children}
    </TechDocsReaderContext.Provider>
  );
};

export const withTechDocsReaderProvider =
  <T extends {}>(Component: ComponentType<T>) =>
    (props: T) =>
      (
        <TechDocsReaderProvider>
          <Component {...props} />
        </TechDocsReaderProvider>
      );
