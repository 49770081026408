import {makeStyles} from "@material-ui/core/styles";
import {useEntity} from "@backstage/plugin-catalog-react";
import {Breadcrumbs, Link} from "@backstage/core-components";
import {Typography} from "@material-ui/core";
import {getEntityInfo, getEntityName} from "../utils/entityUtils";
import React from "react";

const useStyles = makeStyles({
  breadcrumbs: {
    color: '#FFFFFF',
    fontSize: '14px'
  }
});

export const EntityHeaderBreadcrumbs = () => {
  const {entity} = useEntity();
  const classes = useStyles();

  const entityInfo = getEntityInfo(entity);

  return (
    <Breadcrumbs className={classes.breadcrumbs}>
      <Link to="/catalog">Catalog</Link>
      <Link to={entityInfo.parentCatalogLink}>{entityInfo?.kindFriendlyPlural}</Link>
      <Typography>{getEntityName(entity)}</Typography>
    </Breadcrumbs>
  )
}
