import React from 'react';
import {HomePageCompanyLogo} from '@backstage/plugin-home';
import {Content, Header, Page} from '@backstage/core-components';
import {SearchContextProvider} from '@backstage/plugin-search-react';
import {Grid, makeStyles} from '@material-ui/core';
import Logo from "../root/logo/Logo";
import {EngineeringSupportTeamsInfoCard} from "./EngineeringSupportTeamsInfoCard/EngineeringSupportTeamsInfoCard";
import {HomePageSearchBar} from "@backstage/plugin-search";
import {HeaderWorldClock} from "../core/HeaderWorldClock/HeaderWorldClock";
import {HelpfulLinksCard} from "./HelpfulLinksCard/HelpfulLinksCard";
import {FavoriteEntitiesCard} from "./FavoriteEntitiesCard/FavoriteEntitiesCard";

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    // maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));


export const HomePage = () => {
  const classes = useStyles();
  const {container} = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title="Home">
          <HeaderWorldClock/>
        </Header>
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid container xs={12} justifyContent='center'>
              <HomePageCompanyLogo
                className={container}
                logo={<Logo/>}
              />
            </Grid>
            <Grid container item xs={12} sm={10} md={8} lg={6} justifyContent='center'>
              <HomePageSearchBar
                classes={{root: classes.searchBar}}
                InputProps={{classes: {notchedOutline: classes.searchBarOutline}}}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HelpfulLinksCard/>
              </Grid>
              <Grid item xs={12} md={6}>
                <FavoriteEntitiesCard/>
              </Grid>
              <Grid item xs={12}>
                <EngineeringSupportTeamsInfoCard/>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
