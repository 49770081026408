import React from "react";
import {CardTab, InfoCard, Link, TabbedCard} from "@backstage/core-components";
import {isMobile} from 'mobile-device-detect';
import {Accordion, AccordionSummary} from "@material-ui/core";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  accordion: {
    root: {
      expanded: {
        margin: '-50px',
        borderBottom: '1px solid #cccaca',
      }
    }
  },
  accordionSummary: {
    fontWeight: 'bold',
  }
})

export const EngineeringSupportTeamsInfoCard = () => {
  if (isMobile) {
    return <EngineeringSupportTeamsInfoCardMobile/>;
  }

  return <EngineeringSupportTeamsInfoCardWeb/>;
}

const EngineeringSupportTeamsInfoCardMobile = () => {
  const classes = useStyles();

  return (
    <InfoCard title="Engineering Support Teams" noPadding>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.accordionSummary}
        >
          Developer Experience
        </AccordionSummary>
        <AccordionDetails>
          <DeveloperExperienceContent/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2-content"
          id="panel2-header"
          className={classes.accordionSummary}
        >
          Test Infrastructure
        </AccordionSummary>
        <AccordionDetails>
          <TestInfrastructureContent/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2-content"
          id="panel2-header"
          className={classes.accordionSummary}
        >
          Data Infrastructure
        </AccordionSummary>
        <AccordionDetails>
          <DataInfrastructureContent/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2-content"
          id="panel2-header"
          className={classes.accordionSummary}
        >
          Users Infrastructure
        </AccordionSummary>
        <AccordionDetails>
          <UsersInfrastructureContent/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2-content"
          id="panel2-header"
          className={classes.accordionSummary}
        >
          SRE
        </AccordionSummary>
        <AccordionDetails>
          <SREContent/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2-content"
          id="panel2-header"
          className={classes.accordionSummary}
        >
          Front-end Ops
        </AccordionSummary>
        <AccordionDetails>
          <FrontendOpsContent/>
        </AccordionDetails>
      </Accordion>
    </InfoCard>
  )
}

const EngineeringSupportTeamsInfoCardWeb = () => {
  return (
    <TabbedCard title="Engineering Support Teams">
      <CardTab label="Developer Experience">
        <DeveloperExperienceContent/>
      </CardTab>
      <CardTab label="Test Infrastructure">
        <TestInfrastructureContent/>
      </CardTab>
      <CardTab label="Data Infrastructure">
        <DataInfrastructureContent/>
      </CardTab>
      <CardTab label="Users Infrastructure">
        <UsersInfrastructureContent/>
      </CardTab>
      <CardTab label="SRE">
        <SREContent/>
      </CardTab>
      <CardTab label="Front-end Ops">
        <FrontendOpsContent/>
      </CardTab>
    </TabbedCard>
  )
}

const DeveloperExperienceContent = () => {
  return (
    <>
      <p>
        The Developer Experience, or DevX, team is responsible for the majority of infrastructure and
        tooling that developers use to build and deploy their services.
      </p>
      <p>
        This includes the CI/CD pipeline, shared GitHub actions and our self-hosted runners, deployment
        pipeline, bart, Weave Docs, Schema system, shared Go libraries, and much more.
      </p>
      For more information about the DevX team, or to get help with any of the tools and infrastructure
      we own:
      <ul>
        <li>visit our Knowledge Base: <Link
          to="https://switchboard.weavelab.ninja/catalog/default/component/devx-knowledgebase/docs/">https://docs.weavelab.ninja/weave/devx-knowledgebase/</Link>
        </li>
        <li>visit our team info page: <Link
          to="https://switchboard.weavelab.ninja/catalog/default/component/teams/docs/devx/">https://docs.weavelab.ninja/weave/teams/devx/</Link>
        </li>
        <li>reach out to us on Slack in the <Link
          to="https://getweave.slack.com/archives/C01148W2F8B">#dev-experience</Link> channel or use the @devx Slack
          handle
        </li>
      </ul>
    </>
  )
}

const TestInfrastructureContent = () => {
  return (
    <>
      <p>
        The Test Infrastructure team, or test-infra, is responsible for all of the tooling and infrastructure related
        to testing.
      </p>
      <p>
        <b>Mission:</b>
        <ul>
          <li>Provide ready-to-use testing tools and frameworks that are a delight for Weave’s developers to use
            (adopt, implement).
          </li>
          <li>Support and enable testing best practices at Weave through documentation, coaching, easy to use tooling,
            and visibility on overall software quality.
          </li>
        </ul>
      </p>
      <p>
        For more information about the Test Infrastructure team, or to get help with any of the tools and
        infrastructure
        we own:
        <ul>
          <li>visit our info page in the docs site: <Link
            to="https://switchboard.weavelab.ninja/catalog/default/component/teams/docs/test-infrastructure/">https://docs.weavelab.ninja/weave/teams/test-infrastructure</Link>
          </li>
          <li>reach out to us on Slack in the <Link
            to="https://getweave.slack.com/archives/C0102287N3G">#test-infrastructure</Link> channel or use the
            @testinfra Slack handle for fastest response times
          </li>
        </ul>
      </p>
    </>
  )
}

const DataInfrastructureContent = () => {
  return (
    <>
      <p>
        The data infrastructure, or data-infra, team helps support many data systems at Weave including Cloud SQL
        Postgres,
        Kafka, BigQuery, and more.
      </p>
      <p>
        Our team also owns a few key data systems including Data-Service which has been at the center of multiple
        Weave systems for many years.
      </p>
      <p>
        Our goal is to provide a reliable and easy to use data infrastructure and tooling for all of development at
        Weave.
        We try to solve difficult problems and pave a path so that you don't have to spend your time on them.
      </p>
      <p>
        If you have questions or suggestions about any of these systems, please reach out to the data infrastructure
        team
        in the <Link to="https://getweave.slack.com/archives/C02F60QH0V9">#data-infra</Link> Slack channel or use the
        @DataBear handle.
      </p>
    </>
  )
}

const UsersInfrastructureContent = () => {
  return (
    <>
      <p>
        The Users Infrastructure, or users-infra, team is responsible for authentication, authorization and auditing.
      </p>
      <p>
        We provide the login experience and integrations into our various customer facing applications, the
        infrastructure for development teams to manage user's access, and infrastructure for development teams to
        record user actions for audit logs.
      </p>
      <p>
        For help with issues, feature requests or general help with authentication, authorization and auditing, reach
        out in the <Link to="https://getweave.slack.com/archives/C02UXH7713M">#users-infra-support</Link> Slack
        channel or use the @userx handle.
      </p>
      <p>
        If you need additional access or role changes in the Weave application reach out to the IT Helpdesk through
        their Slack handle @Helpdesk.
      </p>
    </>
  )
}

const SREContent = () => {
  return (
    <>
      <p>
        The SRE team is responsible for deploying and maintaining all the Google Cloud Platform (GCP) infrastructure
        and core Kubernetes (k8s) addons needed for Weave applications.
      </p>
      <p>
        This includes our metrics platform (Grafana, Prometheus, Thano-Ruler, etc), Substrate our applications run on
        (GCP/GKE), our gitops systems (ArgoCD), public and private ingress (Traefik/Nginx) controllers, secret
        management (vault) and many more services.
        <ul>
          <li>Visit our docs/faq site here <Link
            to="https://switchboard.weavelab.ninja/catalog/default/component/teams/docs/sre/gorilla-faq/">https://docs.weavelab.ninja/weave/teams/sre/gorilla-faq/</Link>
          </li>
          <li>If you need help with anything feel free to ping The Gorilla (@gorilla) in the <Link
            to="https://getweave.slack.com/archives/CC97WFMCM">#sre</Link> Slack channel
          </li>
        </ul>
      </p>
    </>
  )
}

const FrontendOpsContent = () => {
  return (
    <>
      <p>
        The Frontend Operations, or frontend-ops, team is responsible for tooling, workflows and support for our
        frontend engineers.
      </p>
      <p>
        This includes Github Workflows, linting, frontend testing infrastructure and tools for the desktop client
        (electron shell).
      </p>
      <p>
        For help with issues, feature requests or general help with frontend operations related tasks, reach out in
        the <Link to="https://getweave.slack.com/archives/C01T9T9TBAL">#frontend-ops-public</Link> Slack channel, or
        use the @fedops handle.
      </p>
    </>
  )
}
