import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { getGradeColor } from './helpers';

interface Props {
  percent?: number;
  grade?: string;
  maxPoints?: number;
  optPoints?: number;
}

export const ScorePointInfo = ({
  grade,
  maxPoints,
  optPoints,
  percent,
}: Props) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      divider={<Divider orientation="vertical" flexItem variant="middle" />}
      sx={{
        height: 25,
        opacity: 0.9,
        bgcolor: getGradeColor(grade),
        paddingLeft: 1,
        paddingRight: 1,
        marginRight: 1,
        borderRadius: 4,
        justifyContent: 'space-around',
        boxShadow: `0px 0px 1px 2px ${getGradeColor(grade)}`,
        '> p': {
          minWidth: 30,
          textAlign: 'center',
        },
      }}
      display="inline-flex"
    >
      <Typography variant="body2">
        {optPoints || 0}/{maxPoints}
      </Typography>
      <Typography variant="body2">
        {!percent && percent !== 0 ? 'N/A' : `${percent}%`}
      </Typography>
      <Typography variant="body2">{grade}</Typography>
    </Stack>
  );
};
