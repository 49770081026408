import {Grid} from '@material-ui/core';
import React from 'react';
import {UserSettingsProfileCard} from "@backstage/plugin-user-settings";

/** @public */
export const UserProfileOverviewContent = () => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} md={6}>
        <UserSettingsProfileCard/>
      </Grid>
    </Grid>
  );
};
