import {withStyles} from "@material-ui/core/styles";
import {MTableToolbar} from "@material-table/core";
import React, {MutableRefObject, useCallback} from "react";
import {isMobile} from "mobile-device-detect";

const StyledMTableToolbar = withStyles(
  theme => ({
    root: {
      padding: theme.spacing(3, 0, 2.5, 2.5),
      display: isMobile ? 'block' : "flex"
    },
    title: {
      '& > h6': {
        fontWeight: theme.typography.fontWeightBold,
      },
      marginBottom: '10px'
    },
    searchField: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      width: isMobile ? '100%' : '25%'
    },
  }),
  {name: 'BackstageTableToolbar'},
)(MTableToolbar);

export function TableToolbar(toolbarProps: {
  toolbarRef: MutableRefObject<any>;
  setSearch: (value: string) => void;
  onSearchChanged: (value: string) => void;
  toggleFilters: () => void;
  hasFilters: boolean;
  selectedFiltersLength: number;
}) {
  const {
    toolbarRef,
    setSearch,
  } = toolbarProps;
  const onSearchChanged = useCallback(
    (searchText: string) => {
      toolbarProps.onSearchChanged(searchText);
      setSearch(searchText);
    },
    [toolbarProps, setSearch],
  );

  return (
    <StyledMTableToolbar
      {...toolbarProps}
      ref={toolbarRef}
      onSearchChanged={onSearchChanged}
    />
  );
}
