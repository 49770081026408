import React, { useCallback, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import {
  TechDocsShadowDom,
  useShadowDomStylesLoading,
  useShadowRootElements,
  useTechDocsReaderPage,
} from '@backstage/plugin-techdocs-react';
import { CompoundEntityRef } from '@backstage/catalog-model';
import {Content, ErrorPage, Link} from '@backstage/core-components';
import {TechDocsSearch} from "@backstage/plugin-techdocs";
import {TechDocsStateIndicator} from "./TechDocsStateIndicator";
import {useTechDocsReaderDom} from "./dom";
import {withTechDocsReaderProvider} from "./TechDocsReaderProvider";
import {TechDocsReaderPageContentAddons} from "./TechDocsReaderPageContentAddons";

const useStyles = makeStyles({
  search: {
    width: '100%',
    '@media (min-width: 76.1875em)': {
      width: 'calc(100% - 34.4rem)',
      margin: '0 auto',
    },
    '@media print': {
      display: 'none',
    },
  },
});

/**
 * Props for {@link TechDocsReaderPageContent}
 * @public
 */
export type TechDocsReaderPageContentProps = {
  /**
   * @deprecated No need to pass down entityRef as property anymore. Consumes the entityName from `TechDocsReaderPageContext`. Use the {@link @backstage/plugin-techdocs-react#useTechDocsReaderPage} hook for custom reader page content.
   */
  entityRef?: CompoundEntityRef;
  /**
   * Show or hide the search bar, defaults to true.
   */
  withSearch?: boolean;
  /**
   * Callback called when the content is rendered.
   */
  onReady?: () => void;
};

/**
 * Renders the reader page content
 * @public
 */
export const TechDocsReaderPageContent = withTechDocsReaderProvider(
  (props: TechDocsReaderPageContentProps) => {
    const { withSearch = true, onReady } = props;
    const classes = useStyles();

    const {
      entityMetadata: { value: entityMetadata, loading: entityMetadataLoading },
      entityRef,
      setShadowRoot,
    } = useTechDocsReaderPage();
    const dom = useTechDocsReaderDom(entityRef);
    const path = window.location.pathname;
    const hash = window.location.hash;
    const isStyleLoading = useShadowDomStylesLoading(dom);
    const [hashElement] = useShadowRootElements([`[id="${hash.slice(1)}"]`]);

    useEffect(() => {
      if (isStyleLoading) return;

      if (hash) {
        if (hashElement) {
          hashElement.scrollIntoView();
        }
      } else {
        document?.querySelector('header')?.scrollIntoView();
      }
    }, [path, hash, hashElement, isStyleLoading]);

    const handleAppend = useCallback(
      (newShadowRoot: ShadowRoot) => {
        setShadowRoot(newShadowRoot);
        if (onReady instanceof Function) {
          onReady();
        }
      },
      [setShadowRoot, onReady],
    );

    // No entity metadata = 404. Don't render content at all.
    if (entityMetadataLoading === false && !entityMetadata)
      return <ErrorPage
        status="404"
        statusMessage="PAGE NOT FOUND"
        additionalInfo={<p>This usually happens when there is a misconfiguration in the `documentation` section of the .weave.yaml.
          A common mistake is when the `slug` field inside the .weave.yaml file does not match the name of the repository.
          This can also include not matching the casing of the repository name. You can also refer to
          the <Link to={"https://switchboard.weavelab.ninja/catalog/default/component/waml/docs/#documentation"}>.weave.yaml documentation section docs.</Link></p>}
      />;

    // Do not return content until dom is ready; instead, render a state
    // indicator, which handles progress and content errors on our behalf.
    if (!dom) {
      return (
        <Content>
          <Grid container>
            <Grid xs={12} item>
              <TechDocsStateIndicator />
            </Grid>
          </Grid>
        </Content>
      );
    }

    return (
      <Content>
        <Grid container>
          <Grid xs={12} item>
            <TechDocsStateIndicator />
          </Grid>
          {withSearch && (
            <Grid className={classes.search} xs="auto" item>
              <TechDocsSearch
                entityId={entityRef}
                entityTitle={entityMetadata?.metadata?.title}
              />
            </Grid>
          )}
          <Grid xs={12} item>
            {/* Centers the styles loaded event to avoid having multiple locations setting the opacity style in Shadow Dom causing the screen to flash multiple times */}
            <TechDocsShadowDom element={dom} onAppend={handleAppend}>
              <TechDocsReaderPageContentAddons />
            </TechDocsShadowDom>
          </Grid>
        </Grid>
      </Content>
    );
  },
);

/**
 * Props for {@link Reader}
 *
 * @public
 * @deprecated use `TechDocsReaderPageContentProps` instead.
 */
export type ReaderProps = TechDocsReaderPageContentProps;

/**
 * Component responsible for rendering TechDocs documentation
 * @public
 * @deprecated use `TechDocsReaderPageContent` component instead.
 */
export const Reader = TechDocsReaderPageContent;
