import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {SidebarItem} from '@backstage/core-components';
import {IconComponent} from '@backstage/core-plugin-api';
import {SearchModal, SearchModalChildrenProps, useSearchModal} from './SearchModal';
import {Chip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

/**
 * Props for {@link SidebarSearchModal}.
 *
 * @public
 */
export type SidebarSearchModalProps = {
  icon?: IconComponent;
  open?: boolean;
  children?: (props: SearchModalChildrenProps) => JSX.Element;
};

const useStyles = makeStyles(_theme => ({
  root: {
    color: '#FFF'
  },
  secondaryAction: {
    color: '#FFF',
    border: '1px solid #b6b6b6de',
    marginTop: '5px',
    borderRadius: '5px'
  }
}))

export const SidebarSearchModal = (props: SidebarSearchModalProps) => {
  const classes = useStyles();
  const {state, toggleModal} = useSearchModal(props.open);
  const Icon = props.icon ? props.icon : SearchIcon;

  return (
    <>
      <SidebarItem
        className={classes.root}
        icon={Icon}
        text={"Search"}
        onClick={toggleModal}
      >
        <><Chip label="/" className={classes.secondaryAction} variant="outlined" size="small" /></>
      </SidebarItem>
      <SearchModal
        {...state}
        toggleModal={toggleModal}
        children={props.children}
      />
    </>
  );
};
