import * as React from 'react';
import { MetricInfo } from './MetricInfo';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { ReportCategory } from '@weave/schema-gen-ts/dist/schemas/quality-metrics/quality_metrics.pb';

interface Props {
  categoryInfo: ReportCategory;
}

export const MetricsCategory = ({ categoryInfo }: Props) => {
  return (
    <Box>
      {(categoryInfo?.subcategories ?? []).map(subCategory => {
        return (
          <Stack
            key={subCategory.name}
            sx={{ borderBottom: '1px solid lightgray' }}
            p={3}
          >
            <Typography variant="overline" fontSize={16} fontWeight="bold">
              {subCategory.name}
            </Typography>
            {(subCategory.metrics ?? []).map(metric => {
              return (
                <MetricInfo
                  key={metric.id}
                  title={metric.name}
                  description={metric.description}
                  points={metric.points}
                  maxPoints={metric.pointsMax}
                  numberType={metric.numberType}
                  recordedValue={metric.recordedValue}
                />
              );
            })}
          </Stack>
        );
      })}
      {!!categoryInfo?.metrics?.length && (
        <Stack sx={{ borderBottom: '1px solid lightgray' }} p={3}>
          <Typography variant="overline" fontSize={16} fontWeight="bold">
            {categoryInfo.name}
          </Typography>
          {(categoryInfo?.metrics ?? []).map(metric => {
            return (
              <MetricInfo
                key={metric.id}
                title={metric.name}
                description={metric.description}
                points={metric.points}
                maxPoints={metric.pointsMax}
                numberType={metric.numberType}
                recordedValue={metric.recordedValue}
              />
            );
          })}
        </Stack>
      )}
    </Box>
  );
};
