import {CatalogApi} from "@backstage/plugin-catalog-react";
import {RepositoryEntityV1alpha1} from "../../../../../backend/src/custom-processors/types";

/**
 * Get the repository entity from the catalog API
 * @param catalogApiClient
 * @param name
 */
export async function getRepositoryEntity(catalogApiClient: CatalogApi, name: string): Promise<RepositoryEntityV1alpha1> {
  let returnEntity: RepositoryEntityV1alpha1 = {} as RepositoryEntityV1alpha1;

  if (!name) {
    return returnEntity;
  }

  await catalogApiClient.getEntityByRef({
    name: name,
    namespace: 'default',
    kind: 'Component'
  }).then((e) => {
    if (!e) {
      return;
    }
    const entity = e as RepositoryEntityV1alpha1;
    if (!entity?.spec?.docsMetadata) {
      return;
    }

    returnEntity = entity
  })

  return returnEntity;
}
