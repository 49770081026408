import {useState} from "react";
import {Entity} from "@backstage/catalog-model";
import {useApi} from "@backstage/core-plugin-api";
import {catalogApiRef} from "@backstage/plugin-catalog-react";

interface useEntityByRefProps {
  name: string;
  namespace?: string;
  kind?: string;
}

export const useEntityByRef = (props: useEntityByRefProps) => {
  const {
    namespace = "default",
    kind = "Component"
  } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [entity, setEntity] = useState<Entity | undefined>(undefined);

  const catalogApiClient = useApi(catalogApiRef);
  catalogApiClient.getEntityByRef({
    name: props.name,
    namespace: namespace,
    kind: kind
  }).catch(setError)
    .finally(() => setLoading(false))
    .then((e) => {
      if (!e) {
        return;
      }

      if (JSON.stringify(e) === JSON.stringify(entity)) {
        return;
      }

      setEntity(e);
    })

  return {
    loading,
    error,
    entity
  }
}
