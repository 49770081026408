import React from "react";
import {Grid} from "@material-ui/core";
import {EntityCatalogGraphCard} from "@backstage/plugin-catalog-graph";
import {AboutCard} from "../../../../core/AboutCard/AboutCard";
import {EntityWarningContent} from "../../../../entity/EntityWarningContent/EntityWarningContent";
import {CustomRenderNode} from "../../../../catalog-graph/CustomRenderNode";
import {useEntity} from "@backstage/plugin-catalog-react";
import {InfraDeploymentEntityV1alpha1} from "../../../../../../../backend/src/custom-processors/types";
import {EntityLink} from "@backstage/catalog-model";
import {AnnotationLink} from "@weave/schema-gen-ts/dist/schemas/apps-info/v2/service.pb";

const OverviewContent = () => {
  const {entity} = useEntity<InfraDeploymentEntityV1alpha1>();

  return (
    <Grid container spacing={3}>
      {<EntityWarningContent/>}
      <Grid item xs={12}>
        <AboutCard
          externalLinks={annotationLinksToEntityLinks(entity.spec?.coreModelData?.annotationLinks)}
          externalLinksRef={"https://switchboard.weavelab.ninja/catalog/default/component/waml/docs/deployment_meta/#external-links"}
        />
      </Grid>
      <Grid item xs={12}>
        <EntityCatalogGraphCard variant="gridItem" height={300} maxDepth={1} renderNode={CustomRenderNode}/>
      </Grid>
    </Grid>
  )
}

const annotationLinksToEntityLinks = (annotationLinks: AnnotationLink[] | undefined): EntityLink[] => {
  let entityLinks: EntityLink[] = [];

  if (!annotationLinks) {
    return entityLinks;
  }

  annotationLinks.forEach(annotationLink => {
    entityLinks.push({
      title: annotationLink.title,
      url: annotationLink.url || "",
    });
  });

  return entityLinks
}

export default OverviewContent;
