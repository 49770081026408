import React from "react";
import {Grid} from "@material-ui/core";
import {AboutCard} from "../../../../core/AboutCard/AboutCard";
import {useEntity, useRelatedEntities} from "@backstage/plugin-catalog-react";
import {RELATION_OWNER_OF} from "@backstage/catalog-model";
import {EntityDataTable} from "../../../../entity/EntityDataTable/EntityDataTable";
import {EntityWarningContent} from "../../../../entity/EntityWarningContent/EntityWarningContent";
import {createDeploymentTableLinksColumn, DeploymentTableSubtitle} from "../../../deployment/DeploymentPage";
import {createNameColumn, createOwnerColumn} from "../../../../entity/utils/tableColumns";

const OverviewContent = () => {
  const {entity} = useEntity();
  const {entities, loading, error} = useRelatedEntities(entity, {
    type: RELATION_OWNER_OF,
    kind: "Deployment",
  });

  return (
    <Grid container spacing={3}>
      {<EntityWarningContent/>}
      <Grid item md={12}>
        <AboutCard/>
      </Grid>
      <Grid item md={12}>
        <EntityDataTable
          isLoading={loading}
          error={error}
          columns={[
            createNameColumn(),
            createOwnerColumn(),
            createDeploymentTableLinksColumn(),
          ]}
          title="Deployments"
          subtitle={<DeploymentTableSubtitle/>}
          entities={entities}
          emptyMsg="No deployments found."
        />
      </Grid>
    </Grid>
  )
}

export default OverviewContent;
