import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { entityRouteParams } from '@backstage/plugin-catalog-react';
import {
  ListItem,
  ListItemIcon,
  Tooltip,
  IconButton,
  ListItemText,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import { useRouteRef } from '@backstage/core-plugin-api';
import StarIcon from '@material-ui/icons/Star';

type EntityListItemProps = {
  entity: Entity;
  onToggleStarredEntity: (entity: Entity) => void;
};

export const FavoriteEntityListItem = ({
                                        entity,
                                        onToggleStarredEntity,
                                      }: EntityListItemProps) => {
  const catalogEntityRoute = useRouteRef(entityRouteRef);

  return (
    <ListItem key={stringifyEntityRef(entity)}>
      <ListItemIcon>
        <Tooltip title="Remove from starred">
          <IconButton
            edge="end"
            aria-label="unstar"
            onClick={() => onToggleStarredEntity(entity)}
          >
            <StarIcon style={{ color: '#f3ba37' }} />
          </IconButton>
        </Tooltip>
      </ListItemIcon>
      <Link to={catalogEntityRoute(entityRouteParams(entity))}>
        <ListItemText primary={entity.metadata.title ?? entity.metadata.name} />
      </Link>
    </ListItem>
  );
};
