import {makeStyles} from "@material-ui/core/styles";
import {useSearch} from "@backstage/plugin-search-react";
import React, {ChangeEvent, useEffect} from "react";
import {FormControl, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";

export type SearchFilterComponentProps = {
  className?: string;
  name: string;
  label?: string;
  /**
   * Either an array of values directly, or an async function to return a list
   * of values to be used in the filter. In the autocomplete filter, the last
   * input value is provided as an input to allow values to be filtered. This
   * function is debounced and values cached.
   */
  values?: SearchFilterValue[];
  defaultValue?: string[] | string | null;
  /**
   * Debounce time in milliseconds, used when values is an async callback.
   * Defaults to 250ms.
   */
  valuesDebounceMs?: number;
};

export type SearchFilterValue = {
  name: string;
  value: string;
  type?: string;
}

const useStyles = makeStyles({
  label: {
    textTransform: 'capitalize',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  textWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const SelectFilter = (props: SearchFilterComponentProps) => {
  const {
    className,
    defaultValue,
    label,
    name,
    values,
  } = props;
  const classes = useStyles();
  useDefaultFilterValue(name, defaultValue);
  const {filters, setFilters, types, setTypes} = useSearch();

  const handleChange = (e: ChangeEvent<{ value: unknown }>) => {
    const {
      target: {value},
    } = e;

    if (value === 'Documentation') {
      setFilters({});
      setTypes(['techdocs']);
    } else if (value === 'Component') {
      setFilters({kind: "Component", type: "repository"});
      setTypes([]);
    } else if (value === 'All') {
      setFilters({});
      setTypes([]);
    } else {
      setTypes([]);
      setFilters({[name]: value as string});
    }
  };

  let selectValue = filters[name];
  if (!selectValue) {
    selectValue = types.includes('techdocs') ? 'Documentation' : 'All';
  }

  return (
    <FormControl
      className={className}
      variant="filled"
      fullWidth
      data-testid="search-selectfilter-next"
    >
      {label ? (
        <InputLabel className={classes.label} margin="dense">
          {label}
        </InputLabel>
      ) : null}
      <Select
        variant="outlined"
        value={selectValue}
        onChange={handleChange}
      >
        {values?.map(({name, value}) => (
          <MenuItem key={value} value={value}>
            <Typography variant="inherit" noWrap>
              {name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

/**
 * Utility hook for applying a given default value to the search context.
 *
 * @public
 */
export const useDefaultFilterValue = (
  name: string,
  defaultValue?: string | string[] | null,
) => {
  const {setFilters} = useSearch();

  useEffect(() => {
    if (defaultValue && [defaultValue].flat().length > 0) {
      setFilters(prevFilters => ({
        ...prevFilters,
        [name]: defaultValue,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
