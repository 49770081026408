import React from "react";
import {Grid} from "@material-ui/core";
import {useEntity} from "@backstage/plugin-catalog-react";
import YAML from 'yaml';
import {TeamEntityV1alpha1} from "backend/src/custom-processors/types";
import {CodeEditor} from "../../../../../core/Editors/CodeEditor";

const DeployerResourcesContent = () => {
  const {entity} = useEntity<TeamEntityV1alpha1>();

  return (
    <Grid container spacing={3} alignItems="stretch" style={{height: '100%'}}>
        <CodeEditor
            value={YAML.stringify(entity.spec.teamInfo)}
        />
    </Grid>
  )
}

export default DeployerResourcesContent;
