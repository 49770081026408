import React from "react";
import OverviewContent from "./tabs/overview/OverviewContent";
import DefinitionContent from "./tabs/definition/DefinitionContent";
import GoDocContent from "./tabs/godocs/GoDocContent";
import OpenAPIContent from "./tabs/openapi/OpenAPIContent";
import {EntityLayout} from "../../entity/EntityLayout/EntityLayout";
import {EntityHeaderBreadcrumbs} from "../../entity/EntityHeaderBreadcrumbs/EntityHeaderBreadcrumbs";
import {Link, TableColumn} from "@backstage/core-components";
import {CatalogTableRow} from "@backstage/plugin-catalog";
import {createNameColumn, createOwnerColumn} from "../../entity/utils/tableColumns";
import {isMobile} from "mobile-device-detect";
import {SchemaEntityV1alpha1} from "../../../../../backend/src/custom-processors/types";

export const createSchemaTableLinksColumn = (width?: string): TableColumn<CatalogTableRow> => {
  return {
    title: 'Links',
    render: ({entity}) => {
      const schemaEntity = entity as SchemaEntityV1alpha1;
      return (
        <ul>
          <li>
            <Link
              to={`https://github.com/weave-lab/schema/tree/main/weave/schemas/${schemaEntity.spec?.schema?.path}`}>Source</Link>
          </li>
        </ul>
      )
    },
    width: width,
  };
}

export const getSchemaTableColumns = (): TableColumn<CatalogTableRow>[] => {
  let columns: TableColumn<CatalogTableRow>[] = [
    createNameColumn({width: "20%"}),
    createOwnerColumn("30%"),
  ];

  if (!isMobile) {
    columns.push(createSchemaTableLinksColumn("20%"))
  }

  return columns;
}

export const SchemaTableSubtitle = () => {
  return (
    <>The list of schemas comes from the <Link
      to="https://github.com/weave-lab/schema">schema</Link> repository. For more information about the
      schema system, see the <Link
        to="https://switchboard.weavelab.ninja/catalog/default/component/schema-system/docs/">docs</Link>. If a
      schema's owner is listed as 'acl-developers', it is because there is no WAML that specifies that
      schema.</>
  )
}

const SchemaPage = () => {
  return (
    <EntityLayout headerRoutingBreadcrumbs={<EntityHeaderBreadcrumbs/>}>
      <EntityLayout.Route path="/" title="Overview">
        {<OverviewContent/>}
      </EntityLayout.Route>
      <EntityLayout.Route path="/definition" title="Definition">
        {<DefinitionContent/>}
      </EntityLayout.Route>
      <EntityLayout.Route path="/openapi" title="OpenAPI">
        {<OpenAPIContent/>}
      </EntityLayout.Route>
      <EntityLayout.Route path="/godocs" title="GoDocs">
        {<GoDocContent/>}
      </EntityLayout.Route>
    </EntityLayout>
  );
}

export default SchemaPage;
