import React, {ReactNode} from "react";
import {decode} from "../../../../../../../../utils/encoding";
import SuccessIcon from "@material-ui/icons/CheckCircle"
import WarningIcon from "@material-ui/icons/Help"
import ErrorIcon from "@material-ui/icons/Error"

export type ValidationData = {
  globalResults: ValidationResult[];
  perDeployResults: Map<string, ValidationResult[]>;
}

export type ValidationResult = {
  title: string
  errorFeedback: ResultFeedback[];
  warningFeedback: ResultFeedback[];
  infoFeedback: ResultFeedback[];
}

export type ResultFeedback = {
  message: string;
  markdownMessage?: string;
  lineNum?: number;
}

export const parseValidationData = (validationResponse: string | undefined): ValidationData => {
  let validationData: ValidationData = {
    globalResults: [],
    perDeployResults: new Map<string, ValidationResult[]>()
  }

  if (!validationResponse) {
    return validationData
  }

  let json = JSON.parse(decode(validationResponse))
  validationData.globalResults = json?.globalResults;

  Object.keys(json?.perDeployResults).forEach((deployID: string) => {
    validationData.perDeployResults.set(deployID, json?.perDeployResults?.[deployID])
  })

  return validationData
}

export const resultIsEmpty = (result: ValidationResult): boolean => {
  return !result.errorFeedback && !result.warningFeedback && !result.infoFeedback;
}

export const resultsAreEmpty = (results: ValidationResult[]): boolean => {
  return results.every(result => resultIsEmpty(result));
}

export const getBackgroundColorForSeverity = (severity: "error" | "warning" | "info"): string => {
  switch (severity) {
    case "error":
      return "#ffb2b2";
    case "warning":
      return "#ffe175";
    case "info":
      return "#b9deff";
  }
}

export const getConclusionFromResults = (results: ValidationResult[]): ReactNode => {
  let errorCount = 0;
  let warningCount = 0;

  results.forEach(result => {
    errorCount += result.errorFeedback?.length || 0;
    warningCount += result.warningFeedback?.length || 0;
  })

  if (errorCount) {
    return <span style={{color: getBackgroundColorForSeverity("error")}}><ErrorIcon/></span>;
  }

  if (warningCount) {
    return <span style={{color: getBackgroundColorForSeverity("warning")}}><WarningIcon/></span>;
  }

  return <span style={{color: getBackgroundColorForSeverity("info")}}><SuccessIcon/></span>;
}

export const feedbackIsEmpty = (feedback: ResultFeedback): boolean => {
  return !feedback.message && !feedback.markdownMessage;
}
