// Taken from: https://github.com/backstage/backstage/blob/aa53f7add4c97e8eab34c7c7f48910877edc6077/plugins/catalog/src/components/EntityLayout/EntityLayout.tsx
import {DEFAULT_NAMESPACE, Entity,} from '@backstage/catalog-model';
import {Content, Header, Link, Page, Progress, RoutedTabs, WarningPanel,} from '@backstage/core-components';
import {
  attachComponentData,
  createExternalRouteRef,
  IconComponent,
  useElementFilter,
  useRouteRef,
  useRouteRefParams,
} from '@backstage/core-plugin-api';
import {
  EntityDisplayName,
  entityRouteRef,
  FavoriteEntity,
  InspectEntityDialog,
  UnregisterEntityDialog,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import {Box, TabProps} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {catalogPlugin} from "@backstage/plugin-catalog";
import {useUserProfile} from "@backstage/plugin-user-settings";
import {userIsAdmin} from "../../../utils/adminUserEmails";
import {EntityContextMenu} from "./EntityContextMenu";
import {renderIcon} from "../utils/icons";
import {isMobile} from "mobile-device-detect";

/** @public */
export type EntityLayoutRouteProps = {
  path: string;
  title: string;
  children: JSX.Element;
  if?: (entity: Entity) => boolean;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

const dataKey = 'plugin.catalog.entityLayoutRoute';

const Route: (props: EntityLayoutRouteProps) => null = () => null;
attachComponentData(Route, dataKey, true);
attachComponentData(Route, 'core.gatherMountPoints', true); // This causes all mount points that are discovered within this route to use the path of the route itself

function EntityLayoutTitle(props: {
  title: string;
  entity: Entity | undefined;
}) {
  const {entity, title} = props;
  return (
    <Box display="inline-flex" alignItems="center" height="1em" maxWidth="100%">
      <Box
        component="span"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {renderIcon(entity?.kind)}&nbsp;
        {entity ? <EntityDisplayName entityRef={entity} hideIcon/> : title}
      </Box>
      {entity && <FavoriteEntity entity={entity}/>}
    </Box>
  );
}

// NOTE(freben): Intentionally not exported at this point, since it's part of
// the unstable extra context menu items concept below
interface ExtraContextMenuItem {
  title: string;
  Icon: IconComponent;
  onClick: () => void;
}

type VisibleType = 'visible' | 'hidden' | 'disable';

// NOTE(blam): Intentionally not exported at this point, since it's part of
// unstable context menu option, eg: disable the unregister entity menu
interface EntityContextMenuOptions {
  disableUnregister: boolean | VisibleType;
}

/** @public */
export interface EntityLayoutProps {
  UNSTABLE_extraContextMenuItems?: ExtraContextMenuItem[];
  UNSTABLE_contextMenuOptions?: EntityContextMenuOptions;
  children?: React.ReactNode;
  NotFoundComponent?: React.ReactNode;
  headerRoutingBreadcrumbs?: React.ReactNode;
}

/**
 * EntityLayout is a compound component, which allows you to define a layout for
 * entities using a sub-navigation mechanism.
 *
 * Consists of two parts: EntityLayout and EntityLayout.Route
 *
 * @example
 * ```jsx
 * <EntityLayout>
 *   <EntityLayout.Route path="/example" title="Example tab">
 *     <div>This is rendered under /example/anything-here route</div>
 *   </EntityLayout.Route>
 * </EntityLayout>
 * ```
 *
 * @public
 */
export const EntityLayout = (props: EntityLayoutProps) => {
  const {
    UNSTABLE_extraContextMenuItems,
    UNSTABLE_contextMenuOptions,
    children,
    NotFoundComponent,
    headerRoutingBreadcrumbs,
  } = props;
  const {kind, namespace, name} = useRouteRefParams(entityRouteRef);
  const {entity, loading, error} = useAsyncEntity();
  const user = useUserProfile();
  const location = useLocation();
  const routes = useElementFilter(
    children,
    elements =>
      elements
        .selectByComponentData({
          key: dataKey,
          withStrictError:
            'Child of EntityLayout must be an EntityLayout.Route',
        })
        .getElements<EntityLayoutRouteProps>() // all nodes, element data, maintain structure or not?
        .flatMap(({props: elementProps}) => {
          if (!entity) {
            return [];
          } else if (elementProps.if && !elementProps.if(entity)) {
            return [];
          }

          return [
            {
              path: elementProps.path,
              title: elementProps.title,
              children: elementProps.children,
              tabProps: elementProps.tabProps,
            },
          ];
        }),
    [entity],
  );

  const entityName = entity?.metadata.title ?? name ?? entity?.metadata.name ?? '';
  const headerTitle = `${entityName}${
    namespace && namespace !== DEFAULT_NAMESPACE ? ` in ${namespace}` : ''
  }`

  const unregisterRedirectRouteRef = createExternalRouteRef({
    id: 'catalog:unregister-redirect',
    optional: true,
  });

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [inspectionDialogOpen, setInspectionDialogOpen] = useState(false);
  const navigate = useNavigate();
  const catalogIndexLink = useRouteRef(
    catalogPlugin.routes.catalogIndex,
  );
  const unregisterRedirectRoute = useRouteRef(unregisterRedirectRouteRef);

  const cleanUpAfterRemoval = async () => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
    navigate(
      unregisterRedirectRoute ? unregisterRedirectRoute() : catalogIndexLink(),
    );
  };

  // Make sure to close the dialog if the user clicks links in it that navigate
  // to another entity.
  useEffect(() => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Page themeId={'home'}>
      <Header
        title={<EntityLayoutTitle title={headerTitle} entity={entity!}/>}
        pageTitleOverride={headerTitle}
        subtitle={headerRoutingBreadcrumbs}
      >
        {entity && (
          <>
            {userIsAdmin(user) && !isMobile && (
              <EntityContextMenu
                UNSTABLE_extraContextMenuItems={UNSTABLE_extraContextMenuItems}
                UNSTABLE_contextMenuOptions={{
                  disableUnregister: true,
                  ...UNSTABLE_contextMenuOptions
                }}
                onUnregisterEntity={() => setConfirmationDialogOpen(true)}
                onInspectEntity={() => setInspectionDialogOpen(true)}
              />
            )}
          </>
        )}
      </Header>

      {loading && <Progress/>}

      {entity && <RoutedTabs routes={routes}/>}

      {error && (
        <Content>
          <Alert severity="error">{error.toString()}</Alert>
        </Content>
      )}

      {!loading && !error && !entity && (
        <Content>
          {NotFoundComponent ? (
            NotFoundComponent
          ) : (
            <WarningPanel title="Entity not found">
              There is no {kind} with the requested{' '}
              <Link to="https://backstage.io/docs/features/software-catalog/references">
                kind, namespace, and name
              </Link>
              .
            </WarningPanel>
          )}
        </Content>
      )}

      <UnregisterEntityDialog
        open={confirmationDialogOpen}
        entity={entity!}
        onConfirm={cleanUpAfterRemoval}
        onClose={() => setConfirmationDialogOpen(false)}
      />
      <InspectEntityDialog
        open={inspectionDialogOpen}
        entity={entity!}
        onClose={() => setInspectionDialogOpen(false)}
      />
    </Page>
  );
};

EntityLayout.Route = Route;
