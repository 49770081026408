import {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

/**
 * The state of the search modal, as well as functions for changing the modal's
 * visibility.
 *
 * @public
 */
export type SearchModalValue = {
  state: {
    hidden: boolean;
    open: boolean;
  };
  toggleModal: () => void;
  setOpen: (open: boolean) => void;
};

/**
 * Use this hook to manage the state of {@link SearchModal}
 * and change its visibility. Monitors route changes setting the hidden state
 * to avoid having to call toggleModal on every result click.
 *
 * @public
 *
 * @param initialState - pass `true` to make the modal initially visible
 * @returns an object containing the state of the modal together with
 * functions for changing the visibility of the modal.
 */
export function useSearchModal(initialState = false): SearchModalValue {
  const [state, setState] = useState({
    hidden: !initialState,
    open: initialState,
  });

  useEffect(() => {
    setState({
      hidden: !initialState,
      open: initialState,
    });
  }, [initialState]);

  const toggleModal = useCallback(
    () => {
      setState(prevState => ({
        open: true,
        hidden: !prevState.hidden,
      }))
    },
    [],
  );

  const setOpen = useCallback(
    (open: boolean) => {
      setState(prevState => ({
        open: prevState.open || open,
        hidden: !open,
      }))
    },
    [],
  );

  // Monitor route changes to automatically hide the modal.
  const location = useLocation();
  const locationKey = `${location.pathname}${location.search}${location.hash}`;
  useUpdateEffect(() => {
    setState(prevState => ({
      open: prevState.open,
      hidden: true,
    }));
  }, [locationKey]);

  return {state, toggleModal, setOpen}
}
