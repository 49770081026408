import React from "react";
import {EntitySwitch, isComponentType} from "@backstage/plugin-catalog";
import RepositoryEntityPage from "./repository/RepositoryPage";
import {useEntity} from "@backstage/plugin-catalog-react";
import {RepositoryEntityV1alpha1} from "../../../../../backend/src/custom-processors/types";

const ComponentPage = () => {
  const {entity: repositoryEntity} = useEntity<RepositoryEntityV1alpha1>();

  return (
    <EntitySwitch>
      <EntitySwitch.Case if={isComponentType('repository')}>
        {<RepositoryEntityPage repositoryEntity={repositoryEntity}/>}
      </EntitySwitch.Case>
    </EntitySwitch>
  )
}

export default ComponentPage;
