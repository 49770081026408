import type {Transformer} from "./transformer";
import {RepositoryEntityV1alpha1} from "../../../../../../backend/src/custom-processors/types";

export const convertShortcodes = (entity: RepositoryEntityV1alpha1): Transformer => {
  return dom => {
    const updateDomParagraphElements = <T extends Element>(
      list: Array<T>
    ): void => {
      Array.from(list)
        .forEach((elem: T) => {
          if (elem?.textContent?.replaceAll(" ", "")?.includes('{{<google-drive-video')) {
            handleGoogleDriveVideoShortcode(elem);
            return;
          }

          if (elem?.textContent?.replaceAll(" ", "")?.includes('{{<google-drive-docs')) {
            handleGoogleDriveDocShortcode(elem);
            return;
          }

          if (elem?.textContent?.replaceAll(" ", "")?.includes('{{<lucid-chart')) {
            handleLucidChartShortcode(elem);
            return;
          }

          if (elem?.textContent?.replaceAll(" ", "")?.includes('{{<page-list')) {
            handlePageListShortcode(entity, elem);
            return;
          }
        });
    };

    const updateDomAnchorElements = (list: Array<HTMLAnchorElement>): void => {
      Array.from(list)
        .forEach((elem: HTMLAnchorElement) => {
          let href = decodeURI(elem.href);

          if (href?.includes('{{< ref')) {
            handleRefShortcode(elem, href);
          }
        });
    };

    updateDomParagraphElements(Array.from(dom.getElementsByTagName('p')));

    updateDomAnchorElements(Array.from(dom.getElementsByTagName('a')));

    return dom;
  };
};

/**
 * Converts google-drive-video shortcode to an iframe element.
 * @param elem
 */
function handleGoogleDriveVideoShortcode(elem: Element) {
  let matches = elem?.textContent?.match(/{{< google-drive-video "(.*?)" >}}/g);
  if (!matches) {
    return;
  }

  matches.forEach((match) => {
    const url = match.match(/"(.*?)"/)?.[1];
    if (!url) {
      return;
    }

    const videoId = url.split('/').pop();
    const videoUrl = `https://drive.google.com/file/d/${videoId}/preview`;
    const videoContainer = document.createElement('div');
    videoContainer.classList.add('video-container');
    videoContainer.style.position = 'relative';
    videoContainer.style.paddingBottom = '56.25%';
    videoContainer.style.marginBottom = '50px';
    videoContainer.style.paddingTop = '25px';
    videoContainer.style.width = '100%';

    const iframe = document.createElement('iframe');
    iframe.classList.add('video');
    iframe.src = videoUrl;
    iframe.style.position = 'absolute';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    videoContainer.appendChild(iframe);
    elem.replaceWith(videoContainer);
  });
}

/**
 * Converts google-drive-docs shortcode to an iframe element.
 * @param elem
 */
function handleGoogleDriveDocShortcode(elem: Element) {
  let matches = elem?.textContent?.match(/{{< google-drive-docs "(.*?)" >}}/g);
  if (!matches) {
    return;
  }

  matches.forEach((match) => {
    const url = match.match(/"(.*?)"/)?.[1];
    if (!url) {
      return;
    }

    const docId = url.split('/').pop();
    const docUrl = `https://drive.google.com/file/d/${docId}/preview`;
    const docContainer = document.createElement('div');
    docContainer.id = 'google-drive-container';
    docContainer.style.position = 'relative';
    docContainer.style.paddingBottom = '56.25%';
    docContainer.style.marginBottom = '50px';
    docContainer.style.paddingTop = '25px';
    docContainer.style.width = '100%';

    const iframe = document.createElement('iframe');
    iframe.id = 'google-drive';
    iframe.src = docUrl;
    iframe.style.position = 'absolute';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    docContainer.appendChild(iframe);
    elem.replaceWith(docContainer);
  });
}

/**
 * Converts lucid-chart shortcode to an iframe element.
 * @param elem
 */
function handleLucidChartShortcode(elem: Element) {
  let matches = elem?.textContent?.match(/{{< lucid-chart "(.*?)" >}}/g);
  if (!matches) {
    return;
  }

  matches.forEach((match) => {
    const url = match.match(/"(.*?)"/)?.[1];
    if (!url) {
      return;
    }

    const chartContainer = document.createElement('div');
    chartContainer.id = 'lucid-chart-container';
    chartContainer.style.position = 'relative';
    chartContainer.style.paddingBottom = '56.25%';
    chartContainer.style.paddingTop = '25px';
    chartContainer.style.width = '100%';

    const iframe = document.createElement('iframe');
    iframe.id = 'lucid';
    iframe.src = url;
    iframe.style.position = 'absolute';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    chartContainer.appendChild(iframe);
    elem.replaceWith(chartContainer);
  });
}

/**
 * Converts page-list shortcode to a list of links.
 * @param entity
 * @param elem
 */
function handlePageListShortcode(entity: RepositoryEntityV1alpha1, elem: Element) {
  const pageListDiv = document.createElement('div');
  pageListDiv.id = 'page-list';

  let paths = new Map<string, string>();
  entity.spec?.docsMetadata?.sourceMetadata?.paths?.forEach((path) => {
    if (!path.destinationPath || !path.title) {
      return;
    }

    let currentDocsPath = window.location.pathname.replace(`/catalog/default/component/${entity.metadata.name}/docs`, '') // /catalog/default/component/actions/docs/
    if (currentDocsPath.startsWith('/')) {
      currentDocsPath = currentDocsPath.substring(1, currentDocsPath.length)
    }
    if (!path.destinationPath.startsWith(currentDocsPath)) {
      return;
    }

    let targetDestinationPath = path.destinationPath.substring(currentDocsPath.length, path.destinationPath.length)
    if (targetDestinationPath === '_index.md' || targetDestinationPath.startsWith('.github')) {
      return;
    }

    // only use paths one level deep - ex: wcodesign/_index.md
    // ignore paths nested further - ex: wcodesign/overview/_index.md
    let parts = targetDestinationPath.split("/");
    let title = parts[0].charAt(0).toUpperCase() + parts[0].slice(1); // capitalize first letter
    if (paths.has(title)) {
      return;
    }

    let pathname = window.location.pathname;
    if (pathname.endsWith('/')) {
      pathname = pathname.slice(0, -1);
    }

    let href = window.location.origin + pathname + '/' + parts[0] + '/';
    paths.set(title, href);
  })

  let sortedPaths = new Map([...paths.entries()].sort());
  sortedPaths.forEach((link, title) => {
    const linkElement = document.createElement('a');
    linkElement.href = link;
    linkElement.textContent = title;
    linkElement.style.fontWeight = 'bold';
    linkElement.style.marginLeft = '15px';

    const paragraph = document.createElement('p');
    paragraph.appendChild(linkElement);

    pageListDiv.appendChild(paragraph);
  });

  elem.replaceWith(pageListDiv);
}

/**
 * Converts ref shortcode to the proper link.
 * @param elem
 * @param href
 */
function handleRefShortcode(elem: HTMLAnchorElement, href: string) {
  // @ts-ignore
  let parsedUrl = URL.parse(href);

  let pathname = parsedUrl.pathname;
  if (pathname.startsWith('/')) {
    pathname = pathname.substring(1);
  }
  let pathParts = pathname.split('/');
  let baseURLParts = pathParts.slice(0, 4); // ['catalog', 'default', 'component', '<repo-name>']
  baseURLParts.push('docs'); // ['catalog', 'default', 'component', '<repo-name>', 'docs']

  let matches = href?.match(/{{< ref "(.*?)" >}}/g);
  if (!matches) {
    return;
  }

  matches.forEach((match) => {
    let url = match.match(/"(.*?)"/)?.[1];
    if (!url) {
      return;
    }

    url = url.toLowerCase()
    url = url.replace('.md', '')

    baseURLParts.push(url);
    let newHrefUri = baseURLParts.join('/');

    elem.href = parsedUrl.origin + '/' + newHrefUri;
  });
}
