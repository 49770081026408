import React from 'react';
import {Content, Header, Link, Page, TabbedLayout, TableColumn} from '@backstage/core-components';
import {catalogApiRef, entityRouteRef,} from '@backstage/plugin-catalog-react';
import {Entity} from '@backstage/catalog-model';
import {EntityDataTable} from "../../entity/EntityDataTable/EntityDataTable";
import {configApiRef, useApi, useRouteRef} from "@backstage/core-plugin-api";
import {CatalogTableRow} from "@backstage/plugin-catalog";
import {createMetadataDescriptionColumn, createOwnerColumn} from "../../entity/utils/tableColumns";
import {isMobile} from "mobile-device-detect";
import {RepositoryEntityV1alpha1} from "../../../../../backend/src/custom-processors/types";

export const TechDocsHomePage = () => {
  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';

  // we have to fetch all entities up front to build parent/child relationships
  const {entities, loading, error} = useEntitiesWithDocs();

  let entitiesMap = new Map<string, Entity[]>();
  let parentEntities = new Map<string, Entity[]>();
  let docTypes: string[] = [];

  if (entities) {
    entities.forEach((e) => {
      let entity = e as RepositoryEntityV1alpha1;
      if (!entity.spec?.docsMetadata?.sourceMetadata?.docType) {
        return;
      }

      let docType = entity.spec.docsMetadata.sourceMetadata.docType;
      let parentEntity = entity.spec.docsMetadata.sourceMetadata.parent;
      if (docType == "Child" && parentEntity) {
        parentEntities.set(parentEntity, [...(parentEntities.get(parentEntity) || []), e])
      }

      if (!entitiesMap.get(docType)?.find((a) => a.metadata.name === entity.metadata.name)) {
        entitiesMap.set(docType, [...(entitiesMap.get(docType) || []), e])
      }

      if (!docTypes.includes(docType) && docType !== "Child") {
        docTypes.push(docType)
      }
    })
  }

  return (
    <Page themeId="documentation">
      <Header title={`${orgName} Documentation`}/>
      <Content>
        <TabbedLayout>
          {docTypes.map((docType, index) => {
            return (
              <TabbedLayout.Route path={`/types/${docType.toLowerCase()}`} title={docType}
                                  key={["docType", index].join("-")}>
                <EntityDataTable
                  title={`${docType} Documentation`}
                  subtitle={`Showing all documentation for repositories that have the documentation type set to "${docType}" in the .weave.yaml`}
                  emptyMsg={"Something here?"}
                  columns={getTechDocsTableColumns()}
                  entities={entitiesMap.get(docType) || []}
                  parentChildData={parentEntities}
                  isLoading={loading}
                  error={error}
                  options={{
                    search: false,
                    columnsButton: false,
                  }}
                />
              </TabbedLayout.Route>
            )
          })}
        </TabbedLayout>
      </Content>
    </Page>
  )
}

type UseQueryEntitiesResponse = {
  entities: Entity[];
  loading: boolean;
  error?: Error;
};

/**
 * This is a custom hook that fetches entities with the techdocs-ref annotation
 */
export const useEntitiesWithDocs = (docType?: string): UseQueryEntitiesResponse => {
  let catalogApiClient = useApi(catalogApiRef);

  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<Error>();

  React.useEffect(() => {
    let filters:Record<string, string>[] = [
      {'metadata.annotations.backstage.io/techdocs-ref': 'dir:./_tdbuild'}
    ];
    if (docType) {
      filters = [{'spec.docsMetadata.sourceMetadata.docType': docType}]
    }
    catalogApiClient.getEntities({
      filter: filters
    }).then(r => {
      setEntities(r.items)
    })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [])

  return {
    entities,
    loading,
    error,
  }
}

const getTechDocsTableColumns = (): TableColumn<CatalogTableRow>[] => {
  let columns: TableColumn<CatalogTableRow>[] = [
    createTechDocsDetailPanelColumn(),
    createTechDocsNameColumn(),
    createOwnerColumn("20%"),
  ];

  if (!isMobile) {
    columns.push(createMetadataDescriptionColumn("40%", 'N/A (No "description" in WAML)'))
  }

  return columns;
}

const createTechDocsDetailPanelColumn = (): TableColumn<CatalogTableRow> => {
  return {
    title: '',
    width: '1%'
  }
}

const createTechDocsNameColumn = (): TableColumn<CatalogTableRow> => {
  return {
    title: 'Name',
    field: 'resolved.entityRef',
    highlight: true,
    render: ({entity}) => {
      const entityRoute = useRouteRef(entityRouteRef);
      const routeParams = {
        kind: encodeURIComponent(entity.kind),
        namespace: encodeURIComponent(entity.metadata.namespace || "default"),
        name: encodeURIComponent(entity.metadata.name),
      };

      return (
        <Link to={`${entityRoute(routeParams)}/docs/`}>
          {entity.metadata.name}
        </Link>
      )
    },
    width: "20%",
  };
}
