import {BartApiClient, bartApiRef} from './api';
import {createApiFactory, createPlugin, discoveryApiRef} from '@backstage/core-plugin-api';

export const bartApiPlugin = createPlugin({
  id: 'bart-api',
  apis: [
    createApiFactory({
      api: bartApiRef,
      deps: {discoveryApi: discoveryApiRef},
      factory: () => new BartApiClient(),
    }),
  ],
});
