import {useElementFilter} from '@backstage/core-plugin-api';
import {Badge, Grid, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import {Link} from "@backstage/core-components";

const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: '10px'
  },
  badge: {
    height: '12px',
    minWidth: '12px'
  }
}));

/**
 * Props for {@link ContentField}.
 *
 * @public
 */
export interface ContentFieldProps {
  label: string;
  value?: string;
  gridSizes?: Record<string, number>;
  children?: React.ReactNode;
  badgeLink?: string;
}

/** @public */
export function ContentField(props: ContentFieldProps) {
  const {label, value, gridSizes, children} = props;
  const classes = useStyles();

  const childElements = useElementFilter(children, c => c.getElements());

  // Content is either children or a string prop `value`
  const content =
    childElements.length > 0 ? (
      childElements
    ) : (
      <Typography variant="body2" className={classes.value}>
        {value || `unknown`}
      </Typography>
    );

  return (
    <Grid item {...gridSizes}>
      {props.badgeLink ? (
        // @ts-ignore
        <Badge classes={{ badge: classes.badge }} badgeContent={<Link to={props.badgeLink} color={"#000"}>i</Link>} color={"primary"}>
          <Typography variant="h2" className={classes.label}>
            {label}
          </Typography>
        </Badge>
      ) : (
        <Typography variant="h2" className={classes.label}>
          {label}
        </Typography>
      )}
      {content}
    </Grid>
  );
}
