import React from 'react';
import {UserProfileOverviewContent} from "./tabs/overview/UserProfileOverviewContent";
import {SettingsLayout} from "@backstage/plugin-user-settings";

export const userProfilePage = (
  <SettingsLayout title={"User Profile"}>
    <SettingsLayout.Route path="/" title="Overview">
      <UserProfileOverviewContent />
    </SettingsLayout.Route>
  </SettingsLayout>
);
