import {CodeSnippet, InfoCard, Table, TableColumn, TableProps, WarningPanel} from "@backstage/core-components";
import {CatalogTableRow} from "@backstage/plugin-catalog";
import React, {ReactNode, useCallback, useState} from "react";
import {Typography} from "@material-ui/core";
import {MTableBodyRow, MTableCell, Options} from "@material-table/core";
import {Entity, RELATION_OWNED_BY, RELATION_PART_OF, stringifyEntityRef} from "@backstage/catalog-model";
import {isMobile} from 'mobile-device-detect';
import {TableToolbar} from "./Toolbar";
import {Skeleton} from "@mui/material";
import {getEntityRelations, humanizeEntityRef} from "@backstage/plugin-catalog-react";

interface EntityDataTableProps {
  title?: string;
  hideTitle?: boolean;
  subtitle?: ReactNode;
  isLoading?: boolean | undefined;
  error?: Error | undefined;
  entities?: Entity[] | undefined;
  columns: TableColumn<CatalogTableRow>[];
  actions?: TableProps<CatalogTableRow>['actions'];
  options?: Options<CatalogTableRow>;
  errorMsg?: string;
  emptyMsg?: string;
  parentChildData?: Map<string, Entity[]> | undefined;
}

export const EntityDataTable = (props: EntityDataTableProps) => {
  const {
    errorMsg = "Could not load entities",
    emptyMsg = "No records found.",
  } = props;

  const [_search, setSearch] = useState('');

  const Toolbar = useCallback(
    (toolbarProps: any /* no type for this in material-table */) => {
      return (
        <TableToolbar
          setSearch={setSearch}
          {...toolbarProps}
        />
      );
    },
    [setSearch],
  );

  // use custom Cell component to remove empty cells for rows that have children
  const Cell = useCallback(
    (cellProps: any /* no type for this in material-table */) => {
      let children = props.parentChildData?.get(cellProps.rowData.entity.metadata.name);
      if (!cellProps.value && children && cellProps.columnDef.title === '') {
        return null;
      }

      return (
        <MTableCell {...cellProps} />
      );
    },
    [],
  );

  // use custom Row component to add detail panel for children if there are any
  const Row = useCallback(
    (rowProps: any /* no type for this in material-table */) => {
      let children = props.parentChildData?.get(rowProps.data.entity.metadata.name);
      if (children) {
        return (
          <MTableBodyRow {...rowProps} detailPanel={() => {
            return (
              <Table<CatalogTableRow>
                style={{
                  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                  width: '100%',
                  margin: '15px'
                }}
                title={`Children of ${rowProps.data.entity.metadata.name}`}
                subtitle={`These are repositories that have specified that they are of documentation type 'Child' and have '${rowProps.data.entity.metadata.name}' marked as their parent.`}
                columns={props.columns}
                // @ts-ignore
                data={children?.map(toEntityRow)}
                options={{
                  search: false,
                  paging: false,
                  padding: 'dense' as const
                }}
              />
            )
          }}/>
        );
      }

      // if there are no children just render the default row
      return (
        <MTableBodyRow {...rowProps}/>
      )
    },
    [props.parentChildData],
  );

  if (props.error || !props.entities) {
    return (
      <InfoCard variant={'gridItem'} title={props.title}>
        <WarningPanel
          severity="error"
          title={errorMsg}
          message={<CodeSnippet text={`${props.error}`} language="text"/>}
        />
      </InfoCard>
    );
  }

  const rows = props.entities.sort(refCompare).map(toEntityRow);
  const pageSize = isMobile ? 10 : 25;
  const showPagination = rows.length > pageSize;
  let title = props.title;
  if (props.entities.length > pageSize) {
    title += ` (${props.entities.length})`;
  }
  if (props.hideTitle) {
    title = undefined;
  }

  return (
    <Table<CatalogTableRow>
      components={{
        Toolbar,
        Row,
        Cell
      }}
      isLoading={props.isLoading}
      columns={props.columns}
      options={{
        search: true,
        paging: showPagination,
        pageSize: pageSize,
        pageSizeOptions: [25, 50, 100],
        columnResizable: true,
        columnsButton: props.columns.length > 1 && !isMobile,
        actionsColumnIndex: -1,
        loadingType: 'linear' as const,
        showEmptyDataSourceMessage: !props.isLoading,
        padding: 'dense' as const,
        ...props.options
      }}
      title={props.isLoading ? <Skeleton variant="rectangular" width={210} height={50}/> : title}
      // @ts-ignore because a ReactNode actually is valid here
      subtitle={props.isLoading ? <Skeleton variant="rectangular" width={300} height={30}/> : props.subtitle}
      data={rows}
      actions={props.actions}
      emptyContent={<div style={{textAlign: 'center'}}>
        <Typography variant="body1">
          {emptyMsg}
        </Typography>
      </div>}
    />
  )
}

export const refCompare = (a: Entity, b: Entity) => {
  const toRef = (entity: Entity) =>
    entity.metadata.title ||
    humanizeEntityRef(entity, {
      defaultKind: 'Component',
    });

  return toRef(a).localeCompare(toRef(b));
};

export const toEntityRow = (entity: Entity): CatalogTableRow => {
  const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'system',
  });
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  return {
    entity,
    resolved: {
      // This name is here for backwards compatibility mostly; the
      // presentation of refs in the table should in general be handled with
      // EntityRefLink / EntityName components
      name: humanizeEntityRef(entity, {
        defaultKind: 'Component',
      }),
      entityRef: stringifyEntityRef(entity),
      ownedByRelationsTitle: ownedByRelations
        .map(r => humanizeEntityRef(r, {defaultKind: 'group'}))
        .join(', '),
      ownedByRelations,
      partOfSystemRelationTitle: partOfSystemRelations
        .map(r =>
          humanizeEntityRef(r, {
            defaultKind: 'system',
          }),
        )
        .join(', '),
      partOfSystemRelations,
    },
  };
}
