import React from "react";
import {Grid} from "@material-ui/core";
import {AboutCard} from "../../../../core/AboutCard/AboutCard";
import {EntityWarningContent} from "../../../../entity/EntityWarningContent/EntityWarningContent";
import {EntityDataTable} from "../../../../entity/EntityDataTable/EntityDataTable";
import {ComponentEntity, RELATION_API_CONSUMED_BY, RELATION_API_PROVIDED_BY} from "@backstage/catalog-model";
import {useEntity, useRelatedEntities} from "@backstage/plugin-catalog-react";
import {CardTab} from "@backstage/core-components";
import {getDeploymentTableColumns} from "../../../deployment/DeploymentPage";
import {TabbedCard} from "../../../../core/TabbedCard/TabbedCard";
import {DeploymentEntityV1alpha1, SchemaEntityV1alpha1} from "../../../../../../../backend/src/custom-processors/types";

const OverviewContent = () => {
  const {entity} = useEntity<SchemaEntityV1alpha1>();

  const {
    entities: entitiesProvidingApi,
    loading: loadingProviders,
    error: providersError
  } = useRelatedEntities(entity, {
    type: RELATION_API_PROVIDED_BY,
  });

  const {
    entities: entitiesConsumingApi,
    loading: loadingConsumers,
    error: consumersError
  } = useRelatedEntities(entity, {
    type: RELATION_API_CONSUMED_BY,
  });

  let consumingEntitiesMap: { [clusterId: string]: DeploymentEntityV1alpha1[] } = {};
  // @ts-ignore
  entitiesConsumingApi?.forEach((entity: DeploymentEntityV1alpha1) => {
    if (!consumingEntitiesMap[entity.spec.clusterId]) {
      consumingEntitiesMap[entity.spec.clusterId] = [];
    }
    consumingEntitiesMap[entity.spec.clusterId].push(entity);
  })

  let consumingEntities = Object.entries(consumingEntitiesMap);

  return (
    <Grid container spacing={3}>
      {<EntityWarningContent/>}
      <Grid item xs={12}>
        <AboutCard/>
      </Grid>
      <Grid item xs={12}>
        <EntityDataTable
          title="Providers"
          subtitle={<>These providers are deployments that have declared this particular schema in their WAML
            (.weave.yaml) deploy section.</>}
          isLoading={loadingProviders}
          error={providersError}
          emptyMsg="No deployments provide this schema."
          columns={getDeploymentTableColumns()}
          entities={entitiesProvidingApi as ComponentEntity[]}
        />
      </Grid>
      <Grid item xs={12}>
        <TabbedCard
          title="Consumers"
          subtitle={<>These consumers are deployments that have made a request to a service that provides this
            API.</>}
        >
          {consumingEntities && consumingEntities.map(([clusterId, consumers], index) => {
            return (
              <CardTab label={`${clusterId} (${consumers.length})`} key={clusterId + index}>
                <EntityDataTable
                  hideTitle={true}
                  isLoading={loadingConsumers}
                  error={consumersError}
                  emptyMsg="No component consumes this schema."
                  columns={getDeploymentTableColumns()}
                  entities={consumers as DeploymentEntityV1alpha1[]}
                />
              </CardTab>
            )
          })}
        </TabbedCard>
      </Grid>
    </Grid>
  )
}

export default OverviewContent;
