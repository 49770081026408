import React from "react";
import {RELATION_OWNER_OF} from "@backstage/catalog-model";
import {createRepositoryTableLinksColumn} from "../../../../component/repository/RepositoryPage";
import {Content, TableColumn} from "@backstage/core-components";
import {makeStyles} from "@material-ui/core/styles";
import {EntityDataTable} from "../../../../../entity/EntityDataTable/EntityDataTable";
import {useEntity, useRelatedEntities} from "@backstage/plugin-catalog-react";
import {CatalogTableRow} from "@backstage/plugin-catalog";
import {createMetadataDescriptionColumn, createNameColumn} from "../../../../../entity/utils/tableColumns";
import {isMobile} from "mobile-device-detect";
import {RepositoryEntityV1alpha1} from "../../../../../../../../backend/src/custom-processors/types";
import { QualityScore } from "../../../../component/repository/tabs/overview/QualityScore";

const useStyles = makeStyles({
  root: {
    paddingTop: '0'
  }
})

const QualityScoreContent = () => {
  const classes = useStyles();

  const {entity} = useEntity();
  const {entities, loading, error} = useRelatedEntities(entity, {
    type: RELATION_OWNER_OF,
    kind: "Component",
  });

  return (
    <Content className={classes.root}>
      <EntityDataTable
        isLoading={loading}
        error={error}
        columns={getTableColumns()}
        title={"Team Quality Score Report - Repositories"}
        entities={entities}
        options={{
          sorting: false,
          columnResizable: false,
          columnsButton: false
        }}
      />
    </Content>
  )
}

export const getTableColumns = (): TableColumn<CatalogTableRow>[] => {
  let columns: TableColumn<CatalogTableRow>[] = [
    createNameColumn({width: "20%"}),
  ];

  if (!isMobile) {
    columns.push(createMetadataDescriptionColumn("50%", 'N/A (No "description" in WAML)'))
    columns.push(createRepositoryTableLinksColumn("15%"));
  }

  columns.push(createQualityScoreColumn("15%"));

  return columns;
}

export const createQualityScoreColumn = (width?: string): TableColumn<CatalogTableRow> => {
  return {
    title: 'Quality Score',
    render: ({entity}) => {
      const repositoryEntity = entity as RepositoryEntityV1alpha1;
      return (
        <QualityScore
          entity={repositoryEntity}
          showOnlyChart
        />
      );
    },
    width: width,
  };
}

export default QualityScoreContent;
