import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { getColorByPercentage } from './helpers';

export const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, value }) => {
    const color = getColorByPercentage(value);
    return {
      height: 35,
      borderRadius: 5,
      margin: 5,
      minWidth: 100,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: `hsl(from ${color} h s l / 0.2)`,
      '> span': {
        backgroundColor: color,
      },
    };
  },
);
