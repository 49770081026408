import React from "react";
import {Grid} from "@material-ui/core";
import {AboutCard} from "./AboutCard/AboutCard";
import {EntityCatalogGraphCard} from "@backstage/plugin-catalog-graph";
import {
  EntityOpsgenieAlertsCard,
  EntityOpsgenieOnCallListCard,
  isOpsgenieAvailable,
  isOpsgenieOnCallListAvailable
} from '@k-phoen/backstage-plugin-opsgenie';
import {EntitySwitch} from '@backstage/plugin-catalog';
import {EntityWarningContent} from "../../../../../entity/EntityWarningContent/EntityWarningContent";
import {CustomRenderNode} from "../../../../../catalog-graph/CustomRenderNode";
import {useEntity} from "@backstage/plugin-catalog-react";
import {decode} from "../../../../../../utils/encoding";
import {parse} from "yaml"
import {RepositoryEntityV1alpha1} from "../../../../../../../../backend/src/custom-processors/types";

const OverviewContent = () => {
  const {entity} = useEntity<RepositoryEntityV1alpha1>();
  const wamlBytes: string = entity?.spec?.waml?.toString() || "";
  const waml = parse(decode(wamlBytes));

  return (
    <Grid container spacing={3}>
      <EntityWarningContent/>
      <Grid item xs={12}>
        <AboutCard externalLinks={entity.spec?.externalLinks}/>
      </Grid>
      <Grid item xs={12}>
        <EntityCatalogGraphCard variant="gridItem" zoom={"enable-on-click"} height={400} maxDepth={1}
                                renderNode={CustomRenderNode}/>
      </Grid>
      {(waml?.deploy || waml?.infraDeploy) && (
        <>
          <Grid item sm={12} md={6}>
            <EntitySwitch>
              <EntitySwitch.Case if={isOpsgenieAvailable}>
                <EntityOpsgenieAlertsCard title="OpsGenie: Alerts"/>
              </EntitySwitch.Case>
            </EntitySwitch>
          </Grid>
          <Grid item sm={12} md={6}>
            <EntitySwitch>
              <EntitySwitch.Case if={isOpsgenieOnCallListAvailable}>
                <EntityOpsgenieOnCallListCard title="OpsGenie: Who Is On-Call"/>
              </EntitySwitch.Case>
            </EntitySwitch>
          </Grid>
        </>
      )}
    </Grid>
  )
};

export default OverviewContent;
