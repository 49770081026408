/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import type {Transformer} from './transformer';
import {CatalogApi} from "@backstage/plugin-catalog-react";
import {RepositoryEntityV1alpha1} from "../../../../../../backend/src/custom-processors/types";
import {getRepositoryEntity} from "../../../entity/utils/getRepositoryEntity";
import {Entity} from "@backstage/catalog-model";

export const rewriteDocLinks = (catalogApiClient: CatalogApi, entity: Entity): Transformer => {
  return dom => {
    const updateDom = <T extends Element>(
      list: Array<T>
    ): void => {
      Array.from(list)
        .filter(elem => elem.hasAttribute('href'))
        .forEach((elem: T) => {
          let href = elem.getAttribute('href');
          if (href === "" || !href) {
            return;
          }

          getSwitchboardUrl(elem, catalogApiClient, entity, href).then(url => {
            // open external links in a new tab
            if (url.startsWith('http') && !url.startsWith(window.location.origin)) {
              elem.setAttribute('target', '_blank');
            }

            updateElementUrl(elem, url);
          })
        });
    };

    const updateElementUrl = (elem: Element, url: string): void => {
      try {
        const normalizedWindowLocation = normalizeUrl(
          window.location.href,
        );
        elem.setAttribute(
          'href',
          new URL(url, normalizedWindowLocation).toString(),
        );
      } catch (_e) {
        // Non-parseable links should be re-written as plain text.
        elem.replaceWith(elem.textContent || url);
      }
    }

    // Ex: <a href="https://docs.weavelab.ninja/services/devx-reference-services/devx-reference-run">Run</a>
    updateDom(Array.from(dom.getElementsByTagName('a')));

    return dom;
  };
};

/**
 * Make sure the URL ends with a trailing slash
 * This is to ensure routing within TechDocs works properly
 * @param input
 */
export function normalizeUrl(input: string): string {
  const url = new URL(input);

  if (!url.pathname.endsWith('/') && !url.pathname.endsWith('.html')) {
    url.pathname += '/';
  }

  return url.toString();
}

/**
 * Get the equivalent switchboard URL for Weave Docs and GitHub URLs if possible
 *
 * @param elem
 * @param catalogApiClient
 * @param currentEntity
 * @param url
 */
async function getSwitchboardUrl(elem: Element, catalogApiClient: CatalogApi, currentEntity: Entity, url: string): Promise<string> {
  // if the URL is not a docs URL, return the URL as is
  if (shouldIgnoreUrl(url)) {
    return url;
  }

  // when an anchor tag has a class, it means that it was generated by TechDocs and should be left alone
  // this applies to things like nav links and headers
  if (elem.getAttribute('class')) {
    return url;
  }

  if (url.includes('docs.weavelab.ninja')) {
    return await translateWeaveDocsUrl(catalogApiClient, url);
  }

  // this applies to all github.com/weave-lab URLs
  // this means that the URL is a full URL containing 'github.com/weave-lab' or that it is a relative URL
  return await translateGithubUrl(catalogApiClient, currentEntity, url);
}

/**
 * Check if the URL should be ignored
 * This returns true if the URL should be left alone and not be rewritten
 *
 * @param url
 */
function shouldIgnoreUrl(url: string): boolean {
  // if the URL is not a GitHub or Weave Docs URL, return true
  if (url.startsWith("http") && !url.includes("github.com/weave-lab/") && !url.includes("docs.weavelab.ninja")) {
    return true
  }

  // if the URL includes the current origin or switchboard, return true
  // the reason to also include 'switchboard.weavelab.ninja' is to account for running locally
  return url.includes(window.location.origin) || url.includes("switchboard.weavelab.ninja");
}

/**
 * Translate the Weave docs URL to the switchboard URL
 *
 * @param catalogApiClient
 * @param docsUrl
 */
async function translateWeaveDocsUrl(catalogApiClient: CatalogApi, docsUrl: string): Promise<string> {
  let uri = docsUrl.replace('https://docs.weavelab.ninja/', '');
  if (uri.endsWith('/')) {
    uri = uri.slice(0, -1);
  }
  let parts = uri.split('/');

  // first check to see if the 3rd parameter is a "Child" doc type of the 2nd parameter
  // ex: https://docs.weavelab.ninja/services/devx-reference-services/devx-reference-run
  if (parts.length >= 3) {
    let entity = await getRepositoryEntity(catalogApiClient, parts[2]);
    if (entity?.spec?.docsMetadata?.sourceMetadata?.parent === parts[1]) {
      return `${window.location.origin}/catalog/default/component/${parts[2]}/docs/${parts.slice(3).join('/')}`
    }
  }

  let entity = await getRepositoryEntity(catalogApiClient, parts[1]);
  if (!entity?.spec?.docsMetadata) {
    return docsUrl;
  }

  return `${window.location.origin}/catalog/default/component/${parts[1]}/docs/${parts.slice(2).join('/')}`
}

/**
 * Translate the Github URL to the switchboard URL
 *
 * @param catalogApiClient
 * @param currentEntity
 * @param githubUrl
 */
async function translateGithubUrl(catalogApiClient: CatalogApi, currentEntity: Entity, githubUrl: string): Promise<string> {
  if (hasNonMarkdownCodeFileExtension(githubUrl)) {
    return githubUrl;
  }

  // get the repo name from the URL
  // this works for absolute URLs, but for relative URLs this repo name will be overridden
  // ex: github.com/weave-lab/bart/blob/main/README.md => repoName = 'bart' (CORRECT)
  // ex: docs/rancher-setup/README.md => repoName = 'docs' (INCORRECT)
  let repoName = getRepoNameFromUrl(githubUrl);

  // check for a relative GitHub URL and use the current entity's name for the repo name and construct the absolute URL
  if (!githubUrl.includes('github.com/weave-lab/')) {
    // with a relative url, we don't know the repo name, so we use the current entity's name
    repoName = currentEntity.metadata.name;
    githubUrl = convertRelativeGithubUrlToAbsoluteUrl(currentEntity, githubUrl);
  }

  let hash = getHashFromUrl(githubUrl);
  let uriAfterSlug = getUriWithoutHashFromUrlAfterSlug(githubUrl, hash);
  if (uriAfterSlug.endsWith('/')) {
    uriAfterSlug = uriAfterSlug.slice(0, -1);
  }

  if (uriIsNativeGithubRoute(uriAfterSlug)) {
    return githubUrl;
  }

  let entity = await getRepositoryEntity(catalogApiClient, repoName);
  if (!entity?.spec?.docsMetadata) {
    return githubUrl;
  }

  // if url points to the main README.md file, remove the 'blob/main/' from the uri as it gets routed to the main page
  if (uriAfterSlug === 'blob/main/README.md') {
    uriAfterSlug = '';
  }

  // If url points to a specific file or directory, check if there is a rewrite configured in the entity\
  // It's possible that in the .weave.yaml, there is configuration to move markdown files to a different directory
  // In this case, we need to look up in the docsMetadata to see if there is a mapping for this specific file
  // Ex: https://github.com/weave-lab/radioactive-man/tree/main/internal/checks/required
  // Ex: https://github.com/weave-lab/radioactive-man/blob/main/internal/checks/required/README.md
  if (uriAfterSlug.startsWith('blob/') || uriAfterSlug.startsWith('tree/')) {
    let rewrite = getUrlRewriteFromEntity(entity, uriAfterSlug);
    if (!rewrite) {
      return githubUrl
    }

    uriAfterSlug = rewrite;
  }

  let docsUrl = `${window.location.origin}/catalog/default/component/${repoName}/docs/${uriAfterSlug}`;
  if (docsUrl.endsWith('/')) {
    docsUrl = docsUrl.slice(0, -1);
  }
  if (hash) {
    docsUrl += `#${hash}`;
  }

  return docsUrl;
}

/**
 * Convert a relative GitHub URL to an absolute URL
 *
 * @param currentEntity
 * @param githubUrl
 */
function convertRelativeGithubUrlToAbsoluteUrl(currentEntity: Entity, githubUrl: string): string {
  if (githubUrl.startsWith("./")) {
    githubUrl = githubUrl.slice(2);
  }

  let uri = githubUrl;
  let parts = uri.split('/'); // ex: [docs, rancher-setup, README.md#setup]
  let lastPart = parts[parts.length - 1]; // ex: README.md#setup

  // remove last element from the parts array since we've already captured it in 'lastPart'
  parts.pop(); // ex: [docs, rancher-setup]

  let hash = '';

  // get hash and strip it from the last part of the uri
  if (lastPart.includes('#')) { // ex: README.md#setup
    hash = lastPart.split('#')[1]; // ex: setup
    lastPart = lastPart.replace(`#${hash}`, ''); // ex: README.md
  }

  let uriBeforeLastPart = "";
  if (parts.length > 0) {
    uriBeforeLastPart = parts.join("/") + "/" // ex: docs/rancher-setup/
  }

  if (lastPart.endsWith(".md")) { // markdown file (all other file types are returned early as non-docs)
    uri = "blob/main/" + uriBeforeLastPart + lastPart; // ex: blob/main/docs/rancher-setup/README.md
  } else { // directory
    uri = "tree/main/" + uriBeforeLastPart + lastPart; // ex: tree/main/docs/rancher-setup (if 'README.md' is not present in the URL)
  }

  // apply the hash back to the uri
  if (hash !== "") {
    uri += `#${hash}`; // blob/main/docs/rancher-setup/README.md#setup
  }

  return `https://github.com/weave-lab/${currentEntity.metadata.name}/${uri}`
}

/**
 * Check if the URI after the slug is a native Github route such as 'releases' or 'settings'
 * @param uriAfterSlug
 */
function uriIsNativeGithubRoute(uriAfterSlug: string): boolean {
  const uriParts = uriAfterSlug.split('/'); // ex: releases/latest
  const nativeRoutes: string[] = ['issues', 'pulls', 'actions', 'projects', 'wiki', 'security', 'insights', 'releases', 'settings', 'compare', 'commits', 'branches', 'tags', 'contributors', 'stargazers', 'watchers', 'network', 'graphs'];
  return nativeRoutes.includes(uriParts[0]); // ex: uriParts[0] == 'releases'
}

/**
 * Get the URL rewrite from the entity
 *
 * If the URL points to a specific file or directory, check if there is a rewrite configured in the entity
 * spec docsMetadata
 *
 * @param entity
 * @param uriAfterSlug
 */
function getUrlRewriteFromEntity(entity: RepositoryEntityV1alpha1, uriAfterSlug: string): string {
  let parts = uriAfterSlug.split('/');
  let uri = parts.slice(2).join('/');

  let pathInfo = entity.spec?.docsMetadata?.sourceMetadata?.paths?.find((path) => path.sourcePath === uri);
  if (!pathInfo) {
    pathInfo = entity.spec?.docsMetadata?.sourceMetadata?.paths?.find((path) => path.sourcePath === uri + '/README.md');
    if (!pathInfo) {
      return ""
    }
  }

  if (!pathInfo.destinationPath) {
    return "";
  }

  let rewrite = pathInfo.destinationPath;

  // strip the leading slash since this will be appended
  if (rewrite?.startsWith('/')) {
    rewrite = rewrite.slice(1);
  }

  // remove the trailing '/_index.md' from the rewrite as it gets routed to a specific page
  if (rewrite?.endsWith('/_index.md')) {
    rewrite = rewrite?.slice(0, -10)
  }

  // if url points to a markdown file, remove the '.md' from the uri as it gets routed to a specific page
  // also, make it lowercase so it matches the routing setup in the docs-generator: https://github.com/weave-lab/docs-generator/commit/ff61f16b5cbf95ab838c48d51b14905128ab5999
  if (rewrite?.endsWith('.md')) {
    rewrite = rewrite?.slice(0, -3).toLowerCase()
  }

  let rewriteParts = rewrite.split('/');
  let encodedRewriteParts: string[] = [];
  rewriteParts.forEach((part) => {
    encodedRewriteParts.push(encodeURIComponent(part));
  })

  // return the encoded rewrite to handle special characters and spaces
  return encodedRewriteParts.join('/');
}

/**
 * Get the repo name from the URL
 *
 * Ex: https://github.com/weave-lab/schema/blob/main/README.md#authentication
 * Ex: https://github.com/weave-lab/schema/README.md#authentication
 * - both would return 'schema'
 * */
function getRepoNameFromUrl(url: string): string {
  let uri = url.replace('https://github.com/weave-lab/', '');
  let parts = uri.split('/');
  let repoName = parts[0];
  if (repoName.includes("#")) {
    let repoParts = repoName.split("#");
    repoName = repoParts[0];
  }
  return repoName;
}

/**
 * Get the hash from the URL
 *
 * Ex: https://github.com/weave-lab/schema/blob/main/README.md#authentication
 * Ex: https://github.com/weave-lab/schema/README.md#authentication
 * - both would return 'authentication'
 * */
function getHashFromUrl(url: string): string {
  let parts = url.split('/');
  let lastArg = parts[parts.length - 1];
  let hash = '';
  if (lastArg.includes("#")) {
    let lastArgParts = lastArg.split("#");
    hash = lastArgParts[1];
  }

  return hash.replace('--', '-');
}

/**
 * Get the URI after the slug and remove the hash
 *
 * Ex: https://github.com/weave-lab/schema/blob/main/README.md#authentication
 * - would return 'blob/main/README.md'
 * */
function getUriWithoutHashFromUrlAfterSlug(url: string, hash: string): string {
  let uri = url.replace('https://github.com/weave-lab/', '');
  let parts = uri.split('/');
  return parts.slice(1).join('/').replace(`#${hash}`, '');
}

/**
 * Check if the URL has a file extension that is not markdown
 *
 * Ex: https://github.com/weave-lab/schema/blob/main/server.go
 * - would return 'false'
 * */
function hasNonMarkdownCodeFileExtension(url: string): boolean {
  let parts = url.split('/');
  let lastArg = parts[parts.length - 1];
  if (lastArg.includes('#')) {
    lastArg = lastArg.split('#')[0];
  }

  return lastArg.includes('.') && !lastArg.endsWith('.md');
}
