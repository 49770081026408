import React, {useEffect} from "react";
import {getCompoundEntityRef} from "@backstage/catalog-model";
import {makeStyles} from "@material-ui/core/styles";
import {TechDocsReaderPage} from "@backstage/plugin-techdocs";
import {TechDocsReaderPageContent} from "../../../../../core/TechDocs/TechDocsReaderPageContent";
import {useEntity} from "@backstage/plugin-catalog-react";
import {Grid} from "@material-ui/core";
import {Link, WarningPanel} from "@backstage/core-components";
import mermaid from "mermaid";

const useStyles = makeStyles({
  root: {
    marginBottom: '10px'
  }
})

const DocsContent = () => {
  const classes = useStyles();

  const {entity} = useEntity();
  let entityRef = getCompoundEntityRef(entity);

  const renderMermaid = () => {
    let shadowRootDiv = document.querySelector("[data-testid='techdocs-native-shadowroot']");
    shadowRootDiv?.shadowRoot?.querySelectorAll('.language-text').forEach((shadowRootElement: Element) => {
      if (!shadowRootElement.textContent) {
        return;
      }
      shadowRootElement.id = `mermaid-${Math.random().toString(36).substring(7)}`;

      // if the element has already been rendered as an SVG, then we don't need to render it again
      if (shadowRootElement.innerHTML.startsWith("<svg")) {
        return;
      }

      // find the code element within the language-text parent div
      let codeElement = shadowRootElement.querySelector('code');
      if (!codeElement) {
        return;
      }

      if (!codeElement.textContent) {
        return;
      }

      codeElement.id = `mermaid-${Math.random().toString(36).substring(7)}`;

      // only if the parse is successful, then we render the diagram
      mermaid.parse(codeElement.textContent).then(() => {
        if (!codeElement?.textContent) {
          return;
        }

        // render the diagram to get the actual svg to set as the innerHTML of the shadowRootElement
        mermaid.render(codeElement.id, codeElement.textContent).then(r => {
          const mermaidContainer = document.createElement('div');
          mermaidContainer.innerHTML = r.svg
          shadowRootElement.replaceWith(mermaidContainer);
        }).catch(e => {
          console.error("Error rendering mermaid diagram:", e)
        })
      }).catch(e => {
        console.error("Error parsing mermaid diagram:", e)
      })
    })
  }

  useEffect(() => {
    // We need to wait for the shadowRoot to be created before we can render the mermaid diagrams
    // For whatever reason, the 'onReady' prop for TechDocsReaderPageContent doesn't work as expected and an initial delay
    // solves the problem
    //
    // This tries to initialize mermaid and render any diagrams every 500ms for 5 seconds
    //
    // This is a temporary solution until the discussion happening in their Discord channel is resolved
    // TLDR; we need to figure out why the mkdocs-mermaid2 plugin isn't rendering the diagrams correctly
    // Ref: https://linear.app/getweave/issue/DEVX-6235/figure-out-why-mkdocs-mermaid2-plugin-doesnt-render-svgs-like-its
    let mermaidInterval = setInterval(() => {
      if (!mermaid) {
        return;
      }

      mermaid.initialize({
        securityLevel: 'loose',
        logLevel: "debug",
        startOnLoad: true
      })

      renderMermaid();
    }, 500);

    setTimeout(() => {
      clearInterval(mermaidInterval);
    }, 5000);
  }, []);

  return (
    <>
      <Grid item xs={12} className={classes.root}>
        <WarningPanel
          severity="info"
          title={"TechDocs Formatting Guide"}
          defaultExpanded={true}
        >
          TechDocs renders things slightly differently than GitHub renders markdown. Most things will display as they
          should, but there are some exceptions since TechDocs renders traditional markdown and GitHub is a little more permissive.
          For more information, please refer to the <Link
          to={`${window.location.origin}/catalog/default/component/switchboard/docs/#formatting-guide`}>Formatting Guide</Link>.
        </WarningPanel>
      </Grid>
      <TechDocsReaderPage entityRef={entityRef}>
        <TechDocsReaderPageContent withSearch={false}/>
      </TechDocsReaderPage>
    </>
  )
}

export default DocsContent;
