import React from "react";
import {
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  hasCatalogProcessingErrors,
  isOrphan
} from "@backstage/plugin-catalog";
import {Grid} from "@material-ui/core";
import {useUserProfile} from "@backstage/plugin-user-settings";
import {AdminUserEmails} from "../../../utils/adminUserEmails";
import {useEntity} from "@backstage/plugin-catalog-react";
import {WarningPanel} from "@backstage/core-components";

export const EntityWarningContent = () => {
  const user = useUserProfile();
  const entity = useEntity();
  if (!AdminUserEmails.includes(user?.profile?.email || "") || window.location.hostname !== "localhost") {
    return null;
  }

  return (
    <>
      <EntitySwitch>
        <EntitySwitch.Case if={isOrphan}>
          <Grid item xs={12}>
            <EntityOrphanWarning/>
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={hasCatalogProcessingErrors}>
          <Grid item xs={12}>
            <EntityProcessingErrorsPanel/>
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case
          if={(entity) => !!entity.metadata.annotations?.["switchboard.weavelab.xyz/processing-errors"]}>
          <Grid item xs={12}>
            <WarningPanel
              severity="error"
              title={"Processing Errors"}
              defaultExpanded={false}
            >
              {entity.entity.metadata.annotations?.["switchboard.weavelab.xyz/processing-errors"]}
            </WarningPanel>
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </>
  );
}

