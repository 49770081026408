import React from "react";
import {Grid} from "@material-ui/core";
import {GRPCSelfGeneratedAPIReference} from 'grpc-docs';
import {useEntity} from "@backstage/plugin-catalog-react";
import {SchemaEntityV1alpha1} from "../../../../../../../backend/src/custom-processors/types";

const DefinitionContent = () => {
  const {entity: schemaAPI} = useEntity<SchemaEntityV1alpha1>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <GRPCSelfGeneratedAPIReference definition={schemaAPI.spec.definition || ''}/>
      </Grid>
    </Grid>
  )
}

export default DefinitionContent;
