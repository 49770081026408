/*
Reference: https://github.com/backstage/backstage/blob/c1742c625484a73f24d86d761cba3544e29bffcd/packages/core-components/src/layout/TabbedCard/TabbedCard.tsx
  - Brought in so we could support subtitles.
 */

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Tab, {TabProps} from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import {BottomLink, BottomLinkProps, ErrorBoundary, ErrorBoundaryProps} from "@backstage/core-components";

const useTabsStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(0, 2, 0, 2.5),
      minHeight: theme.spacing(3),
    },
    indicator: {
      backgroundColor: theme.palette.info.main,
      height: theme.spacing(0.3),
    },
  }),
  {name: 'BackstageTabbedCard'},
);

const BoldHeader = withStyles(
  theme => ({
    root: {padding: theme.spacing(2, 2, 2, 2.5), display: 'inline-block'},
    title: {fontWeight: 700},
    subheader: {paddingTop: theme.spacing(1)},
  }),
  {name: 'BackstageTabbedCardBoldHeader'},
)(CardHeader);

type Props = {
  /** @deprecated Use errorBoundaryProps instead */
  errorBoundaryProps?: ErrorBoundaryProps;
  children?: ReactElement<TabProps>[];
  onChange?: (event: React.ChangeEvent<{}>, value: number | string) => void;
  title?: string;
  subtitle?: ReactNode;
  value?: number | string;
  deepLink?: BottomLinkProps;
};

export function TabbedCard(props: PropsWithChildren<Props>) {
  const {
    errorBoundaryProps,
    children,
    title,
    subtitle,
    deepLink,
    value,
    onChange,
  } = props;
  const tabsClasses = useTabsStyles();
  const [selectedIndex, selectIndex] = useState(0);

  const handleChange = onChange
    ? onChange
    : (_ev: unknown, newSelectedIndex: number) => selectIndex(newSelectedIndex);

  let selectedTabContent: ReactNode;
  if (!value) {
    React.Children.map(children, (child, index) => {
      if (React.isValidElement(child) && index === selectedIndex) {
        selectedTabContent = child?.props.children;
      }
    });
  } else {
    React.Children.map(children, child => {
      if (
        React.isValidElement<{ children?: ReactNode; value?: unknown }>(
          child,
        ) &&
        child?.props.value === value
      ) {
        selectedTabContent = child?.props.children;
      }
    });
  }

  const errProps: ErrorBoundaryProps =
    errorBoundaryProps || {};

  return (
    <Card>
      <ErrorBoundary {...errProps}>
        {title && <BoldHeader title={title} subheader={subtitle}/>}
        <Tabs
          classes={tabsClasses}
          value={value || selectedIndex}
          onChange={handleChange}
        >
          {children}
        </Tabs>
        <Divider/>
        <CardContent>{selectedTabContent}</CardContent>
        {deepLink && <BottomLink {...deepLink} />}
      </ErrorBoundary>
    </Card>
  );
}

const useCardTabStyles = makeStyles(
  // @ts-ignore
  theme => ({
    root: {
      minWidth: theme.spacing(6),
      minHeight: theme.spacing(3),
      margin: theme.spacing(0, 2, 0, 0),
      padding: theme.spacing(0.5, 0, 0.5, 0),
      textTransform: 'none',
      '&:hover': {
        opacity: 1,
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
      },
    },
    selected: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
  {name: 'BackstageCardTab'},
);

type CardTabProps = TabProps & {
  children: ReactNode;
};

/**
 * Card tab component used in {@link TabbedCard}
 *
 * @public
 *
 */
export function CardTab(props: PropsWithChildren<CardTabProps>) {
  const {children, ...restProps} = props;
  const classes = useCardTabStyles();

  return <Tab disableRipple classes={classes} {...restProps} />;
}
