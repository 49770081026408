import React, {PropsWithChildren} from 'react';
import HomeIcon from '@material-ui/icons/Home';
import {
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import {errorApiRef, identityApiRef, useApi} from "@backstage/core-plugin-api";
import SidebarLogo from "./logo/SidebarLogo";
import {SidebarSearchModal} from "../search/SidebarSearchModal";
import SignOutIcon from '@material-ui/icons/MeetingRoom';
import CategoryIcon from '@material-ui/icons/Category';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIcon from "@material-ui/icons/Assignment";

const useKeyPress = (targetKey: string) => {
  const [keyPressed, setKeyPressed] = React.useState(false);

  // @ts-ignore
  const downHandler = ({key}) => {
    if (key === targetKey) setKeyPressed(!keyPressed);
  };

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [keyPressed]);

  return keyPressed;
};

export const Root = ({children}: PropsWithChildren<{}>) => {
  const errorApi = useApi(errorApiRef);
  const identityApi = useApi(identityApiRef);
  const searchKeystrokeActive = useKeyPress('/');

  return (
    <SidebarPage>
      <Sidebar sidebarOptions={{
        drawerWidthOpen: 240, // this is to allow for "Product Features" to be displayed in full
      }}>
        <SidebarLogo/>
        <SidebarGroup label="Search" icon={<SearchIcon/>} to="/search">
          <SidebarSearchModal open={searchKeystrokeActive}/>
          <SidebarDivider/>
        </SidebarGroup>
        <SidebarGroup label="Menu" icon={<MenuIcon/>}>
          <SidebarItem
            icon={HomeIcon}
            to="/"
            text="Home"
          />
          <SidebarDivider/>
          <SidebarItem
            text="Catalog"
            to="/catalog"
            icon={CategoryIcon}
          />
          <SidebarItem
            text="Documentation"
            to="/docs"
            icon={AssignmentIcon}
          />
        </SidebarGroup>
        <SidebarSpace/>
        <SidebarDivider/>
        <SidebarGroup label="User" icon={<AccountCircleIcon/>}>
          <SidebarItem
            text="User Profile"
            icon={AccountCircleIcon}
            to="/profile"
          />
          <SidebarItem
            text="Sign Out"
            icon={SignOutIcon}
            onClick={() => {
              identityApi.signOut().catch(error => errorApi.post(error))
            }}
          />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  )
};
