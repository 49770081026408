import React from "react";
import {useEntity} from "@backstage/plugin-catalog-react";
import {Grid} from "@material-ui/core";
import {Content, Link} from "@backstage/core-components";

const DocsContent = () => {
  const {entity} = useEntity();
  const teamNameShort = entity.metadata.name.replace("acl-dev-team-", "")

  return (
    <Content>
      <Grid container spacing={3}>
        <Grid item xs={12}>
            The team docs content for the {entity.metadata.name} team live <Link to={`/catalog/default/component/teams/docs/${teamNameShort}`}>here</Link>.
        </Grid>
        <Grid item xs={12}>
          In a later release, we will be able to display the docs content here as well.
        </Grid>
      </Grid>
    </Content>
  )
}

export default DocsContent;
