import React from "react";
import {Box} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";

export const CardTitle = (props: { title: string }) => (
  <Box display="flex" alignItems="center">
    <GroupIcon fontSize="inherit"/>
    <Box ml={1}>{props.title}</Box>
  </Box>
);
