import React from "react";
import {useEntity} from "@backstage/plugin-catalog-react";
import {EntitySwitch} from "@backstage/plugin-catalog";
import TeamPage from "./team/TeamPage";
import {isEntityType} from "../../entity/utils/entityUtils";

const GroupPage = () => {
  const {entity} = useEntity();

  return (
    <EntitySwitch>
      <EntitySwitch.Case if={isEntityType(entity, "team")} children={<TeamPage/>}/>
    </EntitySwitch>
  )
};

export default GroupPage;
