import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Report } from '@weave/schema-gen-ts/dist/schemas/quality-metrics/quality_metrics.pb';
import Typography from '@mui/material/Typography';
import { ScorecardAccordion } from './ScorecardAccordion';
import Stack from '@mui/material/Stack';
import { ScorePointInfo } from './ScorePointInfo';

interface Props {
  open: boolean;
  onClose: () => void;
  reportData?: Report;
}

export const ScorecardDialog = ({ open, reportData = {}, onClose }: Props) => {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
        <Stack sx={{ alignSelf: 'flex-end', marginBottom: 2 }}>
          <ScorePointInfo
            optPoints={reportData.points}
            maxPoints={reportData.pointsMax}
            grade={"A"}
            percent={100}
          />
        </Stack>
        <ScorecardAccordion categories={reportData.categories} />
      </DialogContent>
      <DialogActions>
        <Stack alignItems="flex-end" margin={1}>
          <Typography lineHeight="1.5" variant="overline" fontWeight="bold">
            {reportData.name}
          </Typography>
          <Typography display="inline" variant="body2" color="GrayText">
            {reportData.description || 'No report description provided.'}
          </Typography>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
