import React from 'react';
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {
  feedbackIsEmpty,
  getBackgroundColorForSeverity,
  ResultFeedback,
  ValidationResult
} from "./types";

const useStyles = makeStyles(_theme => ({
  validationResultCard: {
    fontSize: "15px",
    marginBottom: "1%"
  },
  validationResultDeployIdCardHeader: {
    fontSize: "15px",
    color: "#7f7f7f",
    paddingBottom: "10px",
    borderBottom: "1px solid #cecece"
  },
  validationResultCardHeader: {
    fontSize: "12px",
    color: "#7f7f7f"
  }
}))

type Props = {
  validationResult: ValidationResult;
}

const ValidationResultCard = (props: Props) => {
  const classes = useStyles();

  const {validationResult} = props;

  return (
    <Card classes={{root: classes.validationResultCard}} style={{border: "none", boxShadow: "none"}}>
      <CardHeader title={validationResult.title}
                  classes={{title: classes.validationResultDeployIdCardHeader}}/>
      <CardContent>
        <ResultFeedback
          feedback={validationResult.errorFeedback}
          severity="error"
        />
        <ResultFeedback
          feedback={validationResult.warningFeedback}
          severity="warning"
        />
        <ResultFeedback
          feedback={validationResult.infoFeedback}
          severity="info"
        />
      </CardContent>
    </Card>
  )
}

type ResultFeedbackProps = {
  feedback: ResultFeedback[];
  severity: "error" | "warning" | "info";
}

const ResultFeedback = (props: ResultFeedbackProps) => {
  const classes = useStyles();

  return (
    <>
      {props.feedback?.map((feedback: ResultFeedback, i: number) => {
        if (feedbackIsEmpty(feedback)) {
          return null;
        }

        return (
          <Card key={["validationResults", i, "infoFeedback"].join("-")}
                classes={{root: classes.validationResultCard}}
                style={{backgroundColor: getBackgroundColorForSeverity(props.severity)}}>
            <CardContent>
              {feedback.markdownMessage || feedback.message}
            </CardContent>
          </Card>
        )
      })}
    </>
  )
}

export default ValidationResultCard;
