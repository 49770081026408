import {CatalogTableRow} from "@backstage/plugin-catalog";
import {OverflowTooltip, TableColumn} from "@backstage/core-components";
import {Entity} from "@backstage/catalog-model";
import {EntityRefLink, EntityRefLinks, humanizeEntityRef} from "@backstage/plugin-catalog-react";
import React from "react";

export const createNameColumn = (options?: {
  defaultKind?: string;
  width?: string;
}): TableColumn<CatalogTableRow> => {
  function formatContent(entity: Entity): string {
    return (
      entity.metadata?.title ||
      humanizeEntityRef(entity, {
        defaultKind: options?.defaultKind,
      })
    );
  }

  return {
    title: 'Name',
    field: 'resolved.entityRef',
    highlight: true,
    customSort({entity: entity1}, {entity: entity2}) {
      // TODO: We could implement this more efficiently by comparing field by field.
      // This has similar issues as above.
      return formatContent(entity1).localeCompare(formatContent(entity2));
    },
    render: ({entity}) => (
      <EntityRefLink
        entityRef={entity}
        defaultKind={options?.defaultKind || 'Component'}
      />
    ),
    width: options?.width,
  };
}

export const createOwnerColumn = (width?: string): TableColumn<CatalogTableRow> => {
  return {
    title: 'Owner(s)',
    field: 'resolved.ownedByRelationsTitle',
    render: ({resolved}) => {
      return (
        <EntityRefLinks
          entityRefs={resolved.ownedByRelations}
          defaultKind="group"
        />
      )
    },
    width: width,
  };
}

export const createMetadataDescriptionColumn = (width?: string, emptyMsg?: string): TableColumn<CatalogTableRow> => {
  if (!emptyMsg) {
    emptyMsg = "N/A"
  }

  return {
    title: 'Description',
    field: 'entity.metadata.description',
    render: ({ entity }) => (
      <OverflowTooltip
        text={entity.metadata.description ?? emptyMsg}
        placement="bottom-start"
      />
    ),
    width: width,
  };
}

export const createSystemColumn = (width?: string): TableColumn<CatalogTableRow> => {
  return {
    title: 'System',
    field: 'resolved.partOfSystemRelationTitle',
    render: ({ resolved }) => (
      <EntityRefLinks
        entityRefs={resolved.partOfSystemRelations}
        defaultKind="system"
      />
    ),
    width: width,
  };
}
