import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { ReportMetric } from '@weave/schema-gen-ts/dist/schemas/quality-metrics/quality_metrics.pb';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .v5-MuiTooltip-tooltip`]: {
    backgroundColor: 'aliceblue',
    color: 'black',
    maxWidth: 220,
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
  },
}));

const Dl = styled('dl')(({ theme }) => ({
  margin: 0,
  padding: 0,
  '& dt': {
    fontWeight: 'bold',
  },
  '& dd': {
    marginLeft: 0,
    marginBottom: theme.spacing(1),
  },
  '& div': {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export interface Props {
  metrics?: ReportMetric[];
}

export const ScorecardMetrics = ({ metrics }: Props) => {
  if (!metrics?.length) return null;

  return (
    <Grid item xs={12} md={6} marginTop={1} marginBottom={2}>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Metric</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">
              Points
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metrics.map(metric => (
            <TableRow key={metric.id}>
              <TableCell component="th" scope="row">
                <Tooltip
                  followCursor
                  TransitionComponent={Zoom}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 15],
                          },
                        },
                      ],
                    },
                  }}
                  title={metric.description}
                >
                  <Typography variant="body2" display="inline">
                    {metric.name}
                  </Typography>
                </Tooltip>
              </TableCell>
              {!metric.thresholdType ? (
                <TableCell align="right">
                  <Checkbox
                    checked={metric.points === metric.pointsMax}
                    readOnly
                    disableRipple
                    sx={{ ':hover': { cursor: 'unset' } }}
                  />
                </TableCell>
              ) : (
                <TableCell align="right">
                  <HtmlTooltip
                    followCursor
                    TransitionComponent={Zoom}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, 15],
                            },
                          },
                        ],
                      },
                    }}
                    title={
                      <Dl>
                        <div>
                          <dt>Threshold Type:</dt>
                          <dd>{metric.thresholdType}</dd>
                        </div>
                        <div>
                          <dt>Thresholds:</dt>
                          <dd>
                            <Stack>
                              {Object.entries(metric.threshold ?? {}).map(
                                ([key, value], i) => (
                                  <Box key={i}>
                                    <span>{key}</span>
                                    &#x27A1;
                                    <span>{value}</span>
                                  </Box>
                                ),
                              )}
                            </Stack>
                          </dd>
                        </div>

                        <div>
                          <dt>Points:</dt>
                          <dd>{metric.points || 0}</dd>
                        </div>
                      </Dl>
                    }
                  >
                    <Typography variant="body2" display="inline">
                      {metric.points || 0}/{metric.pointsMax}
                    </Typography>
                  </HtmlTooltip>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};
