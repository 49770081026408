import React, {ReactNode} from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from '@backstage/core-components';
import {IndexableDocument, ResultHighlight,} from '@backstage/plugin-search-common';
import {HighlightedSearchResultText} from '@backstage/plugin-search-react';
import {ListItem} from "@material-ui/core";
import {SearchDocument} from "./SearchDocument";
import {EntityRefLink} from "@backstage/plugin-catalog-react";
import {parseEntityRef} from "@backstage/catalog-model";

const useStyles = makeStyles(
  {
    item: {
      display: 'flex',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    flexContainer: {
      flexWrap: 'wrap',
    },
    itemText: {
      width: '100%',
      wordBreak: 'break-all',
      marginBottom: '1rem',
    },
  },
  {name: 'CatalogSearchResultListItem'},
);

/**
 * Props for {@link CatalogSearchResultListItem}.
 *
 * @public
 */
export interface CatalogSearchResultListItemProps {
  icon?: ReactNode | ((result: IndexableDocument) => ReactNode);
  result?: SearchDocument;
  highlight?: ResultHighlight;
  rank?: number;
  lineClamp?: number;
  source: string; // either 'software-catalog' or 'techdocs'
}

/** @public */
export function CatalogSearchResultListItem(
  props: CatalogSearchResultListItemProps,
) {
  const result = props.result;
  const highlight = props.highlight as ResultHighlight;

  const classes = useStyles();

  if (!result) return null;

  let kind = result.kind;
  let type = result.type;
  if (kind === 'Component' && result.type === 'repository') {
    kind = "Repository"
    type = '';
  }

  let documentationSource: string = '';
  let docsEntityRef;
  let entityOwnerRef;
  if (props.source === 'techdocs') {
    kind = 'Documentation';
    type = '';
    if (result.kind === 'component' && result.type === 'repository') {
      documentationSource = result.name;
      docsEntityRef = parseEntityRef({
        name: result.name,
        kind: result.kind,
        namespace: result.namespace,
      })
    }
  }
  // the owner for our teams does not show up as the FQN, so we need to parse it
  if (result.owner.includes('acl-dev')) {
    entityOwnerRef = parseEntityRef({
      name: result.owner,
      kind: 'Group',
    })
  }

  if (type === 'other') {
    type = '';
  }

  return (
    <ListItem className={classes.item}>
      {props.icon && (
        <ListItemIcon>
          {typeof props.icon === 'function' ? props.icon(result) : props.icon}
        </ListItemIcon>
      )}
      <div className={classes.flexContainer}>
        <ListItemText
          className={classes.itemText}
          primaryTypographyProps={{variant: 'h6'}}
          primary={
            <Link noTrack to={result.location}>
              {highlight?.fields.title ? (
                <HighlightedSearchResultText
                  text={highlight.fields.title}
                  preTag={highlight.preTag}
                  postTag={highlight.postTag}
                />
              ) : (
                result.title
              )}
            </Link>
          }
          secondary={
            <Typography
              component="span"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: props.lineClamp,
                overflow: 'hidden',
              }}
              color="textSecondary"
              variant="body2"
            >
              {highlight?.fields.text ? (
                <HighlightedSearchResultText
                  text={highlight.fields.text}
                  preTag={highlight.preTag}
                  postTag={highlight.postTag}
                />
              ) : (
                result.text
              )}
            </Typography>
          }
        />
        <Box>
          {kind && (
            <Chip label={`Kind: ${kind}`} size="small"/>
          )}
          {type && (
            <Chip label={`Type: ${type}`} size="small"/>
          )}
          {documentationSource && !docsEntityRef && (
            <Chip label={`Source: ${documentationSource}`} size="small"/>
          )}
          {docsEntityRef && (
            <Chip label={<>Source: <EntityRefLink entityRef={docsEntityRef}/></>} size="small"/>
          )}
          {entityOwnerRef && (
            <Chip label={<>Owner: <EntityRefLink entityRef={entityOwnerRef}/></>} size="small"/>
          )}
        </Box>
      </div>
    </ListItem>
  );
}
