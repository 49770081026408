import React from "react";
import WamlContent, {ModifyWamlOptions} from "./tabs/waml/WAMLContent";
import OverviewContent from "./tabs/overview/OverviewContent";
import OwnershipContent from "./tabs/ownership/OwnershipContent";
import QualityScoreContent from "./tabs/quality-score/QualityScoreContent";
import YAML from 'yaml';
import {decode} from "../../../../utils/encoding";
import {EntityLayout} from "../../../entity/EntityLayout/EntityLayout";
import {EntityHeaderBreadcrumbs} from "../../../entity/EntityHeaderBreadcrumbs/EntityHeaderBreadcrumbs";
import {Link, TableColumn} from "@backstage/core-components";
import {CatalogTableRow} from "@backstage/plugin-catalog";
import {isMobile} from 'mobile-device-detect';
import {createMetadataDescriptionColumn, createNameColumn, createOwnerColumn} from "../../../entity/utils/tableColumns";
import {isTechDocsAvailable,} from "@backstage/plugin-techdocs";
import DocsContent from "./tabs/docs/DocsContent";
import { qualityScoreUserEmails } from "./tabs/overview/Scorecard/ScorecardContent";
import { useUserProfile } from "@backstage/plugin-user-settings";
import {RepositoryEntityV1alpha1} from "../../../../../../backend/src/custom-processors/types";
import {useLocalStorage} from "react-use";
import {ChangesNotificationBadge} from "../../../core/ChangesNotificationBadge/ChangesNotificationBadge";

export type RepositoryPageProps = {
  repositoryEntity: RepositoryEntityV1alpha1;
}

const reposWithChangedWamlLocalStorageKey = "wappsWithChanges";

type ReposWithChangedWaml = {
  repos?: RepoWithChangedWaml[]
}

type RepoWithChangedWaml = {
  slug: string;
  tabsWithChanges: string[];
  waml?: string;
  lastModified: number;
}

/*
  Note: This component defines the global state of the local WAML and the changes that have been made to it.
  - 'waml' should be used to refer to the string representation of the .weave.yaml
  - 'wapp' should be used after YAML.parse has been called on the 'waml' and is then a YAML object
 */
const RepositoryPage = (props: RepositoryPageProps) => {
  const user = useUserProfile();
  const currentUserEmail = user?.profile?.email || '';
  const [hasStagedChanges, setHasStagedChanges] = React.useState(false);

  // reposWithChangedWaml is a list of repositories that have local changes to their waml
  const [reposWithChangedWaml, setReposWithChangedWaml] = useLocalStorage<ReposWithChangedWaml>(reposWithChangedWamlLocalStorageKey, undefined);

  // localWaml is meant for temporary changes to the waml that are not yet saved or a part of the source of truth .weave.yaml from the entity
  // it's state lives here so that changes can be shown on the WAML tab even if the user navigates away to a different tab
  const [localWaml, setLocalWaml] = React.useState<string>("");

  const originalWaml = decode(props.repositoryEntity.spec?.waml || "");

  // remove the localWaml from local storage after one day of no changes
  React.useEffect(() => {
    let repo = getRepoFromLocalStorage();
    if (!repo) {
      return;
    }

    let oneDay = 24 * 60 * 60 * 1000;
    let now = new Date().getTime();
    if (repo.lastModified < now - oneDay) {
      resetWaml();
    }
  }, []);

  // anytime the localWaml in local storage changes, update the localWaml state
  React.useEffect(() => {
    let repo = getRepoFromLocalStorage();
    if (repo?.waml) {
      setLocalWaml(repo.waml);
    } else {
      setLocalWaml(originalWaml);
    }
  }, [reposWithChangedWaml]);

  // anytime the localWaml changes, determine if there are local changes
  React.useEffect(() => {
    setHasStagedChanges(localWaml !== originalWaml);
  }, [localWaml])

  const modifyWaml = (newWaml: string, options?: ModifyWamlOptions) => {
    // initialize the newReposWithChangedWaml object with the existing reposWithChangedWaml object or the default empty object
    let newReposWithChangedWaml = {
      repos: reposWithChangedWaml?.repos || []
    }

    let r = getRepoFromLocalStorage();
    if (r === undefined) {
      r = {
        slug: props.repositoryEntity.metadata.name,
        tabsWithChanges: [],
        lastModified: new Date().getTime()
      }
    }

    // this can happen when the save button is pressed when there are no changes
    if (newWaml === r.waml) {
      return;
    }

    switch (typeof newWaml) {
      case "string":
        r.waml = newWaml
        break;
      case "object":
        r.waml = YAML.stringify(newWaml);
        break;
    }

    if (options?.tab) {
      r.tabsWithChanges.push(options?.tab);
    }

    // get all OTHER repos that are not the current repo and add the current one to the list so it can be updated
    let newRepos = newReposWithChangedWaml.repos.filter(repo => repo.slug !== props.repositoryEntity.metadata.name);
    newRepos.push(r);
    newReposWithChangedWaml.repos = newRepos;

    setReposWithChangedWaml(newReposWithChangedWaml);
  }

  const resetWaml = () => {
    setReposWithChangedWaml({
      repos: reposWithChangedWaml?.repos?.filter(repo => repo.slug !== props.repositoryEntity.metadata.name)
    });
  }

  const getRepoFromLocalStorage = () => {
    return reposWithChangedWaml?.repos?.find(repo => repo.slug === props.repositoryEntity.metadata.name) || undefined
  }

  return (
    <EntityLayout headerRoutingBreadcrumbs={<EntityHeaderBreadcrumbs/>}>
      <EntityLayout.Route path="/" title="Overview">
        {<OverviewContent/>}
      </EntityLayout.Route>
      <EntityLayout.Route path="/docs" title="Docs" if={isTechDocsAvailable}>
        <DocsContent/>
      </EntityLayout.Route>
      {props.repositoryEntity?.spec?.waml && !isMobile && (
        <EntityLayout.Route path="/waml" title="WAML" tabProps={hasStagedChanges ? {
          label: (<ChangesNotificationBadge title={"WAML"}/>)
        } : {}}>
          <WamlContent
            repositoryEntity={props.repositoryEntity}
            originalWaml={originalWaml}
            hasStagedChanges={hasStagedChanges}
            localWaml={localWaml}
            modifyWaml={modifyWaml}
            resetWaml={resetWaml}
            tabsWithChanges={getRepoFromLocalStorage()?.tabsWithChanges || []}
          />
        </EntityLayout.Route>
      )}
      {props.repositoryEntity?.spec?.waml
        // check for deploys in the WAML
        && Object.keys(YAML.parse(originalWaml).deploy || {}).length != 0
        && (
          <EntityLayout.Route path="/ownership" title="Ownership">
            {<OwnershipContent/>}
          </EntityLayout.Route>
        )}
      {!!props.repositoryEntity?.spec?.qualityMetricsReport && qualityScoreUserEmails.includes(currentUserEmail) && (
        <EntityLayout.Route path="/quality-score" title="Quality Score">
          <QualityScoreContent />
        </EntityLayout.Route>
      )}
    </EntityLayout>
  )
};

export const createRepositoryTableLinksColumn = (width?: string): TableColumn<CatalogTableRow> => {
  return {
    title: 'Links',
    render: ({entity}) => {
      return (
        <ul>
          <li>
            <Link
              to={`https://github.com/weave-lab/${entity.metadata.name}`}>Source</Link>
          </li>
        </ul>
      )
    },
    width: width,
  };
}

export const getRepositoryTableColumns = (): TableColumn<CatalogTableRow>[] => {
  let columns: TableColumn<CatalogTableRow>[] = [
    createNameColumn({width: "20%"}),
    createOwnerColumn("20%"),
  ];

  if (!isMobile) {
    columns.push(createMetadataDescriptionColumn("50%", 'N/A (No "description" in WAML)'))
    columns.push(createRepositoryTableLinksColumn("15%"));
  }

  return columns;
}

export const RepositoryTableSubtitle = () => {
  return (
    <>The list of repositories comes from <Link to="https://github.com/weave-lab">GitHub</Link> for every
      repository that contains a valid WAML (.weave.yaml) file.</>
  )
}

export default RepositoryPage;
