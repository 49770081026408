import React from "react";
import Editor, {EditorProps} from "@monaco-editor/react";

export const CodeEditor = (props: EditorProps) => {
  return (
    <Editor
      defaultLanguage={props.defaultLanguage ? props.defaultLanguage : "yaml"}
      height={props.height ? props.height : "75vh"}
      options={{
        readOnly: true,
        theme: 'light',
        ...props.options,
      }}
      onChange={props.onChange}
      value={props.value}
    />
  );
}
