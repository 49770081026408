import React from "react";
import PercentInput from "./PercentInput";
import SecondsInput from "./SecondsInput";

export interface SLOProtocolProps {
  protocol: string;
  protocolSpec: any;
  onChange: (e: any) => void;
}

const SLOProtocol = (props: SLOProtocolProps) => {
  return (
    <>
      <PercentInput
        name={`${props.protocol}.availability.percent`}
        value={props.protocolSpec?.availability?.percent}
        onChange={props.onChange}
      />
      <span> of {props.protocol} requests will complete without error</span>
      <br/>
      <PercentInput
        name={`${props.protocol}.latency.tier1.percent`}
        value={props.protocolSpec?.latency?.tier1?.percent}
        onChange={props.onChange}
      />
      <span> of {props.protocol} requests will complete in&nbsp;</span>
      <SecondsInput
        name={`${props.protocol}.latency.tier1.seconds`}
        value={props.protocolSpec?.latency?.tier1?.seconds}
        onChange={props.onChange}
      />
      {props.protocolSpec?.latency?.tier1?.seconds === "1" ? ("second or less") : ("seconds or less")}
      <br/>
      <PercentInput
        name={`${props.protocol}.latency.tier2.percent`}
        value={props.protocolSpec?.latency?.tier2?.percent}
        onChange={props.onChange}
      />
      <span> of {props.protocol} requests will complete in&nbsp;</span>
      <SecondsInput
        name={`${props.protocol}.latency.tier2.seconds`}
        value={props.protocolSpec?.latency?.tier2?.seconds}
        onChange={props.onChange}
      />
      {props.protocolSpec?.latency?.tier2?.seconds === "1" ? ("second or less") : ("seconds or less")}
    </>
  )
}

export default SLOProtocol;
