import React from "react";
import TextField from "@material-ui/core/TextField";

export interface PercentInputProps {
  name: string;
  value?: string;
  onChange: (e: any) => void;
}

const PercentInput = (props: PercentInputProps) => {
  const [value, setValue] = React.useState(props.value);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (e: any) => {
    setValue(e.target.value)
    props.onChange(e)
  }

  return (
    <>
      <TextField
        autoFocus
        margin="none"
        name={props.name}
        value={value ?? ""}
        onChange={onChange}
        size="small"
        style={{width: '3em'}}
      />
      %
    </>
  )
}

export default PercentInput;
