// Ref: https://github.com/backstage/backstage/blob/8c50da66854bb335eb95ce499464974b93d0d915/plugins/catalog-graph/src/components/EntityRelationsGraph/EntityKindIcon.tsx
import { HelpIcon } from '@backstage/core-components';
import { useApp } from '@backstage/core-plugin-api';
import React from 'react';

export function CustomEntityKindIcon({
  kind,
  ...props
}: {
  kind: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  className?: string;
}) {
  const app = useApp();
  const Icon = 
    app.getSystemIcon(kind.toLocaleLowerCase('en-US'))
    || app.getSystemIcon(`kind:${kind.toLocaleLowerCase('en-US')}`)
    || HelpIcon;
  return (<Icon fontSize='inherit' {...props} />);
}
