// imports, etc omitted for brevity. for full source see:
import React, {ReactNode, useEffect} from 'react';
import {Content, Header, Page, TabbedLayout, TableColumn, TableProps,} from '@backstage/core-components';
import {configApiRef, useApi} from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  DefaultEntityFilters,
  EntityKindFilter,
  EntityTypeFilter,
  useEntityList,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import {CatalogTableRow, isOrphan} from '@backstage/plugin-catalog';
import {useUserProfile} from "@backstage/plugin-user-settings";
import {userIsAdmin} from "../../utils/adminUserEmails";
import {HeaderWorldClock} from "../core/HeaderWorldClock/HeaderWorldClock";
import {ANNOTATION_VIEW_URL} from "@backstage/catalog-model";
import {withStyles} from "@material-ui/core/styles";
import Star from "@material-ui/icons/Star";
import Typography from "@material-ui/core/Typography";
import {visuallyHidden} from "@mui/utils";
import OpenInNew from "@material-ui/icons/OpenInNew";
import StarBorder from "@material-ui/icons/StarBorder";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import {createNameColumn} from "../entity/utils/tableColumns";
import {isMobile} from "mobile-device-detect";
import {getRepositoryTableColumns, RepositoryTableSubtitle} from "./component/repository/RepositoryPage";
import {DeploymentTableSubtitle, getDeploymentTableColumns} from "./deployment/DeploymentPage";
import {getSchemaTableColumns, SchemaTableSubtitle} from "./schema/SchemaPage";
import {EntityDataTable} from "../entity/EntityDataTable/EntityDataTable";
import {TeamTableSubtitle} from "./group/team/TeamPage";
import {ClusterTableSubtitle} from "./cluster/ClusterPage";
import {ProductFeatureTableSubtitle} from "./system/product-feature/ProductFeaturePage";
import {getInfraDeploymentTableColumns, InfraDeploymentTableSubtitle} from "./infra-deployment/InfraDeploymentPage";

// https://github.com/backstage/backstage/blob/master/plugins/catalog/src/components/CatalogPage/DefaultCatalogPage.tsx

export const YellowStar = withStyles({
  root: {
    color: '#f3ba37',
  },
})(Star);

export interface CatalogTabContentProps {
  columns: TableColumn<CatalogTableRow>[];
  filters: DefaultEntityFilters;
  title: string;
  subtitle: ReactNode;
}

export function CustomCatalogPage() {
  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';

  return (
    <Page themeId="home">
      <Header title={`${orgName} Catalog`}>
        <HeaderWorldClock/>
      </Header>
      <Content>
        <TabbedLayout>
          <TabbedLayout.Route path="/repositories" title="Repositories">
            <CatalogTabContent
              columns={getRepositoryTableColumns()}
              filters={{
                kind: new EntityKindFilter("component"),
                type: new EntityTypeFilter("repository"),
              }}
              title="Repositories"
              subtitle={<RepositoryTableSubtitle/>}
            />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/deployments" title="Deployments">
            <CatalogTabContent
              columns={getDeploymentTableColumns()}
              filters={{
                kind: new EntityKindFilter("deployment"),
                type: undefined
              }}
              title="Deployments"
              subtitle={<DeploymentTableSubtitle/>}
            />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/infra-deployments" title="Infra Deployments">
            <CatalogTabContent
              columns={getInfraDeploymentTableColumns()}
              filters={{
                kind: new EntityKindFilter("InfraDeployment"),
                type: undefined
              }}
              title="Infra Deployments"
              subtitle={<InfraDeploymentTableSubtitle/>}
            />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/schemas" title="Schemas">
            <CatalogTabContent
              columns={getSchemaTableColumns()}
              filters={{
                kind: new EntityKindFilter("schema"),
                type: undefined
              }}
              title="Schemas"
              subtitle={<SchemaTableSubtitle/>}
            />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/teams" title="Teams">
            <CatalogTabContent
              columns={[
                createNameColumn(),
              ]}
              filters={{
                kind: new EntityKindFilter("group"),
                type: new EntityTypeFilter("team"),
              }}
              title="Teams"
              subtitle={<TeamTableSubtitle/>}
            />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/clusters" title="Clusters">
            <CatalogTabContent
              columns={[
                createNameColumn(),
              ]}
              filters={{
                kind: new EntityKindFilter("cluster"),
                type: undefined,
              }}
              title="Clusters"
              subtitle={<ClusterTableSubtitle/>}
            />
          </TabbedLayout.Route>
          <TabbedLayout.Route path="/product-features" title="Product Features">
            <CatalogTabContent
              columns={[
                createNameColumn(),
              ]}
              filters={{
                kind: new EntityKindFilter("system"),
                type: new EntityTypeFilter("productfeature"),
              }}
              title="Product Features"
              subtitle={<ProductFeatureTableSubtitle/>}
            />
          </TabbedLayout.Route>
        </TabbedLayout>
      </Content>
    </Page>
  );
}

export const CatalogTabContent = (props: CatalogTabContentProps) => {
  const user = useUserProfile();
  const {isStarredEntity, toggleStarredEntity} = useStarredEntities();

  const catalogApi = useApi(catalogApiRef)
  let actions: TableProps<CatalogTableRow>['actions'] = [];
  if (userIsAdmin(user)) {
    actions?.push(({entity}) => {
      const url = entity.metadata.annotations?.[ANNOTATION_VIEW_URL];
      const title = 'View';

      return {
        icon: () => (
          <>
            <Typography style={visuallyHidden}>{title}</Typography>
            <OpenInNew fontSize="small"/>
          </>
        ),
        tooltip: title,
        disabled: !url,
        onClick: () => {
          if (!url) return;
          window.open(url, '_blank');
        },
      };
    })
  }
  actions?.push(({entity}) => {
    const isStarred = isStarredEntity(entity);
    const title = isStarred ? 'Remove from favorites' : 'Add to favorites';

    return {
      cellStyle: {paddingLeft: '1em'},
      icon: () => (
        <>
          <Typography style={visuallyHidden}>{title}</Typography>
          {isStarred ? <YellowStar/> : <StarBorder/>}
        </>
      ),
      tooltip: title,
      onClick: () => toggleStarredEntity(entity),
    };
  })
  if (userIsAdmin(user)) {
    actions?.push(({entity}) => {
      const isOrphanFlag = isOrphan(entity)
      const title = 'To delete an orphaned entity.';
      return {
        icon: () => (
          <>
            <Typography style={visuallyHidden}>{title}</Typography>
            <DeleteOutline fontSize="small"/>
          </>
        ),
        tooltip: title,
        hidden: !isOrphanFlag,
        onClick: (_e: any) => {
          if (!isOrphanFlag) return;
          confirm(`Are you sure you want to delete "${entity.metadata.name}" ${entity.kind} ?`) && (async () => {
            catalogApi.removeEntityByUid(entity.metadata.uid || "")
              .then(() => {
                console.log(`deleted: ${entity.metadata.name}`)
                alert(`Deleted ${entity.metadata.name} successfuly.`)
                location.reload()
              })
              .catch(error => console.error(error));
          })()
        },
      };
    })
  }

  if (isMobile) {
    actions = [];
  }

  const entityListContext = useEntityList();
  const {loading, error, entities, updateFilters} = entityListContext;

  useEffect(() => {
    updateFilters(props.filters);
  }, [props.filters, updateFilters]);

  return (
    <EntityDataTable
      title={props.title}
      subtitle={props.subtitle}
      isLoading={loading}
      error={error}
      emptyMsg={"Something here?"}
      columns={props.columns}
      actions={actions}
      entities={entities}
    />
  )
}
