import {Entity} from "@backstage/catalog-model";

export const isEntityType = (entity: Entity, type: string): (entity: Entity) => boolean => {
  return (_: Entity) => entity?.spec?.type === type;
}

export interface EntityInfo {
  kindFriendly: string;
  kindFriendlyPlural: string;
  displayName: string;
  catalogLink: string;
  parentCatalogLink: string;
  entity?: Entity;
}

export const getEntityInfo = (entity: Entity | undefined): EntityInfo => {
  const displayName = getEntityName(entity);
  const catalogLink = `/catalog/${entity?.metadata.namespace}/${entity?.kind}/${entity?.metadata.name}`

  if (entity?.kind.toLowerCase() == 'component' && entity?.spec?.type === 'repository') {
    return {
      kindFriendly: "Repository",
      kindFriendlyPlural: "Repositories",
      catalogLink: catalogLink,
      parentCatalogLink: `/catalog/repositories`,
      displayName: displayName,
      entity: entity
    }
  }

  if (entity?.kind.toLowerCase() == 'deployment') {
    return {
      kindFriendly: "Deployment",
      kindFriendlyPlural: "Deployments",
      catalogLink: catalogLink,
      parentCatalogLink: `/catalog/deployments`,
      displayName: displayName,
      entity: entity
    }
  }

  if (entity?.kind.toLowerCase() == 'infradeployment') {
    return {
      kindFriendly: "Infra Deployment",
      kindFriendlyPlural: "Infra Deployments",
      catalogLink: catalogLink,
      parentCatalogLink: `/catalog/infra-deployments`,
      displayName: displayName,
      entity: entity
    }
  }

  if (entity?.kind.toLowerCase() == 'schema') {
    return {
      kindFriendly: "Schema",
      kindFriendlyPlural: "Schemas",
      catalogLink: catalogLink,
      parentCatalogLink: `/catalog/schemas`,
      displayName: displayName,
      entity: entity
    }
  }

  if (entity?.kind.toLowerCase() == 'group' && entity?.spec?.type === 'team') {
    return {
      kindFriendly: "Team",
      kindFriendlyPlural: "Teams",
      catalogLink: catalogLink,
      parentCatalogLink: `/catalog/teams`,
      displayName: displayName,
      entity: entity
    }
  }

  if (entity?.kind.toLowerCase() == 'cluster') {
    return {
      kindFriendly: "Cluster",
      kindFriendlyPlural: "Clusters",
      catalogLink: catalogLink,
      parentCatalogLink: `/catalog/clusters`,
      displayName: displayName,
      entity: entity
    }
  }

  if (entity?.kind.toLowerCase() == 'system' && entity?.spec?.type === 'productFeature') {
    return {
      kindFriendly: "Product Feature",
      kindFriendlyPlural: "Product Features",
      catalogLink: catalogLink,
      parentCatalogLink: `/catalog/product-features`,
      displayName: displayName,
      entity: entity
    }
  }

  return  {
    kindFriendly: entity?.kind ?? "",
    kindFriendlyPlural: entity?.kind + "s",
    catalogLink: catalogLink,
    parentCatalogLink: `/catalog`,
    displayName: displayName,
    entity: entity
  };
}

export const getEntityName = (entity: Entity | undefined): string => {
  if (!entity) {
    return "";
  }

  return entity?.metadata.title ?? entity?.metadata.name ?? ''
}
