import {useEntity, useRelatedEntities} from '@backstage/plugin-catalog-react';
import React, {ReactNode} from 'react';
import {CatalogTable, CatalogTableRow} from "@backstage/plugin-catalog";
import {RELATION_OWNER_OF} from "@backstage/catalog-model";
import {EntityDataTable} from "../EntityDataTable/EntityDataTable";
import {TableColumn} from "@backstage/core-components";

/** @public */
export const EntityRelationsCard = (props: {
  title: string;
  subtitle?: ReactNode;
  relationsType: string;
  relationsKind: string;
  errorMsg?: string;
  emptyMsg?: string;
  columns?: TableColumn<CatalogTableRow>[];
}) => {
  const {
    title = "Ownership",
    relationsType = RELATION_OWNER_OF,
    relationsKind,
    errorMsg = "Could not load relationships",
    emptyMsg = "No records found",
    columns = [
      CatalogTable.columns.createNameColumn(),
      CatalogTable.columns.createOwnerColumn(),
      CatalogTable.columns.createMetadataDescriptionColumn(),
    ]
  } = props;

  const {entity} = useEntity();
  const {entities, loading, error} = useRelatedEntities(entity, {
    type: relationsType,
    kind: relationsKind,
  });

  return (
    <EntityDataTable
      isLoading={loading}
      error={error}
      errorMsg={errorMsg}
      emptyMsg={emptyMsg}
      columns={columns}
      title={title}
      subtitle={props.subtitle}
      entities={entities}
    />
  )
};
