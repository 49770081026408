import {ProfileInfo} from "@backstage/core-plugin-api";
import * as _backstage_core_plugin_api from "@backstage/core-plugin-api";

export const AdminUserEmails: string[] = [
  "jon.stevens@getweave.com",
  "carson.anderson@getweave.com",
  "katharine.tuck@getweave.com",
  "ganesh.kakkireni@getweave.com",
  "ankit.kumar@getweave.com",
]

export const userIsAdmin = (user: {
  profile: ProfileInfo;
  displayName: string;
  loading: boolean;
  backstageIdentity?: undefined;
} | {
  profile: ProfileInfo;
  backstageIdentity: _backstage_core_plugin_api.BackstageUserIdentity;
  displayName: string;
  loading: false;
}) => {
  // @ts-ignore
  return AdminUserEmails.includes(user?.profile?.email)
}
