import React from 'react';
import {Entity, EntityLink, RELATION_OWNED_BY} from '@backstage/catalog-model';
import {Chip, Grid, makeStyles} from '@material-ui/core';
import {MarkdownContent} from '@backstage/core-components';
import {ContentField} from '../ContentField/ContentField';
import {LinksGridList} from "../LinksGridList/LinksGridList";
import {IconComponent, useApp} from "@backstage/core-plugin-api";
import LanguageIcon from '@material-ui/icons/Language';
import {EntityRefLinks, getEntityRelations} from "@backstage/plugin-catalog-react";

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word',
  },
});

/**
 * Props for {@link AboutContent}.
 *
 * @public
 */
export interface AboutContentProps {
  entity: Entity;
  externalLinks?: EntityLink[];
  externalLinksRef?: string;
}

/** @public */
export function AboutContent(props: AboutContentProps) {
  const {entity} = props;
  const classes = useStyles();
  const app = useApp();
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  let externalLinks = props.externalLinks || [];

  const linksIconResolver = (key?: string): IconComponent =>
    key ? app.getSystemIcon(key) ?? LanguageIcon : LanguageIcon;

  return (
    <Grid container>
      <ContentField label="Description" gridSizes={{xs: 12, sm: 6, md: 4}}>
        <MarkdownContent
          className={classes.description}
          content={entity?.metadata?.description || 'N/A'}
        />
      </ContentField>
      {entity?.metadata?.tags && (
        <ContentField
          label="Tags"
          value="No Tags"
          gridSizes={{xs: 12, sm: 6, md: 4}}
        >
          {(entity?.metadata?.tags || []).map(t => (
            <Chip key={t} size="small" label={t}/>
          ))}
        </ContentField>
      )}
      {ownedByRelations.length > 0 && (
        <ContentField
          label="Owner(s)"
          value="No Owner"
          gridSizes={{xs: 12, md: 4}}
        >
          {ownedByRelations.length > 0 && (
            <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group"/>
          )}
        </ContentField>
      )}
      {entity.metadata?.links && (
        <ContentField
          label="Links"
          value="No links"
          gridSizes={{xs: 12}}
        >
          <LinksGridList
            cols={{xs: 2, sm: 2, md: 3, lg: 4, xl: 5}}
            items={entity.metadata?.links.map(({url, title, icon}) => ({
              text: title ?? url,
              href: url,
              Icon: linksIconResolver(icon),
            }))}
          />
        </ContentField>
      )}
      {externalLinks.length > 0 && (
        <ContentField
          label="External Links"
          value="No links"
          gridSizes={{xs: 12}}
          badgeLink={props.externalLinksRef}
        >
          <LinksGridList
            cols={{xs: 2, sm: 2, md: 3, lg: 4, xl: 5}}
            items={externalLinks.map(({url, title}) => ({
              text: title ?? url,
              href: url
            }))}
          />
        </ContentField>
      )}
    </Grid>
  );
}
