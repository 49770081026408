import React from "react";
import {Grid} from "@material-ui/core";
import {
  EntityOpsgenieAlertsCard,
  EntityOpsgenieOnCallListCard,
  isOpsgenieAvailable,
  isOpsgenieOnCallListAvailable
} from '@k-phoen/backstage-plugin-opsgenie';
import {EntitySwitch} from '@backstage/plugin-catalog';
import {EntityWarningContent} from "../../../../../entity/EntityWarningContent/EntityWarningContent";
import {AboutCard} from "../../../../../core/AboutCard/AboutCard";

const OverviewContent = () => {
  return (
    <Grid container>
      {<EntityWarningContent/>}
      <Grid item xs={12}>
        <AboutCard/>
      </Grid>
      <Grid item md={12} lg={6}>
        <EntitySwitch>
          <EntitySwitch.Case if={isOpsgenieAvailable}>
            <EntityOpsgenieAlertsCard title="OpsGenie: Alerts"/>
          </EntitySwitch.Case>
        </EntitySwitch>
      </Grid>
      <Grid item md={12} lg={6}>
        <EntitySwitch>
          <EntitySwitch.Case if={isOpsgenieOnCallListAvailable}>
            <EntityOpsgenieOnCallListCard title="OpsGenie: Who Is On-Call"/>
          </EntitySwitch.Case>
        </EntitySwitch>
      </Grid>
      {/*<Grid item xs={12}>*/}
      {/*  <EntityMembersListCard/>*/}
      {/*</Grid>*/}
    </Grid>
  )
}

export default OverviewContent;
