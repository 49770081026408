import React from "react";
import Select from '@material-ui/core/Select';
import {MenuItem} from "@material-ui/core";

const ValidSeconds = [null, "0.1", "0.25", "0.05", "1", "10", "0.01", "0.5", "2.5", "20", "5", "60", "0.005", "0.025"];

export interface SecondsInputProps {
  name: string;
  value?: string;
  onChange: (e: any) => void;
}

const SecondsInput = (props: SecondsInputProps) => {
  const [value, setValue] = React.useState(props.value);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (e: any) => {
    setValue(e.target.value)
    props.onChange(e)
  }

  return (
    <>
      <Select
        name={props.name}
        onChange={onChange}
        onSelect={onChange}
        value={value ?? ""}
        style={{width: "5em", color: "black"}}
      >
        <MenuItem value=""><em>None</em></MenuItem>
        {ValidSeconds.sort().map((seconds) => {
          return (
            <MenuItem key={seconds} value={seconds || ""}>{seconds}</MenuItem>
          )
        })}
      </Select>
    </>
  )
}

export default SecondsInput;
