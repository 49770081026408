import React from "react";
import {Dialog, DialogTitle} from "@material-ui/core";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

type Props = {
  open: boolean;
  toggleModal: () => void;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

  title: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
}

const useModalStyles = makeStyles(_theme => ({
  // Reduces default height of the modal, keeping a gap of 128px between the top and bottom of the page.
  paperFullWidth: {height: 'calc(100% - 128px)'},
  dialogTitle: {
    borderBottom: "1px solid #cbcbcb"
  },
  dialogContent: {
    paddingTop: "10px"
  },
  dialogActions: {
    borderTop: "1px solid #cbcbcb"
  },
  fmtDiffWarning: {
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "50%",
    marginTop: "1%"
  }
}));

const Modal = (props: Props) => {
  const classes = useModalStyles();
  const {open = false, toggleModal} = props;

  const {
    maxWidth = "lg"
  } = props;

  return (
    <Dialog
      classes={{
        paperFullWidth: classes.paperFullWidth,
      }}
      onClose={toggleModal}
      aria-labelledby="waml-diff-modal-title"
      scroll={"paper"}
      fullWidth={props.fullWidth}
      maxWidth={maxWidth}
      open={open}
    >
      <DialogTitle className={classes.dialogTitle}>{props.title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {props.children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={toggleModal} color={"default"}>Close</Button>
        {props.actions}
      </DialogActions>
    </Dialog>
  );
}

export default Modal;
