import {catalogApiRef, useStarredEntities,} from '@backstage/plugin-catalog-react';
import {Entity, stringifyEntityRef} from '@backstage/catalog-model';
import {useApi} from '@backstage/core-plugin-api';
import {CardTab, InfoCard, Progress, ResponseErrorPanel, TabbedCard} from '@backstage/core-components';
import {Typography} from '@material-ui/core';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import {FavoriteEntityListItem} from './FavoriteEntityListItem';
import {CardTitle} from "../../core/CardTitle/CardTitle";
import {getEntityInfo} from "../../entity/utils/entityUtils";

/**
 * A component to display a list of starred entities for the user.
 *
 * @public
 */

export const FavoriteEntitiesCard = () => {
  const catalogApi = useApi(catalogApiRef);
  const {starredEntities, toggleStarredEntity} = useStarredEntities();

  // Grab starred entities from catalog to ensure they still exist and also retrieve display titles
  const entities = useAsync(async () => {
    if (!starredEntities.size) {
      return [];
    }

    return (
      await catalogApi.getEntitiesByRefs({
        entityRefs: [...starredEntities],
        fields: [
          'kind',
          'metadata.namespace',
          'metadata.name',
          'metadata.title',
          'spec.type',
        ],
      })
    ).items.filter((e): e is Entity => !!e);
  }, [catalogApi, starredEntities]);

  if (starredEntities.size === 0) {
    return (
      <InfoCard title={<CardTitle title={"Favorites"}/>}>
        <Typography variant="body1">
          Click the star beside an entity name to add it to this list!
        </Typography>
      </InfoCard>
    );
  }

  if (entities.loading) {
    return (
      <InfoCard title={<CardTitle title={"Favorites"}/>}>
        <Progress/>
      </InfoCard>
    );
  }

  const groupedEntities: { [kind: string]: Entity[] } = {};
  entities.value?.forEach(entity => {
    let entityInfo = getEntityInfo(entity);
    if (!groupedEntities[entityInfo.kindFriendlyPlural]) {
      groupedEntities[entityInfo.kindFriendlyPlural] = [];
    }
    groupedEntities[entityInfo.kindFriendlyPlural].push(entity);
  });

  const groupByKindEntries = Object.entries(groupedEntities);

  return entities.error ? (
    <InfoCard title={<CardTitle title={"Favorites"}/>}>
      <ResponseErrorPanel error={entities.error}/>
    </InfoCard>
  ) : (
    <TabbedCard title="Favorites">
      {groupByKindEntries.map(([kind, entitiesByKind], index) => {
        return (
          <CardTab label={kind} key={kind + index}>
            {entitiesByKind
              ?.sort((a, b) =>
                (a.metadata.title ?? a.metadata.name).localeCompare(
                  b.metadata.title ?? b.metadata.name,
                ),
              )
              .map(entity => (
                <FavoriteEntityListItem
                  key={stringifyEntityRef(entity)}
                  entity={entity}
                  onToggleStarredEntity={toggleStarredEntity}
                />
              ))}
          </CardTab>
        )
      })}
    </TabbedCard>
  );
};
