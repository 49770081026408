import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {useTechDocsReader} from "./TechDocsReaderProvider";
import {TechDocsBuildLogs} from "./TechDocsBuildLogs";
import {TechDocsNotFound} from "./TechDocsNotFound";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  message: {
    // `word-break: break-word` is deprecated, but gives legacy support to browsers not supporting `overflow-wrap` yet
    // https://developer.mozilla.org/en-US/docs/Web/CSS/word-break
    wordBreak: 'break-word',
    overflowWrap: 'anywhere',
  },
}));

export const TechDocsStateIndicator = () => {
  let StateAlert: JSX.Element | null = null;
  const classes = useStyles();

  const {
    state,
    contentReload,
    contentErrorMessage,
    syncErrorMessage,
    buildLog,
  } = useTechDocsReader();

  if (state === 'INITIAL_BUILD') {
    StateAlert = (
      <Alert
        classes={{root: classes.root}}
        variant="outlined"
        severity="info"
        icon={<CircularProgress size="24px"/>}
        action={<TechDocsBuildLogs buildLog={buildLog}/>}
      >
        Documentation is accessed for the first time and is being prepared. The
        subsequent loads are much faster.
      </Alert>
    );
  }

  if (state === 'CONTENT_STALE_REFRESHING') {
    StateAlert = (
      <Alert
        variant="outlined"
        severity="info"
        icon={<CircularProgress size="24px"/>}
        action={<TechDocsBuildLogs buildLog={buildLog}/>}
        classes={{root: classes.root}}
      >
        A newer version of this documentation is being prepared and will be
        available shortly.
      </Alert>
    );
  }

  if (state === 'CONTENT_STALE_READY') {
    StateAlert = (
      <Alert
        variant="outlined"
        severity="success"
        action={
          <Button color="inherit" onClick={() => contentReload()}>
            Refresh
          </Button>
        }
        classes={{root: classes.root}}
      >
        A newer version of this documentation is now available, please refresh
        to view.
      </Alert>
    );
  }

  if (state === 'CONTENT_STALE_ERROR') {
    StateAlert = (
      <Alert
        variant="outlined"
        severity="error"
        action={<TechDocsBuildLogs buildLog={buildLog}/>}
        classes={{root: classes.root, message: classes.message}}
      >
        Building a newer version of this documentation failed.{' '}
        {syncErrorMessage}
      </Alert>
    );
  }

  if (state === 'CONTENT_NOT_FOUND') {
    StateAlert = (
      <>
        {syncErrorMessage && (
          <Alert
            variant="outlined"
            severity="error"
            action={<TechDocsBuildLogs buildLog={buildLog}/>}
            classes={{root: classes.root, message: classes.message}}
          >
            Building a newer version of this documentation failed.{' '}
            {syncErrorMessage}
          </Alert>
        )}
        <TechDocsNotFound errorMessage={contentErrorMessage}/>
      </>
    );
  }

  return StateAlert;
};
