import React from "react";
import {Grid} from "@material-ui/core";
import {RELATION_HAS_PART} from "@backstage/catalog-model";
import {AboutCard} from "../../../../../core/AboutCard/AboutCard";
import {EntityWarningContent} from "../../../../../entity/EntityWarningContent/EntityWarningContent";
import {EntityRelationsCard} from "../../../../../entity/EntityRelationsCard/EntityRelationsCard";
import {getRepositoryTableColumns} from "../../../../component/repository/RepositoryPage";

const OverviewContent = () => {
  return (
    <Grid container spacing={3} alignItems="stretch">
      {<EntityWarningContent/>}
      <Grid item xs={12}>
        <AboutCard/>
      </Grid>
      <Grid item xs={12}>
        <EntityRelationsCard
          title="Repositories"
          relationsType={RELATION_HAS_PART}
          relationsKind="Component"
          columns={getRepositoryTableColumns()}
          errorMsg="Failed to load repositories."
          emptyMsg="No repositories found."
        />
      </Grid>
    </Grid>
  )
}

export default OverviewContent;
