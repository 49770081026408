import React from "react";
import OverviewContent from "./tabs/overview/OverviewContent";
import DeployerResourcesContent from "./tabs/deployer-resources/DeployerResourcesContent";
import OwnershipContent from "./tabs/ownership/OwnershipContent";
import {EntityLayout} from "../../../entity/EntityLayout/EntityLayout";
import {EntityHeaderBreadcrumbs} from "../../../entity/EntityHeaderBreadcrumbs/EntityHeaderBreadcrumbs";
import {Link} from "@backstage/core-components";
import {useUserProfile} from "@backstage/plugin-user-settings";
import {qualityScoreUserEmails} from "../../component/repository/tabs/overview/Scorecard/ScorecardContent";
import QualityScoreContent from "./tabs/quality-score/QualityScoreContent";
import DocsContent from "./tabs/docs/DocsContent";
import {useEntityByRef} from "../../../entity/utils/useEntityByRef";
import {RepositoryEntityV1alpha1} from "../../../../../../backend/src/custom-processors/types";
import {useEntity} from "@backstage/plugin-catalog-react";

export const TeamTableSubtitle = () => {
  return (
    <>The list of teams comes from the <Link
      to="https://github.com/weave-lab/deployer-resources/tree/main/teams">deployer-resources</Link> repository.</>
  )
}

const TeamPage = () => {
  const {entity} = useEntity();
  const user = useUserProfile();
  const currentUserEmail = user?.profile?.email || '';

  const {entity: e} = useEntityByRef({name: "teams"})
  const teamsRepoEntity = e as RepositoryEntityV1alpha1;
  const teamNameShort = entity.metadata.name.replace("acl-dev-team-", "")
  const hasTeamDocs = teamsRepoEntity?.spec?.docsMetadata?.sourceMetadata?.paths?.find(p => p.destinationPath?.startsWith(teamNameShort))

  return (
    <EntityLayout headerRoutingBreadcrumbs={<EntityHeaderBreadcrumbs/>}>
      <EntityLayout.Route path="/" title="Overview">
        {<OverviewContent/>}
      </EntityLayout.Route>
      {hasTeamDocs && (
        <EntityLayout.Route path="/docs" title="Docs">
          {<DocsContent/>}
        </EntityLayout.Route>
      )}
      <EntityLayout.Route path="/deployer-resources" title="Deployer Resources">
        {<DeployerResourcesContent/>}
      </EntityLayout.Route>
      <EntityLayout.Route path="/ownership" title="Ownership">
        {<OwnershipContent/>}
      </EntityLayout.Route>
      {qualityScoreUserEmails.includes(currentUserEmail) && (
        <EntityLayout.Route path="/quality-score" title="Quality Score">
          {<QualityScoreContent/>}
        </EntityLayout.Route>
      )}
    </EntityLayout>
  );
}

export default TeamPage;
