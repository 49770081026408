import * as React from 'react';
import BaseGaugeChart from 'react-gauge-chart';

interface Props {
  maxPoints: number;
  points: number;
  style?: React.CSSProperties;
}

const getChartPercentage = (points: number, maxPoints: number) => {
  let rangeStart = 0;
  let rangeEnd = 0;
  let startingPercentage = 0;
  if (points < 95) {
    rangeStart = 0;
    rangeEnd = 95;
    startingPercentage = 0;
  } else if (points >= 95 && points < 140) {
    rangeStart = 95;
    rangeEnd = 139;
    startingPercentage = 25;
  } else if (points >= 140 && points < 195) {
    rangeStart = 140;
    rangeEnd = 194;
    startingPercentage = 50;
  } else if (points >= 195) {
    rangeStart = 195;
    rangeEnd = maxPoints;
    startingPercentage = 75;
  }

  return (
    ((points - rangeStart) / (rangeEnd - rangeStart || 1)) * 25 +
    startingPercentage
  );
};

export const GaugeChart = ({ points, maxPoints, style }: Props) => {
  const percentage = getChartPercentage(points, maxPoints);

  return (
    <BaseGaugeChart
      marginInPercent={0.01}
      nrOfLevels={4}
      percent={percentage / 100}
      arcWidth={0.55}
      arcPadding={0}
      cornerRadius={0}
      animate={false}
      style={style}
      hideText
      colors={['#008254', '#40ad87', '#f8bb32', '#ff5c5c']}
    />
  );
};
